import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";
import CheckBox from "../../CheckBox";
import Status from "../../Status";
import { usePersistentStore } from "../../../store/RootStore";
import { useNavigate } from "react-router-dom";
import { RequestInterface } from "../../../store/RequestStore";
import axios from "axios";
import { BaseURL } from "../../../utils/BaseUrl";

interface TableRowProps {
  row: any;
}

interface StyledTableRowProps {
  selected: boolean;
}

const RequestTableRow = ({ row }: TableRowProps) => {
  const { RequestStore, DetailedRequestStore, UserStore } =
    usePersistentStore();
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();

  const handleClick = (id: string) => {
    const requestForDetail = {
      request_number: row.request_number,
      uuid: row.uuid,
      counterparty_id: row.counterparty_company.full_name,
      trader_from_company: row.trader_from_company,
      delivery_tern_id: row.delivery_tern_id,
      departure_point_id: row.departure_point_id,
      agro_culture_type: row.agro_culture_type,
      contract_start_date: row.contract_start_date,
      contract_end_date: row.contract_end_date,
      number: row.number,
      conclusion_date: row.conclusion_date,
      measure: row.measure,
      sum: row.sum,
      vat: row.vat,
      harvest_year: row.harvest_year,
      contract_status_id: row.contract_status,
      cargo_class: row.cargo_class,
      price: [
        {
          id: row.price[0]?.id,
          unit_offered_price: row.price[0]?.unit_offered_price,
          unit_agreed_price: row.price[0]?.unit_agreed_price,
          unit_delivery_price: row.price[0]?.unit_delivery_price,
          unit_agreed_delivery_price: row.price[0]?.unit_agreed_delivery_price,
          comment: row.price[0]?.comment,
          start_at_version: row.price[0]?.start_at_version,
          end_at_version: row.price[0]?.end_at_version,
          is_active_version: row.price[0]?.is_active_version,
          is_actual_now_version: row.price[0]?.is_actual_now_version,
        },
      ],
      isFavourite: row.isFavourite,
    };
    // console.log(
    //   "🚀 ~ file: RequestTableRow.tsx:41 ~ handleClick ~ requestForDetail",
    //   requestForDetail
    // );
    DetailedRequestStore.setRequest(requestForDetail);
    DetailedRequestStore.clearSelection();
    RequestStore.removeAllSelections();
    DetailedRequestStore.clearProducts();
    navigate(`/request/:${id.split("/").join("-")}`);
  };

  const handleSelect = (e: any) => {
    e.stopPropagation();
    RequestStore.toggleIsSelected(row.uuid);
  };

  const handleSetFavourite = (e: any, uuid: string) => {
    e.stopPropagation();

    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };

    const body = {
      contract: uuid,
    };
    if (!row.isFavourite) {
      axios
        .post(`${BaseURL}/v1/contracts/add_favor/`, body, config)
        .then((res) => {
          //   console.log("🚀 ~ file: RequestTableRow.tsx:92 ~ .then ~ res", res);
          RequestStore.setFavouriteRequest(uuid);
        })
        .catch((err) => {
          console.log("🚀 ~ file: RequestTableRow.tsx:95 ~ .then ~ err", err);

          if (err.response.data.code === "token_not_valid") {
            UserStore.updateToken();
          }
        });
    } else {
      axios
        .post(`${BaseURL}/v1/contracts/remove_favor/`, body, config)
        .then((res) => {
          //   console.log("🚀 ~ file: RequestTableRow.tsx:92 ~ .then ~ res", res);
          RequestStore.setFavouriteRequest(uuid);
        })
        .catch((err) => {
          console.log("🚀 ~ file: RequestTableRow.tsx:95 ~ .then ~ err", err);

          if (err.response.data.code === "token_not_valid") {
            UserStore.updateToken();
          }
        });
    }
  };

  return (
    <StyledTableRow
      onClick={() => handleClick(row.request_number)}
      selected={row.isSelected}
    >
      <FirstStyledCell onClick={(e) => handleSelect(e)}>
        <CheckBox checked={row.isSelected} />
      </FirstStyledCell>
      <StyledCell>
        <TableCellContainer>
          {/* <GreenTag>
            {row.agro_culture_type.split(" ")[0].slice(0, 4).toUpperCase()}
          </GreenTag> */}
          <div>{row.agro_culture_type}</div>
        </TableCellContainer>
      </StyledCell>
      <StyledCell>
        <TableCellContainer>
          {/* <GreenTag>КФХ №1</GreenTag> */}
          <div>
            {row.counterparty_company.short_name
              ? row.counterparty_company.short_name?.length > 9
                ? row.counterparty_company.short_name?.slice(0, 9).trim() +
                  "..."
                : row.counterparty_company.short_name
              : "-"}
          </div>
        </TableCellContainer>
      </StyledCell>
      <NumberCell>{`#${row.request_number}`}</NumberCell>
      <StyledCell>
        <Status
          status={row.contract_status}
          border={true}
          backgroundColor="transparent"
        />
      </StyledCell>
      <StyledCell>{row.harvest_year}</StyledCell>
      <StyledCell>{`${row.vat} ${
        row.measure === "т" ? "т" : "кг"
      }`}</StyledCell>
      {/* <StyledCell>{row.tonna_price.toFixed(2)}</StyledCell> */}
      <StyledCell>{row.price[0]?.unit_offered_price}</StyledCell>
      <StyledCell>{row.price[0]?.unit_agreed_price}</StyledCell>
      <StyledCell>{row.price[0]?.unit_delivery_price}</StyledCell>
      <StyledCell>{row.price[0]?.unit_agreed_delivery_price}</StyledCell>
      <StyledCell>
        {row.delivery_tern_id.length > 14
          ? row.delivery_tern_id.slice(0, 9) + "..."
          : row.delivery_tern_id}
      </StyledCell>
      <FirstStyledCell onClick={(e) => handleSetFavourite(e, row.uuid)}>
        {!row.isFavourite ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.72158 5.92565L10 1.60723L12.2784 5.92565C12.4593 6.26845 12.7889 6.50797 13.1709 6.57405L17.982 7.40649L14.579 10.9079C14.3089 11.1858 14.183 11.5733 14.2381 11.957L14.9331 16.7899L10.5516 14.6354C10.2037 14.4644 9.79626 14.4644 9.44844 14.6354L5.06686 16.7899L5.76187 11.957C5.81705 11.5733 5.69113 11.1858 5.42099 10.9079L2.01801 7.40649L6.82913 6.57405C7.21105 6.50797 7.54071 6.26845 7.72158 5.92565ZM15.0006 17.2589C15.0006 17.2588 15.0006 17.2587 15.0005 17.2585L15.0006 17.2589ZM4.64164 16.999C4.64174 16.9989 4.64184 16.9989 4.64194 16.9988L4.64164 16.999ZM4.99946 17.2585C4.99944 17.2587 4.99943 17.2588 4.99941 17.2589L4.99946 17.2585Z"
              stroke="#C7C7C7"
              stroke-width="1.5"
            />
          </svg>
        ) : (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.72158 6.6256L10 2.30719L12.2784 6.6256C12.4593 6.96841 12.7889 7.20792 13.1709 7.274L17.982 8.10644L14.579 11.6078C14.3089 11.8858 14.183 12.2733 14.2381 12.6569L14.9331 17.4898L10.5516 15.3354C10.2037 15.1644 9.79626 15.1644 9.44844 15.3354L5.06686 17.4898L5.76187 12.6569C5.81705 12.2733 5.69113 11.8858 5.42099 11.6078L2.01801 8.10644L6.82913 7.274C7.21105 7.20792 7.54071 6.96841 7.72158 6.6256ZM15.0006 17.9589C15.0006 17.9587 15.0006 17.9586 15.0005 17.9585L15.0006 17.9589ZM4.64164 17.6989C4.64174 17.6989 4.64184 17.6988 4.64194 17.6988L4.64164 17.6989ZM4.99946 17.9585C4.99944 17.9586 4.99943 17.9587 4.99941 17.9589L4.99946 17.9585Z"
              fill="#F5D90A"
              stroke="#F5D90A"
              stroke-width="1.5"
            />
          </svg>
        )}
      </FirstStyledCell>
    </StyledTableRow>
  );
};

export default observer(RequestTableRow);

const StyledTableRow = styled(TableRow)<StyledTableRowProps>`
  border: unset !important;
  transition: var(--transition);
  background: ${(props) =>
    props.selected ? "#F2F5FF" : "transparent"} !important;

  &:hover {
    background: var(--lightBlue3) !important;
  }
`;

const StyledCell = styled(TableCell)`
  padding: 20px 12px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: unset !important;
`;

const TableCellContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const FirstStyledCell = styled(StyledCell)`
  cursor: pointer;
`;

const GreenTag = styled.div`
  color: var(--blue);
`;

const NumberCell = styled(StyledCell)`
  color: var(--GrayLight10) !important;
`;
