import { TableCell, TableCellProps } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DownArrow } from "../../../styles/DownArrow";
import { ResetButton } from "../../../styles/Buttons/ResetButton";
import listenForOutsideClicks from "../../../utils/ListenForOutsideClicks";
import { usePersistentStore } from "../../../store/RootStore";

const AmountDropdown = () => {
  const { FiltersStore } = usePersistentStore();
  const [open, setOpen] = useState(false);
  const [minValueSelect, setMinValueSelected] = useState("кг");
  const [openMinSelect, setOpenMinSelect] = useState(false);
  const [maxValueSelect, setMaxValueSelected] = useState("кг");
  const [openMaxSelect, setOpenMaxSelect] = useState(false);
  const [minValue, setMinValue] = useState(FiltersStore.getAmountMin());
  const [maxValue, setMaxValue] = useState(FiltersStore.getAmountMax());

  const handleChangeMax = (e: React.FormEvent<HTMLInputElement>) => {
    setMaxValue(Number(e.currentTarget.value));
  };

  const handleChangeMin = (e: React.FormEvent<HTMLInputElement>) => {
    setMinValue(Number(e.currentTarget.value));
  };

  const handleReset = () => {
    FiltersStore.removeFilterByAmount();
    setMinValue(FiltersStore.getAmountMin());
    setMaxValue(FiltersStore.getAmountMax());
    setMinValueSelected(FiltersStore.getAmountValueMin());
    setMaxValueSelected(FiltersStore.getAmountValueMax());
  };

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  useEffect(() => {
    if (minValue !== 0 || maxValue !== 0) {
      FiltersStore.setFilterByAmount(
        minValue,
        maxValue,
        minValueSelect,
        maxValueSelect
      );
    }
  });

  return (
    <Container ref={menuRef}>
      <Button
        onClick={() => setOpen(!open)}
        color={open ? "#365EDC" : "#858585"}
      >
        <div>Количество</div>
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
            fill="#858585"
          />
        </svg>
      </Button>
      {open && (
        <Menu>
          <AmountItemContainer>
            <AmountItem>
              <AmountLabel>от</AmountLabel>
              <AmountInputContainer>
                <AmountInput
                  type="number"
                  value={minValue.toString().replace(/^0+/, "")}
                  min="0"
                  onChange={(e) => handleChangeMin(e)}
                  placeholder="0"
                />
                <AmountButton onClick={() => setOpenMinSelect(!openMinSelect)}>
                  <div>{minValueSelect}</div>
                  <DownArrow src="/assets/Header/AccountMore.svg" />
                  {openMinSelect && (
                    <SelectMenu>
                      <SelectMenuItem onClick={() => setMinValueSelected("кг")}>
                        кг
                      </SelectMenuItem>
                      <SelectMenuItem onClick={() => setMinValueSelected("т")}>
                        т
                      </SelectMenuItem>
                    </SelectMenu>
                  )}
                </AmountButton>
              </AmountInputContainer>
            </AmountItem>
            <AmountItem>
              <AmountLabel>до</AmountLabel>
              <AmountInputContainer>
                <AmountInput
                  type="number"
                  min="0"
                  value={maxValue.toString().replace(/^0+/, "")}
                  onChange={(e) => handleChangeMax(e)}
                  placeholder="0"
                />
                <AmountButton onClick={() => setOpenMaxSelect(!openMaxSelect)}>
                  <div>{maxValueSelect}</div>
                  <DownArrow src="/assets/Header/AccountMore.svg" />
                  {openMaxSelect && (
                    <SelectMenu>
                      <SelectMenuItem onClick={() => setMaxValueSelected("кг")}>
                        кг
                      </SelectMenuItem>
                      <SelectMenuItem onClick={() => setMaxValueSelected("т")}>
                        т
                      </SelectMenuItem>
                    </SelectMenu>
                  )}
                </AmountButton>
              </AmountInputContainer>
            </AmountItem>
          </AmountItemContainer>
          <ResetButton onClick={() => handleReset()}>Сбросить</ResetButton>
        </Menu>
      )}
    </Container>
  );
};

export default observer(AmountDropdown);

const Container = styled(TableCell)`
  position: relative;
  border: unset !important;
`;

const Button = styled.button<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  background-color: transparent;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  transition: var(--transition);

  & svg path {
    fill: ${(props) => props.color};
  }

  &:hover {
  }

  & .active {
  }
`;

const Menu = styled.div`
  position: absolute;
  top: calc(100%);
  right: 0;
  left: 0;
  z-index: 1;
  width: 285px;
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);
`;

const AmountItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: 0px 12px;
  margin: 8px 0px;
`;

const AmountItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const AmountLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
`;

const AmountInputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const AmountInput = styled.input`
  width: 80px;
  height: 32px;
  border: 1px solid #f3f3f3;
  border-radius: 4px 0px 0px 4px;
  padding: 0px 4px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  color: var(--GrayLight10);
`;

const AmountButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 47px;
  height: 32px;
  padding-left: 4px;

  border: 1px solid #f3f3f3;
  border-radius: 0px 4px 4px 0px;

  background: var(--GrayLight3);

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--GrayLight10);
`;

const SelectMenu = styled.div`
  position: absolute;
  top: calc(100%);
  right: 0;
  z-index: 2;
  width: 55px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 12px 32px -5px rgba(0, 0, 0, 0.2);
`;

const SelectMenuItem = styled.div`
  padding: 4px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--GrayLight12);
  text-align: left;
  margin: 8px 0px;
  transition: var(--transition);

  &:hover {
    color: var(--GrassLight11);
    background: var(--GrayLight4);
  }
`;
