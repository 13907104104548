import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckBox from "../CheckBox";

interface CheckButtonProps {
  name: string;
  allSelectionFunc: Function | null;
  changeStatusFunction?: Function;
  checkStatusFunc?: Function;
}

const CheckButton = ({
  name,
  allSelectionFunc,
  changeStatusFunction,
  checkStatusFunc,
}: CheckButtonProps) => {
  const handleSelect = () => {
    // setSelected(!selected);
    changeStatusFunction && changeStatusFunction(name);
  };

  return (
    <Item
      onClick={() =>
        allSelectionFunc != null ? allSelectionFunc() : handleSelect()
      }
    >
      {/* <CheckBox type="checkbox" checked={selected} /> */}
      <CheckBox checked={checkStatusFunc && checkStatusFunc(name)} />
      <ItemName>{name}</ItemName>
    </Item>
  );
};

export default observer(CheckButton);

const ItemName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
`;

const Item = styled.div`
  margin: 4px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 12px;

  &:hover ${ItemName} {
    color: var(--lighBlue2);
  }
`;

// const CheckBox = styled.input`
//   width: 16px;
//   height: 16px;
//   border: 1px solid var(--GrayLight7);
//   cursor: pointer;

//   &:checked {
//     color: white;
//     background-color: black;
//   }
// `;
