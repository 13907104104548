import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ModalWrapper } from "../../../styles/ModalWrapper";
import styled from "styled-components";
import {
  HeaderInModals,
  TitleInDetailedRequestModals,
} from "../../../styles/DetailedRequestModals/TitleInDetailedRequestModal";
import CloseButton from "../../Buttons/CloseButton";
import { InputContainer } from "../../../styles/DetailedRequestModals/InputContainer";
import { BlackTitleInput } from "../../../styles/DetailedRequestModals/BlackLabelInput";
import { UncoloredInput } from "../../../styles/DetailedRequestModals/UncoloredInput";
import { usePersistentStore } from "../../../store/RootStore";
import { PriceInput } from "../../../styles/DetailedRequestModals/PriceInput";
import { GreyLabelInput } from "../../../styles/DetailedRequestModals/GreyLabelInput";
import { ColoredInput } from "../../../styles/DetailedRequestModals/ColoredInput";
import { ButtonPrimary } from "../../../styles/Buttons/ButtonPrimary";
import axios from "axios";
import { BaseURL } from "../../../utils/BaseUrl";
import { NumberArray } from "../../../utils/NumberArray";

interface ChangePriceModalProps {
  closeFunction: Function;
}

const ChangePriceModal = ({ closeFunction }: ChangePriceModalProps) => {
  const { DetailedRequestStore, UserStore } = usePersistentStore();
  const [newPrice, setNewPrice] = useState("");

  const handleSubmit = () => {
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };
    const priceBody = {
      contract_id: DetailedRequestStore.getUUID(),
      units: [
        {
          unit_id: DetailedRequestStore.getSelected().id,
          price: {
            unit_agreed_price: Number(newPrice),
            unit_agreed_delivery_price:
              Number(newPrice) +
              (DetailedRequestStore.getPriceOfSelected()
                .unit_agreed_delivery_price as number) -
              (DetailedRequestStore.getPriceOfSelected()
                .unit_agreed_price as number),
            unit_delivery_price:
              DetailedRequestStore.getPriceOfSelected().unit_delivery_price,
            unit_offered_price:
              DetailedRequestStore.getPriceOfSelected().unit_offered_price,
          },
        },
      ],
    };

    axios
      .post(
        `${BaseURL}/v1/contracts/send_price_for_approval/`,
        priceBody,
        config
      )
      .then((res) => {
        // console.log("🚀 ~ file: ChangePriceModal.tsx:53 ~ .then ~ res", res);
        closeFunction();
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: ChangePriceModal.tsx:57 ~ handleSubmit ~ err",
          err
        );
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
          handleSubmit();
        }
      });
  };

  return (
    <ModalWrapper onClick={() => closeFunction()}>
      <Container onClick={(e) => e.stopPropagation()}>
        <HeaderInModals>
          <TitleInDetailedRequestModals>Стоимость</TitleInDetailedRequestModals>
          <CloseButton closeFunction={() => closeFunction()} />
        </HeaderInModals>
        <ChangePriceContainer>
          <InputContainer>
            <BlackTitleInput>Исходная, руб/т</BlackTitleInput>
            <UncoloredInput>
              {DetailedRequestStore.getPriceOfSelected().unit_agreed_price}
            </UncoloredInput>
          </InputContainer>
          <InputContainer>
            <BlackTitleInput>Согласованная, руб/т</BlackTitleInput>
            <PriceInput
              min="0"
              value={newPrice}
              onKeyDown={(evt) =>
                (evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "e" ||
                  (newPrice.length === 1 &&
                    !NumberArray.includes(newPrice) &&
                    evt.key === "0")) &&
                evt.preventDefault()
              }
              onChange={(e) =>
                Number(e.target.value) > -1
                  ? Number(e.target.value) > 1
                    ? setNewPrice(e.target.value)
                    : setNewPrice(e.target.value)
                  : () => {}
              }
            />
          </InputContainer>
          <InputContainer>
            <GreyLabelInput>Цена с доставкой, руб/т</GreyLabelInput>
            <UncoloredInput>
              {
                DetailedRequestStore.getPriceOfSelected()
                  .unit_agreed_delivery_price
              }
            </UncoloredInput>
          </InputContainer>
          <InputContainer>
            <GreyLabelInput>Цена с доставкой, руб/т</GreyLabelInput>
            <ColoredInput>
              {newPrice
                ? (DetailedRequestStore.getPriceOfSelected()
                    .unit_agreed_delivery_price as number) -
                  (DetailedRequestStore.getPriceOfSelected()
                    .unit_agreed_price as number) +
                  Number(newPrice)
                : 0}
            </ColoredInput>
          </InputContainer>
        </ChangePriceContainer>
        <Button disabled={newPrice === ""} onClick={() => handleSubmit()}>
          Подтвердить
        </Button>
      </Container>
    </ModalWrapper>
  );
};

export default observer(ChangePriceModal);

const Container = styled.div`
  align-self: center;
  padding: 20px;
  width: 410px;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  box-shadow: 0px 12px 32px -5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const ChangePriceContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
`;

const Button = styled(ButtonPrimary)`
  width: fit-content;
  padding: 8px 16px;
`;
