import { types } from "mobx-state-tree";

// action: "INCREASE_PRICE";
// contract_id: "ef9bccc1-18ac-4213-a3fe-43141156e6af";
// created_at: "2023-01-29T20:54:28.224993Z";
// from_user: "Нестерцев Славик";
// id: 6;
// is_read: false;
// status_before: "UPDATED";
// status_now: "INCREASE_PRICE";
// to_user_id: "44c92121-a48f-430d-9f93-cf436e2bf9ce";
// unit_id: 2;

const NotificationModel = types.model({
  action: types.string,
  contract_id: types.string,
  created_at: types.string,
  from_user: types.string,
  id: types.number,
  is_read: types.boolean,
  status_before: types.string,
  status_now: types.string,
  contract_number: types.string,
});

export const NotificationStore = types
  .model({
    Notifications: types.optional(types.array(NotificationModel), []),
  })
  .actions((self) => ({
    setNotifications(payload: any) {
      self.Notifications = payload;
    },
    clearNotifications() {
      self.Notifications.splice(0, self.Notifications.length);
    },
    removeNotification(id: number) {
      self.Notifications.replace(
        self.Notifications.filter((e: any) => e.id !== id)
      );
    },
  }))
  .views((self) => ({
    getNotifications() {
      return self.Notifications;
    },
  }));
