import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Wrapper } from "../styles/Wrapper";
import styled from "styled-components";
import { usePersistentStore } from "../store/RootStore";
import Status from "../components/Status";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import DetailedRequestTable from "../components/DetailedRequest/DetailedRequestTable/DetailedRequestTable";
import SideBar from "../components/DetailedRequest/SideBar";
import ExportActionDetailedRequest from "../components/ExportAction/ExportActionDetailedRequest";
import DeleteProductFromRequestModal from "../components/DetailedRequest/Modals/DeleteProductFromRequestModal";
import { CurrentPageProps } from "../components/Header";
import RequestEditModal from "../components/DetailedRequest/Modals/RequestEditModal";
import SupervisorActions from "../components/DetailedRequest/SupervisorActions";
import SampleSelectionModal from "../components/DetailedRequest/SupervisorModalsInDetailedRequest/SampleSelectionModal";
import DecreasePriceModal from "../components/DetailedRequest/SupervisorModalsInDetailedRequest/DecreasePriceModal";
import IncreasePriceModal from "../components/DetailedRequest/SupervisorModalsInDetailedRequest/IncreasePriceModal";
import CancelnProcurementModal from "../components/DetailedRequest/SupervisorModalsInDetailedRequest/CancelnProcurementModal";
import axios, { AxiosResponse } from "axios";
import { BaseURL } from "../utils/BaseUrl";
import { Oval } from "react-loader-spinner";

const DetailedRequest = () => {
  const { DetailedRequestStore, UserStore } = usePersistentStore();
  const navigate = useNavigate();
  const location = useLocation();
  //   console.log(
  //     "🚀 ~ file: DetailedRequest.tsx:18 ~ DetailedRequest ~ location",
  //     location
  //   );
  const [attentionBanner, setAttentionBanner] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [openSampleSelection, setOpenSampleSelection] = useState(false);
  const [openDecreasePrice, setOpenDecreasePrice] = useState(false);
  const [openIncreasePrice, setOpenIncreasePrice] = useState(false);
  const [inProcurement, setInProcurement] = useState(false);
  const [cancelInProcurement, setCancelInProcurement] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleGoBack = () => {
    navigate("/request");
  };

  //   useEffect(() => {
  //     setLoading(true);
  //     const body = {
  //       contract_id: DetailedRequestStore.getUUID(),
  //     };
  //     const config = {
  //       headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
  //     };

  //     axios
  //       .post(`${BaseURL}/v1/contracts/get_contract_units/`, body, config)
  //       .then((res: AxiosResponse<any>) => {
  //         // console.log("🚀 ~ file: DetailedRequest.tsx:49 ~ .then ~ res", res);
  //         DetailedRequestStore.setProducts(
  //           res.data.map((e: any) => {
  //             return e.product;
  //           })
  //         );
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log("🚀 ~ file: DetailedRequest.tsx:52 ~ .then ~ err", err);
  //         if (err.response.data.code === "token_not_valid") {
  //           UserStore.updateToken();
  //         }
  //       });
  //   }, []);

  return (
    <Wrapper>
      <Container>
        <MainSideContainer>
          <HeaderLine>
            <Header>
              <BackButton onClick={() => handleGoBack()}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.3679 9.95756L12.3054 7.02008C12.4599 6.86461 12.5467 6.65429 12.5467 6.43507C12.5467 6.21585 12.4599 6.00554 12.3054 5.85007C12.2282 5.77229 12.1365 5.71056 12.0353 5.66843C11.9342 5.6263 11.8258 5.60461 11.7162 5.60461C11.6067 5.60461 11.4982 5.6263 11.3971 5.66843C11.296 5.71056 11.2042 5.77229 11.1271 5.85007L7.60873 9.3684C7.53096 9.44554 7.46923 9.53732 7.4271 9.63844C7.38497 9.73955 7.36328 9.84801 7.36328 9.95756C7.36328 10.0671 7.38497 10.1756 7.4271 10.2767C7.46923 10.3778 7.53096 10.4696 7.60873 10.5467L11.1271 14.1065C11.2046 14.1834 11.2966 14.2443 11.3977 14.2856C11.4988 14.3269 11.607 14.3478 11.7162 14.3472C11.8254 14.3478 11.9337 14.3269 12.0348 14.2856C12.1359 14.2443 12.2278 14.1834 12.3054 14.1065C12.4599 13.9511 12.5467 13.7407 12.5467 13.5215C12.5467 13.3023 12.4599 13.092 12.3054 12.9365L9.3679 9.95756Z"
                    fill="#2F52BF"
                  />
                </svg>
              </BackButton>
              <div>
                {DetailedRequestStore.getAgroCultureType()} #
                {DetailedRequestStore.getNumber()}
              </div>
              <Status
                status={DetailedRequestStore.getStatus()}
                border={true}
                backgroundColor={"white"}
              />
            </Header>
            {UserStore.getRole() === "Старший трейдер" ? (
              <SupervisorActions
                setDeleteModal={setDeleteModal}
                isOpenSampleSelection={openSampleSelection}
                openSampleSelectionFunction={() => setOpenSampleSelection(true)}
                isOpenDecreaseModal={openDecreasePrice}
                openDecreaseFunction={() => setOpenDecreasePrice(true)}
                isOpenIncreaseModal={openIncreasePrice}
                openIncreaseFunction={() => setOpenIncreasePrice(true)}
              />
            ) : (
              <ExportActionDetailedRequest setDeleteModal={setDeleteModal} />
            )}
          </HeaderLine>
          <NavbarContainer>
            <Link to="">
              <NavTabs
                color={
                  location.pathname ===
                  `/request/id:${DetailedRequestStore.getId()}`
                    ? "#171717"
                    : "#171717"
                }
                borderColor={
                  location.pathname ===
                  `/request/id:${DetailedRequestStore.getId()}`
                    ? "#2F52BF"
                    : "transparent"
                }
              >
                Товары
              </NavTabs>
            </Link>
            <Link to="approval_history">
              <NavTabs
                color={
                  location.pathname ===
                  `/request/id:${DetailedRequestStore.getId()}/approval_history`
                    ? "#171717"
                    : "#171717"
                }
                borderColor={
                  location.pathname ===
                  `/request/id:${DetailedRequestStore.getId()}/approval_history`
                    ? "#2F52BF"
                    : "transparent"
                }
              >
                История согласования
              </NavTabs>
            </Link>
            <Link to="sample_quality">
              <NavTabs
                color={
                  location.pathname ===
                  `/request/id:${DetailedRequestStore.getId()}/sample_quality`
                    ? "#171717"
                    : "#171717"
                }
                borderColor={
                  location.pathname ===
                  `/request/id:${DetailedRequestStore.getId()}/sample_quality`
                    ? "#2F52BF"
                    : "transparent"
                }
              >
                Качество образцов
              </NavTabs>
            </Link>
          </NavbarContainer>
          {/* {!loading ? ( */}
          <Outlet />
          {/* ) : (
            <OvalContainer>
              <Oval
                ariaLabel="loading-indicator"
                height={100}
                width={100}
                strokeWidth={5}
                strokeWidthSecondary={3}
                color="#8BBB4B"
                secondaryColor="rgba(139, 187, 75, 0)"
              />
            </OvalContainer>
          )} */}
          {/* <DetailedRequestTable /> */}
        </MainSideContainer>
        <SideBar
          attentionBanner={attentionBanner}
          setAttention={setAttentionBanner}
          openEditFunction={() => setEditModal(true)}
          inProcurement={inProcurement}
          setInProcurement={setInProcurement}
          setCancelInProcurement={setCancelInProcurement}
        />
      </Container>
      {deleteModal && (
        <DeleteProductFromRequestModal
          setAttention={setAttentionBanner}
          setDeleteModal={setDeleteModal}
        />
      )}
      {editModal && (
        <RequestEditModal closeFunction={() => setEditModal(false)} />
      )}
      {openSampleSelection && (
        <SampleSelectionModal
          closeFunction={() => setOpenSampleSelection(false)}
        />
      )}
      {openDecreasePrice && (
        <DecreasePriceModal closeFunction={() => setOpenDecreasePrice(false)} />
      )}
      {openIncreasePrice && (
        <IncreasePriceModal closeFunction={() => setOpenIncreasePrice(false)} />
      )}
      {cancelInProcurement && (
        <CancelnProcurementModal
          closeFunction={() => setCancelInProcurement(false)}
          setInProcurement={setInProcurement}
        />
      )}
    </Wrapper>
  );
};

export default observer(DetailedRequest);

const Container = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const MainSideContainer = styled.div`
  max-width: 1170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const HeaderLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: black;
`;

const BackButton = styled.button`
  width: 28px;
  height: 28px;

  border: 1px solid var(--GrayLight8);
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: var(--transition);

  &:hover {
    border-color: var(--lightBlue);
  }

  &:active {
    border-color: var(--darkBlue);
  }
`;

const NavbarContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

const NavTabs = styled.div<CurrentPageProps>`
  width: fit-content;
  padding: 19px 8px 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  border-bottom: 4px solid ${(props) => props.borderColor};
  cursor: pointer;
`;
const OvalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;
