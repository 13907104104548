import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ButtonActiveProps } from "../Dropdown/Dropdown";
import { DownArrow } from "../../styles/DownArrow";
import listenForOutsideClicks from "../../utils/ListenForOutsideClicks";
import { usePersistentStore } from "../../store/RootStore";
import { CSVLink, CSVDownload } from "react-csv";
import { GetStatus } from "../../utils/GetStatus";
import { useNavigate } from "react-router-dom";

const ExportAction = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([] as Array<any>);
  const { RequestStore, DetailedRequestStore } = usePersistentStore();
  const navigate = useNavigate();

  const toggleOpen = () => {
    setOpen(!open);
  };

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  const headers = [
    { label: "Номер", key: "number" },
    { label: "Наименование", key: "agro_culture_type" },
    { label: "Поставщик", key: "counterparty_company" },
    { label: "Статус", key: "status" },
    { label: "Урожай", key: "harvest_year" },
    { label: "Трейдер", key: "trader_from_company" },
    { label: "Количество", key: "vat" },
    { label: "Цена", key: "offeredPrice" },
    { label: "Согл. цена", key: "agreedPrice" },
    { label: "Цена с дост.", key: "deliveryPrice" },
    { label: "Согл цена с дост.", key: "agreedDeliveryPrice" },
    { label: "Отгрузка", key: "departurePoint" },
  ];

  useEffect(() => {
    let exportData = RequestStore.getRequests().map((e) => {
      return {
        number: e.number,
        agro_culture_type: e.agro_culture_type,
        counterparty_company: e.counterparty_company.full_name,
        status: GetStatus(e.contract_status),
        harvest_year: e.harvest_year,
        trader_from_company: e.trader_from_company,
        vat: `${e.vat} ${e.measure}`,
        offeredPrice: e.price[0]?.unit_offered_price,
        deliveryPrice: e.price[0]?.unit_delivery_price,
        agreedPrice: e.price[0]?.unit_agreed_price,
        agreedDeliveryPrice: e.price[0]?.unit_agreed_delivery_price,
        departurePoint: e.delivery_tern_id,
      };
    });
    setData(exportData);
  }, []);

  const goToDetailedRequest = () => {
    const requestForDetail = {
      request_number: RequestStore.getSelected().request_number,
      uuid: RequestStore.getSelected().uuid,
      counterparty_id:
        RequestStore.getSelected().counterparty_company.full_name,
      trader_from_company: RequestStore.getSelected().trader_from_company,
      delivery_tern_id: RequestStore.getSelected().delivery_tern_id,
      departure_point_id: RequestStore.getSelected().departure_point_id,
      agro_culture_type: RequestStore.getSelected().agro_culture_type,
      contract_start_date: RequestStore.getSelected().contract_start_date,
      contract_end_date: RequestStore.getSelected().contract_end_date,
      number: RequestStore.getSelected().number,
      conclusion_date: RequestStore.getSelected().conclusion_date,
      measure: RequestStore.getSelected().measure,
      sum: RequestStore.getSelected().sum,
      vat: RequestStore.getSelected().vat,
      harvest_year: RequestStore.getSelected().harvest_year,
      contract_status_id: RequestStore.getSelected().contract_status,
      cargo_class: RequestStore.getSelected().cargo_class,
      price: [
        {
          id: RequestStore.getSelected().price[0]?.id,
          unit_offered_price:
            RequestStore.getSelected().price[0]?.unit_offered_price,
          unit_agreed_price:
            RequestStore.getSelected().price[0]?.unit_agreed_price,
          unit_delivery_price:
            RequestStore.getSelected().price[0]?.unit_delivery_price,
          unit_agreed_delivery_price:
            RequestStore.getSelected().price[0]?.unit_agreed_delivery_price,
          comment: RequestStore.getSelected().price[0]?.comment,
          start_at_version:
            RequestStore.getSelected().price[0]?.start_at_version,
          end_at_version: RequestStore.getSelected().price[0]?.end_at_version,
          is_active_version:
            RequestStore.getSelected().price[0]?.is_active_version,
          is_actual_now_version:
            RequestStore.getSelected().price[0]?.is_actual_now_version,
        },
      ],
      isFavourite: RequestStore.getSelected().isFavourite,
    };

    DetailedRequestStore.setRequest(requestForDetail);
    DetailedRequestStore.clearSelection();
    DetailedRequestStore.clearProducts();
    navigate(`/request/:${RequestStore.getSelected().request_number}`);
    RequestStore.removeAllSelections();
  };

  return (
    <Container>
      <CSVLink
        data={data}
        headers={headers}
        filename={`Заявки_от_${new Date().toLocaleDateString()}.csv`}
      >
        <ExportButton>
          <div>Экспортировать CSV</div>
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 6.705C14.9922 6.6361 14.9771 6.56822 14.955 6.5025V6.435C14.9189 6.35788 14.8708 6.287 14.8125 6.225V6.225L10.3125 1.725C10.2505 1.66666 10.1796 1.61856 10.1025 1.5825H10.0275C9.95457 1.5435 9.8762 1.51569 9.795 1.5H5.25C4.65326 1.5 4.08097 1.73705 3.65901 2.15901C3.23705 2.58097 3 3.15326 3 3.75V14.25C3 14.8467 3.23705 15.419 3.65901 15.841C4.08097 16.2629 4.65326 16.5 5.25 16.5H12.75C13.3467 16.5 13.919 16.2629 14.341 15.841C14.7629 15.419 15 14.8467 15 14.25V6.75C15 6.75 15 6.75 15 6.705ZM10.5 4.0575L12.4425 6H11.25C11.0511 6 10.8603 5.92098 10.7197 5.78033C10.579 5.63968 10.5 5.44891 10.5 5.25V4.0575ZM13.5 14.25C13.5 14.4489 13.421 14.6397 13.2803 14.7803C13.1397 14.921 12.9489 15 12.75 15H5.25C5.05109 15 4.86032 14.921 4.71967 14.7803C4.57902 14.6397 4.5 14.4489 4.5 14.25V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H9V5.25C9 5.84674 9.23705 6.41903 9.65901 6.84099C10.081 7.26295 10.6533 7.5 11.25 7.5H13.5V14.25ZM9.9675 10.7175L9.75 10.9425V9C9.75 8.80109 9.67098 8.61032 9.53033 8.46967C9.38968 8.32902 9.19891 8.25 9 8.25C8.80109 8.25 8.61032 8.32902 8.46967 8.46967C8.32902 8.61032 8.25 8.80109 8.25 9V10.9425L8.0325 10.7175C7.89127 10.5763 7.69973 10.4969 7.5 10.4969C7.30027 10.4969 7.10873 10.5763 6.9675 10.7175C6.82627 10.8587 6.74693 11.0503 6.74693 11.25C6.74693 11.4497 6.82627 11.6413 6.9675 11.7825L8.4675 13.2825C8.53883 13.3508 8.62294 13.4043 8.715 13.44C8.80478 13.4797 8.90185 13.5002 9 13.5002C9.09815 13.5002 9.19522 13.4797 9.285 13.44C9.37706 13.4043 9.46117 13.3508 9.5325 13.2825L11.0325 11.7825C11.1737 11.6413 11.2531 11.4497 11.2531 11.25C11.2531 11.0503 11.1737 10.8587 11.0325 10.7175C10.8913 10.5763 10.6997 10.4969 10.5 10.4969C10.3003 10.4969 10.1087 10.5763 9.9675 10.7175V10.7175Z"
              fill="#858585"
            />
          </svg>
        </ExportButton>
      </CSVLink>
      <ActionContainer ref={menuRef}>
        <ActionButton
          onClick={() => toggleOpen()}
          borderColor={open ? "#365EDC" : "#DBDBDB"}
          backGroundColor={open ? "#F2F5FF" : "transparent"}
          disabled={RequestStore.getAmountOfSelected() !== 1}
        >
          <div>Действия</div>
          <DownArrow src="/assets/Header/AccountMore.svg" />
        </ActionButton>
        {open && (
          <ExportMenu>
            <Item onClick={() => goToDetailedRequest()}>
              Запросить отбор проб
            </Item>
            <Item onClick={() => goToDetailedRequest()}>
              Отправить в закупку
            </Item>
            <Item onClick={() => goToDetailedRequest()}>
              Изменить пункт Доставки
            </Item>
            <CancelItem onClick={() => toggleOpen()}>Отменить</CancelItem>
          </ExportMenu>
        )}
      </ActionContainer>
    </Container>
  );
};

export default observer(ExportAction);

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const ExportButton = styled.button`
  display: flex;
  flex-direction: RequestStore.getSelected();
  align-items: center;
  padding: 8px 8px 8px 8px;
  gap: 8px;

  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  background: var(--GrayLight2);

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  transition: var(--transition);

  &:hover {
    border-color: var(--blue);
  }

  &:active {
    background: var(--lightBlue3);
  }
`;

const ActionContainer = styled.div`
  position: relative;
`;

const ActionButton = styled.button<ButtonActiveProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  /* height: 36px; */

  text-align: left;
  cursor: pointer;
  background-color: ${(props) => props.backGroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;
  padding: 7px 9px 7px 16px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: var(--transition);

  &:hover {
    border-color: var(--lightblue);
  }

  & .active {
    border-color: var(--darkBlue);
  }

  &:disabled {
    border-color: var(--GrayLight10);
    color: var(--GrayLight10);
    cursor: unset;
  }
`;

const ExportMenu = styled.div`
  position: absolute;
  top: calc(100% + 3px);
  right: 0;
  z-index: 1;
  width: 225px;
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);
`;

const Item = styled.div`
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);

  transition: var(--transition);

  &:hover {
    background: var(--GrayLight2);
    color: var(--blue);
  }
`;

const CancelItem = styled.div`
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--TomatoDark8);

  transition: var(--transition);

  &:hover {
    color: var(--TomatoDark9);
  }

  &:active {
    color: var(--TomatoDark7);
  }
`;
