import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

interface StatusProps {
  status: string;
  border: boolean;
  backgroundColor: string;
}

interface StatusContainerProps {
  backgroundColor: string;
  borderColor: string;
}

interface StatusColorProps {
  color: string;
}

const Status = ({ status, border, backgroundColor }: StatusProps) => {
  const ColorFunc = (status: string): string => {
    switch (status) {
      case "NEW":
        return "#299764";
        break;
      case "SEND":
        return "#2EC8EE";
        break;
      case "FIXED":
        return "#9D5BD2";
        break;
      case "DECLINED":
        return "#F2555A";
        break;
      case "UPDATED":
        return "#F7CE00";
        break;
      case "DRAFT":
        return "#3A5CCC";
      case "CONTRACT_ABORTED":
        return "#F2555A";
      case "FINISHED":
        return "#171717";
    }
    return "";
  };

  const NameFunc = (status: string): string => {
    switch (status) {
      case "NEW":
        return "Новая заявка";
        break;
      case "SEND":
        return "Согласование";
        break;
      case "FIXED":
        return "В закупке";
        break;
      case "DECLINED":
        return "Отклонена";
        break;
      case "InTest":
        return "Отбор проб";
        break;
      case "UPDATED":
        return "Обновлен";
      case "CONTRACT_ABORTED":
        return "Контракт разорван";
      case "FINISHED":
        return "Дата окончания контракта достигнута";
      case "ERROR":
        return "Ошибка";
      case "DRAFT":
        return "Создание";
    }
    return "";
  };
  // console.log("🚀 ~ file: Status.tsx:78 ~ NameFunc ~ """, "")

  return (
    <Container
      borderColor={border ? "#EDEDED" : "transparent"}
      backgroundColor={backgroundColor}
    >
      <StatusColor color={ColorFunc(status)}></StatusColor>
      <StatusTitle>{NameFunc(status)}</StatusTitle>
    </Container>
  );
};

export default observer(Status);

const Container = styled.div<StatusContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 4px;
  background: ${(props) => props.backgroundColor};
`;

const StatusColor = styled.div<StatusColorProps>`
  width: 8px;
  height: 8px;
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

const StatusTitle = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: var(--GrayLight12);
`;
