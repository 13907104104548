import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ModalWrapper } from "../../styles/ModalWrapper";
import { usePersistentStore } from "../../store/RootStore";
import styled from "styled-components";
import DatePicker, { registerLocale } from "react-datepicker";
import * as ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import FiltersModalDropdown from "./Dropdowns/FiltersModalDropdown";
import RadioButton from "./RadioButton";
import CheckButton from "./CheckButton";
import CloseButton from "../Buttons/CloseButton";
import FiltersStatusDropdown from "./Dropdowns/FiltersStatusDropdown";
import { addDays } from "date-fns";
registerLocale("ru", ru);

interface CategoryButtonProps {
  color: string;
  backgroundColor: string;
}

const FiltersModal = () => {
  const { FiltersStore, RequestStore, SimpleDictionaryStore } =
    usePersistentStore();
  const [selectedYear, setSelectedYear] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [startDate, setStartDate] = useState(
    FiltersStore.getStartDate() !== ""
      ? new Date(FiltersStore.getStartDate())
      : null
  );
  const [endDate, setEndDate] = useState(
    FiltersStore.getEndDate() !== ""
      ? new Date(FiltersStore.getEndDate())
      : null
  );

  const handleReset = () => {
    // FiltersStore.toggleIsOpen();
    FiltersStore.clearSearching();
    FiltersStore.removeNoResults();
    FiltersStore.resetTraidersFilter();
    FiltersStore.resetAgrocultureTypeFilter();
    FiltersStore.resetShipmentFilter();
    FiltersStore.resetSupplierFilter();
    FiltersStore.clearDateFilter();
    FiltersStore.resetStatusFilter();
    FiltersStore.resetDeparturePointFilter();
    FiltersStore.resetHarvestYearFilter();
    setStartDate(null);
    setEndDate(null);
    setSelectedAll(!selectedAll);
    setSelectedYear(false);
  };

  const handleSearch = () => {
    FiltersStore.clearSearching();
    FiltersStore.removeNoResults();
    if (FiltersStore.FilteredData(RequestStore.getRequests()).length === 0) {
      FiltersStore.setNoResults();
    }
    FiltersStore.toggleIsOpen();
  };

  const handleChangeDate = (dates: any) => {
    const [start, end] = dates;
    // console.log(
    //   "🚀 ~ file: FiltersModal.tsx:48 ~ handleChangeDate ~ dates",
    //   start.toString()
    // );
    setStartDate(start);
    if (start) FiltersStore.setStartDate(start.toString());
    setEndDate(end);
    if (end) FiltersStore.setEndDate(end.toString());
  };

  const handleSelectAllYears = () => {
    if (!selectedYear) {
      RequestStore.getCropYears().forEach((e: string) => {
        FiltersStore.toggleAllHarvestYearFilters(e, "set");
      });
    } else {
      RequestStore.getCropYears().forEach((e: string) => {
        FiltersStore.toggleAllHarvestYearFilters(e, "delete");
      });
    }
    setSelectedYear(!selectedYear);
  };

  return (
    <ModalWrapper
      onClick={() => {
        handleReset();
        FiltersStore.toggleIsOpen();
      }}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <Header>
          <TitleContainer>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.25 1.5H3.75C3.15326 1.5 2.58097 1.73705 2.15901 2.15901C1.73705 2.58097 1.5 3.15326 1.5 3.75V4.6275C1.49989 4.93721 1.56372 5.2436 1.6875 5.5275V5.5725C1.79346 5.81323 1.94354 6.032 2.13 6.2175L6.75 10.8075V15.75C6.74974 15.8775 6.78198 16.0029 6.84365 16.1144C6.90533 16.226 6.99442 16.3199 7.1025 16.3875C7.22186 16.4615 7.35958 16.5005 7.5 16.5C7.61741 16.4993 7.73301 16.471 7.8375 16.4175L10.8375 14.9175C10.9612 14.8552 11.0652 14.7598 11.138 14.642C11.2108 14.5242 11.2496 14.3885 11.25 14.25V10.8075L15.84 6.2175C16.0265 6.032 16.1765 5.81323 16.2825 5.5725V5.5275C16.4166 5.24582 16.4907 4.93933 16.5 4.6275V3.75C16.5 3.15326 16.2629 2.58097 15.841 2.15901C15.419 1.73705 14.8467 1.5 14.25 1.5ZM9.9675 9.9675C9.89799 10.0376 9.843 10.1207 9.80567 10.2121C9.76835 10.3034 9.74943 10.4013 9.75 10.5V13.785L8.25 14.535V10.5C8.25057 10.4013 8.23165 10.3034 8.19433 10.2121C8.157 10.1207 8.10201 10.0376 8.0325 9.9675L4.0575 6H13.9425L9.9675 9.9675ZM15 4.5H3V3.75C3 3.55109 3.07902 3.36032 3.21967 3.21967C3.36032 3.07902 3.55109 3 3.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V4.5Z"
                fill="#858585"
              />
            </svg>

            <Title>Фильтры</Title>
          </TitleContainer>
          {/* <CloseBtn
            src="/assets/CloseBtn.svg"
            onClick={() => FiltersStore.toggleIsOpen()}
          /> */}
          <CloseButton
            closeFunction={() => {
              handleReset();
              FiltersStore.toggleIsOpen();
            }}
          />
        </Header>
        <FiltersModalDropdown
          title="Категория сырья"
          placeholder="Все категории"
          items={SimpleDictionaryStore.getAgroCultureTypeList()}
          changeStatusFunction={FiltersStore.changeAgrocultureTypeFilter}
          checkStatusFunc={FiltersStore.getAgrocultureTypeFilterStatus}
          resetFunction={FiltersStore.resetAgrocultureTypeFilter}
          toggleAll={FiltersStore.toggleAllAgrocultureFilters}
          isAllReset={selectedAll}
        />
        {/* <FiltersModalDropdown
          title="Вид сырья"
          placeholder="Все категории"
          items={["Пшено", "Гречиха", "Солод", "Соя", "Кукуруза"]}
        /> */}
        <FiltersModalDropdown
          title="Отвественный трейдер"
          placeholder="Все трейдеры"
          items={RequestStore.getTraiders()}
          changeStatusFunction={FiltersStore.changeTradersFilter}
          checkStatusFunc={FiltersStore.getTraderFilterStatus}
          resetFunction={FiltersStore.resetTraidersFilter}
          toggleAll={FiltersStore.toggleAllTraidersFilter}
          isAllReset={selectedAll}
        />
        <CategoryContainer>
          <CategoryTitle>Дата создания заявки</CategoryTitle>
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={handleChangeDate}
            dateFormat="dd.MM.yyyy"
            placeholderText="Выберите дату или диапазон"
            selectsRange
            className="date-picker"
            locale="ru"
            // inline
          />
        </CategoryContainer>
        <FiltersModalDropdown
          title="Точка поставки"
          placeholder="Все точки"
          items={SimpleDictionaryStore.getPointList()}
          changeStatusFunction={FiltersStore.changeDeparturePointFilter}
          checkStatusFunc={FiltersStore.getDeparutrePointFilterStatus}
          resetFunction={FiltersStore.resetDeparturePointFilter}
          toggleAll={FiltersStore.toggleAllDeparturePointFilter}
          isAllReset={selectedAll}
        />
        <FiltersStatusDropdown
          title="Статус заявки"
          placeholder="Все статусы"
          items={[
            "NEW",
            "DRAFT",
            "SEND",
            "UPDATED",
            "FIXED",
            "DECLINED",
            "CONTRACT_ABORTED",
          ]}
          changeStatusFunction={FiltersStore.changeStatusFilter}
          checkStatusFunc={FiltersStore.getStatusFilterStatus}
          resetFunction={FiltersStore.resetStatusFilter}
          toggleAll={FiltersStore.toggleAllStatusesFilter}
          isAllReset={selectedAll}
        />
        <CategoryContainer>
          <CategoryTitle>Год урожая</CategoryTitle>
          <CheckButton
            name="Все года"
            allSelectionFunc={handleSelectAllYears}
            checkStatusFunc={() => {
              return selectedYear;
            }}
          ></CheckButton>
          {RequestStore.getCropYears()
            .reverse()
            .map((e, i) => {
              return (
                <CheckButton
                  key={i}
                  name={e}
                  changeStatusFunction={FiltersStore.changeHarvestYearFilter}
                  checkStatusFunc={FiltersStore.getHarvestYearFilterStatus}
                  allSelectionFunc={null}
                />
              );
            })}
        </CategoryContainer>
        {/* <CategoryContainer>
          <CategoryTitle>Изменение цены</CategoryTitle>
          <RadioButton label="Без изменений" />
          <RadioButton label="Цена снижена" />
          <RadioButton label="Открытая цена" />
        </CategoryContainer> */}
        <Footer>
          <ClearButton onClick={() => handleReset()}>Сбросить</ClearButton>
          <SearchButton onClick={() => handleSearch()}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.2825 15.2175L13.5001 12.4575C14.5801 11.1108 15.1032 9.40146 14.9617 7.68096C14.8201 5.96047 14.0248 4.35958 12.7392 3.20747C11.4536 2.05537 9.77541 1.43962 8.04974 1.48684C6.32408 1.53406 4.68209 2.24066 3.46141 3.46135C2.24072 4.68203 1.53412 6.32402 1.4869 8.04968C1.43968 9.77534 2.05543 11.4535 3.20753 12.7391C4.35964 14.0247 5.96053 14.8201 7.68103 14.9616C9.40152 15.1031 11.1109 14.5801 12.4576 13.5L15.2176 16.26C15.2873 16.3303 15.3702 16.3861 15.4616 16.4242C15.553 16.4622 15.651 16.4818 15.7501 16.4818C15.8491 16.4818 15.9471 16.4622 16.0385 16.4242C16.1299 16.3861 16.2128 16.3303 16.2825 16.26C16.4177 16.1201 16.4933 15.9332 16.4933 15.7387C16.4933 15.5442 16.4177 15.3573 16.2825 15.2175ZM8.25005 13.5C7.2117 13.5 6.19666 13.1921 5.33331 12.6152C4.46995 12.0383 3.79704 11.2184 3.39968 10.2591C3.00232 9.29977 2.89836 8.24417 3.10093 7.22577C3.3035 6.20737 3.80351 5.2719 4.53774 4.53768C5.27197 3.80345 6.20743 3.30344 7.22583 3.10087C8.24423 2.89829 9.29983 3.00226 10.2591 3.39962C11.2185 3.79698 12.0384 4.46989 12.6153 5.33325C13.1921 6.1966 13.5001 7.21164 13.5001 8.24999C13.5001 9.64238 12.9469 10.9777 11.9624 11.9623C10.9778 12.9469 9.64244 13.5 8.25005 13.5Z"
                fill="#fff"
              />
            </svg>

            <div>Искать</div>
          </SearchButton>
        </Footer>
      </Container>
    </ModalWrapper>
  );
};

export default observer(FiltersModal);

const Container = styled.div`
  width: 548px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: white;
  height: fit-content;
  align-self: center;
  border-radius: 8px;
  position: relative;

  overflow: scroll;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--GrayLight4);
  padding: 16px 20px;
  /* position: sticky;
  top: 0; */

  background: white;
  z-index: 101;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--GrayLight12);
  margin-left: 10px;
`;

const CloseBtn = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const CategoryContainer = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;

const CategoryTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--GrayLight12);
`;

const CategoryButtonContainer = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const CategoryButton = styled.button<CategoryButtonProps>`
  padding: 8px 16px;
  border-width: 1px 0px 1px 1px;
  border-style: solid;
  border-color: ${(props) =>
    props.backgroundColor === "#F2F5FF" ? "#F2F5FF" : "#DBDBDB"};

  background: ${(props) => props.backgroundColor};

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
`;

const CategoryPrice = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLigth12);
  margin-left: 8px;
`;

const CategoryPriceRadioLabel = styled.label`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #c7c7c7;
  cursor: pointer;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: 11px 11px;
`;

const CategoryRadioBtn = styled.input`
  display: none;

  &:checked ~ ${CategoryPriceRadioLabel} {
    background-image: url("/assets/insidecircle.svg");
  }
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid var(--GrayLight4);
  padding: 16px 20px;
  gap: 10px;
`;

const SearchButton = styled.button`
  width: 110px;
  height: 36px;
  background: var(--blue);
  border-radius: 8px;

  color: var(--TextWhite);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: var(--transition);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background: var(--lightBlue);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    background: var(--darkBlue);
  }
`;

const ClearButton = styled.button`
  color: var(--GrayLight12);

  border-radius: 6px;
  border: 1px solid var(--GrayLight8);
  transition: var(--transition);

  padding: 8px 16px;
  /* width: 76px; */
  height: 36px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    border-color: var(--lightBlue);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    border-color: var(--blue);
  }
`;
