import { observer } from "mobx-react-lite";
import React, { FormEvent, FormEventHandler, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import emailjs from "@emailjs/browser";

const Preview = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    emailjs.sendForm(
      "service_a8lisud",
      "template_5u3u68n",
      e.target,
      "OJENSqLL1MSqI7wvN"
    );
    setName("");
    setPhone("");
    setEmail("");
  };

  const handleTry = () => {
    navigate("/login");
  };

  return (
    <Wrapper>
      <Header>
        <LogoContainer src="/assets/Header/stackrus_logo.svg" />
      </Header>
      <Container>
        <TextForm>
          <TitleText>Множество сфер.</TitleText>
          <TitleText>Одно решение.</TitleText>
          <SubTitleText>
            Трейдинговая площадка, которая помогает заключать сделки по
            покупке/продаже продукции для любой сферы деятельности.
          </SubTitleText>
        </TextForm>
      </Container>
      <ColoredContainer>
        <InfoForm>
          <InfoFormTitle>Для поставщиков</InfoFormTitle>
          <InfoFormContentLine>
            <Dot />
            <ContentText>размещение предложений продукции</ContentText>
          </InfoFormContentLine>
          <InfoFormContentLine>
            <Dot />
            <ContentText>
              онлайн аналитика по рынку и история сделок
            </ContentText>
          </InfoFormContentLine>
          <InfoFormContentLine>
            <Dot />
            <ContentText>мониторинг спроса и предложения</ContentText>
          </InfoFormContentLine>
          <InfoFormContentLine>
            <Dot />
            <ContentText>формирование будущих объёмов поставок</ContentText>
          </InfoFormContentLine>
        </InfoForm>
        <InfoForm>
          <InfoFormTitle>Для покупателей</InfoFormTitle>
          <InfoFormContentLine>
            <Dot />
            <ContentText>единый каталог продукции</ContentText>
          </InfoFormContentLine>
          <InfoFormContentLine>
            <Dot />
            <ContentText>определение оптимальной цены</ContentText>
          </InfoFormContentLine>
          <InfoFormContentLine>
            <Dot />
            <ContentText>онлайн аналитика изменения цен</ContentText>
          </InfoFormContentLine>
          <InfoFormContentLine>
            <Dot />
            <ContentText>контроль качества приобретаемой продукции</ContentText>
          </InfoFormContentLine>
          <InfoFormContentLine>
            <Dot />
            <ContentText>выход на ранние сделки</ContentText>
          </InfoFormContentLine>
        </InfoForm>
      </ColoredContainer>
      <ImageContainer>
        <MobileImg src="/assets/Preview_mobile.svg" />
        <TextForm>
          <TitleText>Готовое решение для вашего бизнеса.</TitleText>
          <SubTitleText>Планы от 100 000 рублей в месяц</SubTitleText>
        </TextForm>
        <SendButton onClick={() => handleTry()}>
          Попробовать демо-версию
        </SendButton>
      </ImageContainer>
      <Container>
        <TextForm>
          <TitleText>Оставьте контактные данные.</TitleText>
          <SubTitleText>
            Мы свяжемся с вами в течение 3-х рабочих дней.
          </SubTitleText>
        </TextForm>
        <InputForm onSubmit={handleSubmit}>
          <Input
            placeholder="Имя*"
            onChange={(e) => setName(e.target.value)}
            value={name}
            type="text"
            name="from_name"
          ></Input>
          <Input
            placeholder="Email*"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            name="from_email"
          ></Input>
          <Input
            placeholder="Телефон"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            type="tel"
            name="phone"
          ></Input>
          <SendButton>Оставить контактные данные</SendButton>
        </InputForm>
      </Container>
      <ColoredFilesContainer>
        <TitleText>Документация</TitleText>
        <FilesGroup>
          <FilesFormContainer>
            <InfoFormTitle>Установка и поддержка ПО</InfoFormTitle>
            <FilesContainer>
              <a
                href="/files/technoly_instruction.pdf"
                download="Установка_и_поддержка_ПО_StackRUS"
              >
                <PdfImg src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1656403710/daark/Projects/pdf-svgrepo-com_de5k7n.svg" />
              </a>
              <a
                href="/files/technoly_instruction.pdf"
                download="Установка_и_поддержка_ПО_StackRUS"
              >
                <PdfText>Скачать</PdfText>
              </a>
            </FilesContainer>
          </FilesFormContainer>
          <FilesFormContainer>
            <InfoFormTitle>Эксплуатация ПО</InfoFormTitle>
            <FilesContainer>
              <a
                href="/files/user_instruction.pdf"
                download="Эксплуатация_ПО_StackRUS"
              >
                <PdfImg src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1656403710/daark/Projects/pdf-svgrepo-com_de5k7n.svg" />
              </a>
              <a
                href="/files/user_instruction.pdf"
                download="Эксплуатация_ПО_StackRUS"
              >
                <PdfText>Скачать</PdfText>
              </a>
            </FilesContainer>
          </FilesFormContainer>
          <FilesFormContainer>
            <InfoFormTitle>Функциональные характеристики ПО</InfoFormTitle>
            <FilesContainer>
              <a
                href="/files/specifications.pdf"
                download="Функциональные_характеристики_ПО_StackRUS"
              >
                <PdfImg src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1656403710/daark/Projects/pdf-svgrepo-com_de5k7n.svg" />
              </a>
              <a
                href="/files/specifications.pdf"
                download="Функциональные_характеристики_ПО_StackRUS"
              >
                <PdfText>Скачать</PdfText>
              </a>
            </FilesContainer>
          </FilesFormContainer>
        </FilesGroup>
      </ColoredFilesContainer>
    </Wrapper>
  );
};

export default observer(Preview);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
`;

const Header = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0px;
  background: #fafafa;
`;

const LogoContainer = styled.img`
  width: 50px;
  height: 50px;
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 150px;

  @media screen and (max-width: 430px) {
    width: calc(100% - 32px);
  }

  @media screen and (max-width: 1500px) {
    min-height: calc(100vh / 0.8);
  }
`;

const ColoredContainer = styled(Container)`
  flex-direction: row;
  background: #ecf1f4;
  gap: 32px;
  width: 100%;

  @media screen and (max-width: 430px) {
    flex-direction: column;
    padding: 32px 0px;
  }
`;

const ImageContainer = styled(Container)`
  background-image: url("/assets/Preview.svg");
  background-repeat: no-repeat;
  width: 100%;
  background-position-x: center;
  background-position-y: bottom;
  background-size: contain;

  @media screen and (max-width: 430px) {
    background-image: none;
    width: calc(100% - 32px);
  }
`;

const TextForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const TitleText = styled.h1`
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 130%;
  text-align: center;

  color: #333333;

  @media screen and (max-width: 430px) {
    font-size: 32px;
  }
`;

const SubTitleText = styled.h5`
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 130%;
  /* or 39px */

  text-align: center;

  color: #8c8c8c;

  max-width: 890px;

  @media screen and (max-width: 430px) {
    font-size: 24px;
  }
`;

const InfoForm = styled.div`
  width: fit-content;
  max-width: 500px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  background: #fafafa;
  border-radius: 16px;

  @media screen and (max-width: 430px) {
    width: calc(100% - 32px);
  }
`;

const InfoFormTitle = styled.h3`
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 34px;
  /* identical to box height, or 94% */

  color: #000000;

  text-align: center;

  @media screen and (max-width: 430px) {
    font-size: 24px;
  }
`;

const InfoFormContentLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;

  @media screen and (max-width: 430px) {
    font-size: 12px;
  }
`;

const Dot = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: black;
  margin-top: 15px;
`;

const ContentText = styled.div`
  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  /* or 155% */

  color: #000000;
  text-align: left;
  align-self: stretch;
  flex-grow: 0;

  max-width: 390px;
`;

const InputForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  max-width: 600px;

  gap: 32px;

  @media screen and (max-width: 430px) {
    gap: 24px;
  }
`;

const Input = styled.input`
  width: 100%;
  border: unset;
  border-bottom: 2px solid #b0b0b0;
  padding-left: 10px;
  height: 36px;
  background: transparent;

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: black;
  border-radius: unset;

  &::placeholder {
    font-family: "Commissioner";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */

    color: #8c8c8c;
  }

  @media screen and (max-width: 430px) {
    font-size: 18px;

    &::placeholder {
      font-size: 18px;
    }
  }
`;

const SendButton = styled.button`
  width: fit-content;
  padding: 12px 56px;

  font-family: "Commissioner";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #ffffff;

  background: #365edc;
  border-radius: 6px;
  cursor: pointer;

  @media screen and (max-width: 430px) {
    width: 100%;
    height: 64px;

    font-size: 18px;
  }
`;

const MobileImg = styled.img`
  display: none;

  @media screen and (max-width: 430px) {
    display: block;
    width: 100%;
    height: 180px;
  }
`;

const FilesFormContainer = styled(InfoForm)`
  width: 450px;
  height: 250px;
  max-width: unset;

  justify-content: space-between;

  gap: 60px;

  @media screen and (max-width: 430px) {
    height: 200px;
    gap: 15px;
  }
`;

const PdfImg = styled.img`
  width: 60px;
  height: 60px;
`;

const PdfText = styled.div`
  font-weight: 400;
  margin-left: 5px;

  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  /* or 155% */

  color: #000000;
  text-align: left;

  transition: var(--transition);
`;

const FilesContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover a div {
    color: #8c8c8c;
  }
`;

const ColoredFilesContainer = styled(ColoredContainer)`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 100px;
`;

const FilesGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 430px) {
    flex-direction: column;
  }
`;
