import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

interface SampleQulityDropdownProps {
  name: string;
  changeSelectedValue: Function;
  idNumber: number;
  closeFunction: Function;
}

const SampleQulityDropdownItem = ({
  name,
  changeSelectedValue,
  idNumber,
  closeFunction,
}: SampleQulityDropdownProps) => {
  return (
    <Item
      onClick={() => {
        changeSelectedValue(idNumber, name);
        closeFunction();
      }}
    >
      <ItemName>{name}</ItemName>
    </Item>
  );
};

export default observer(SampleQulityDropdownItem);

const ItemName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  transition: var(--transition);
`;

const Item = styled.div`
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: var(--transition);

  &:hover {
    background: var(--GrayLight2);
  }

  &:hover ${ItemName} {
    color: var(--blue);
  }
`;
