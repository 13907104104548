import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

interface CheckBoxProps {
  checked: boolean;
}

const CheckBox = ({ checked }: CheckBoxProps) => {
  return (
    <CheckboxContainer>
      <HiddenCheckbox checked={checked} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};

export default observer(CheckBox);

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  width: 16px;
  height: 16px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledCheckbox = styled.div<CheckBoxProps>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${(props) => (props.checked ? "#171717" : "white")};
  /* border: 2px solid black; */
  border: 1px solid ${(props) => (props.checked ? "171717" : " #DBDBDB")};
  border-radius: 4px;
  transition: var(--transition);

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;
