import { types } from "mobx-state-tree";
import { GetHistoryTypes } from "../utils/GetHistoryType";
import { DetailedRequestApprovalHistoryInterface } from "./DetailedRequestStore";

export const FilterApprovalHistoryStore = types
  .model({
    Date: types.optional(types.array(types.string), []),
    Time: types.optional(types.array(types.string), []),
    Actions: types.optional(types.array(types.string), []),
    Traders: types.optional(types.array(types.string), []),
  })
  .actions((self) => ({
    changeDateFilter(date: string) {
      let indexOf = self.Date.findIndex((e) => e === date);
      if (indexOf > -1) {
        self.Date.splice(indexOf, 1);
      } else {
        self.Date.push(date);
      }
    },
    resetDateFilter() {
      self.Date.splice(0, self.Date.length);
    },
    changeTimeFilter(time: string) {
      let indexOf = self.Time.findIndex((e) => e === time);
      if (indexOf > -1) {
        self.Time.splice(indexOf, 1);
      } else {
        self.Time.push(time);
      }
    },
    resetTimeFilter() {
      self.Time.splice(0, self.Time.length);
    },
    changeActionsilter(action: string) {
      let indexOf = self.Actions.findIndex((e) => e === action);
      if (indexOf > -1) {
        self.Actions.splice(indexOf, 1);
      } else {
        self.Actions.push(action);
      }
    },
    resetActionsFilter() {
      self.Actions.splice(0, self.Actions.length);
    },
    changeTradersFilter(trader: string) {
      let indexOf = self.Traders.findIndex((e) => e === trader);
      if (indexOf > -1) {
        self.Traders.splice(indexOf, 1);
      } else {
        self.Traders.push(trader);
      }
    },
    resetTradersFilter() {
      self.Traders.splice(0, self.Traders.length);
    },
  }))
  .views((self) => ({
    getDateFilterStatus(date: string) {
      return self.Date.includes(date);
    },
    getTimeFilterStatus(time: string) {
      return self.Time.includes(time);
    },
    getActionsFilterStatus(action: string) {
      return self.Actions.includes(action);
    },
    getTradersFilterStatus(trader: string) {
      return self.Traders.includes(trader);
    },
    FilteredData(payload: Array<any>) {
      let filteredData = payload;

      //---filter for date---///
      if (self.Date.length !== 0) {
        filteredData = filteredData.filter((e: any) =>
          self.Date.includes(e.date)
        );
      }

      //---filter for time---///
      if (self.Time.length !== 0) {
        filteredData = filteredData.filter((e: any) =>
          self.Time.includes(e.time)
        );
      }

      //---filter for actions---///
      if (self.Actions.length !== 0) {
        filteredData = filteredData.filter((e: any) =>
          self.Actions.includes(GetHistoryTypes(e.action))
        );
      }

      //---filter for traders---///
      if (self.Traders.length !== 0) {
        filteredData = filteredData.filter((e: any) =>
          self.Traders.includes(e.user_id)
        );
      }

      return filteredData;
    },
  }));
