import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import CheckBox from "../../CheckBox";

interface TableDropdownItemProps {
  name: string;
  changeStatusFunction: Function;
  checkStatusFunc: Function;
}

const TableDropdownItem = ({
  name,
  changeStatusFunction,
  checkStatusFunc,
}: TableDropdownItemProps) => {
  return (
    <Item onClick={() => changeStatusFunction(name)}>
      <CheckBox checked={checkStatusFunc(name)} />
      <ItemName>{name}</ItemName>
    </Item>
  );
};

export default observer(TableDropdownItem);

const ItemName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  transition: var(--transition);
`;

const Item = styled.div`
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: var(--transition);

  &:hover {
    background: var(--GrayLight2);
  }

  &:hover ${ItemName} {
    color: var(--blue);
  }
`;
