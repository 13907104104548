import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TableDropdownItem from "./TableDropdownItem";
import { TableCell } from "@mui/material";
import SearchBar from "../../SearchBar";
import { ResetButton } from "../../../styles/Buttons/ResetButton";
import NoResultsDropdown from "../../NoResultsDropdown";
import listenForOutsideClicks from "../../../utils/ListenForOutsideClicks";

interface TableDropdownProps {
  label: string;
  items: Array<string>;
  placeholder: string;
  className?: string;
  changeValueFunc: Function;
  searchingValue: string;
  changeStatusFunction: Function;
  checkStatusFunc: Function;
  resetFunction: Function;
}

export interface TableCellProps {
  color: string;
}

const TableDropdown = ({
  label,
  items,
  placeholder,
  className,
  changeValueFunc,
  searchingValue,
  changeStatusFunction,
  checkStatusFunc,
  resetFunction,
}: TableDropdownProps) => {
  const [open, setOpen] = useState(false);

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  useEffect(() => {
    changeValueFunc("");
  }, [open]);
  return (
    <Container ref={menuRef}>
      <Button
        onClick={() => setOpen(!open)}
        color={open ? "#365EDC" : "#858585"}
      >
        <div>{label}</div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
            fill="#858585"
          />
        </svg>
      </Button>
      {open && (
        <Menu className={className}>
          <SearchBar
            placeholder={placeholder}
            changeValueFunc={changeValueFunc}
          />
          {items.filter((e) =>
            e.toUpperCase().includes(searchingValue.trim().toUpperCase())
          ).length != 0 ? (
            items
              .filter((e: string) =>
                e.toUpperCase().includes(searchingValue.trim().toUpperCase())
              )
              .map((e) => {
                return (
                  <TableDropdownItem
                    name={e}
                    changeStatusFunction={changeStatusFunction}
                    checkStatusFunc={checkStatusFunc}
                  />
                );
              })
          ) : (
            <NoResultsDropdown
              searchingItem={searchingValue}
            ></NoResultsDropdown>
          )}

          <ResetButton onClick={() => resetFunction()}>Сбросить</ResetButton>
        </Menu>
      )}
    </Container>
  );
};

export default observer(TableDropdown);

const Container = styled(TableCell)`
  position: relative;
  border: unset !important;
`;

const Button = styled.button<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  background-color: transparent;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  transition: var(--transition);

  & svg path {
    fill: ${(props) => props.color};
  }

  &:hover {
  }

  & .active {
  }
`;

const Menu = styled.div`
  position: absolute;
  top: calc(100%);
  right: 0;
  left: 0;
  z-index: 1;
  width: 225px;
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);

  max-height: 250px;
  overflow: scroll;
`;
