import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Wrapper } from "../../styles/Wrapper";
import LoginLogo from "../../components/LoginLogo";
import { LoginTitle } from "../../styles/Login/LoginTitle";
import { LoginSubtitle } from "../../styles/Login/LoginSubtitle";
import { FormContainer } from "../../styles/Login/FormContainer";
import { LoginContainer } from "../../styles/Login/LoginContainer";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { BaseURL } from "../../utils/BaseUrl";
import { ResData } from "./Login";

function AfterLogin() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(59);

  const handleRepeatLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const body = {
      email: state.email,
      password: null,
    };

    setTimer(59);
    axios
      .post(`${BaseURL}/token/`, body)
      .then((res: AxiosResponse<ResData>) => {
        // console.log(res);
        if (res) {
        }
      })
      .catch((err: any) => {
        // console.log(err);
        navigate("/login/error");
      });
  };

  useEffect(() => {
    timer > 0 && setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  return (
    <Wrapper>
      <LoginContainer>
        <LoginLogo />
        <FormContainer onSubmit={(e) => handleRepeatLogin(e)}>
          <Img src="/assets/Login/Mail_green.svg" alt="email" />
          <LoginTitle>
            Ссылка на вход была отправлена на указанную почту
          </LoginTitle>
          <LoginSubtitle>
            Если ваша почта зарегистрирована в системе, вам придет ссылка на
            вход. Проверьте “Входящие” и “Спам”.
          </LoginSubtitle>
          <Button disabled={timer > 0} type="submit">
            {timer > 0
              ? `Отправить повторно через 00:${timer > 9 ? timer : "0" + timer}`
              : "Отправить повторно"}
          </Button>
          <SubButton type="button" onClick={() => navigate("/login")}>
            <Link to="/login">Использовать другую электронную почту</Link>
          </SubButton>
        </FormContainer>
      </LoginContainer>
    </Wrapper>
  );
}

export default observer(AfterLogin);

const Img = styled.img`
  width: 65px;
  height: 65px;
`;

const Button = styled.button`
  width: 100%;
  height: 56px;
  background: var(--lightBlue3);
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--darkBlue);
  border-radius: 8px;
  transition: var(--transition);

  &:hover {
    background: var(--lightBlue2);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    background: white;
  }
`;

const SubButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 6px;
  transition: var(--transition);

  & a {
    color: var(--blue);
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    transition: var(--transition);
  }

  &:hover {
    background: var(--lightBlue3);
  }

  &:hover a {
    color: var(--darkBlue);
  }

  &:active {
    background: transparent;
  }
`;
