import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { usePersistentStore } from "../../../../store/RootStore";
import CheckBox from "../../../CheckBox";
import NewRequestEditProductModal from "../../Modals/NewRequestEditProductModal";
import { ButtonTertriary } from "../../../../styles/Buttons/ButtonTertriary";
import NewRequestAddSampleQualityModal from "../../Modals/NewRequestAddSampleQualityModal";
import { autorun } from "mobx";

interface NewRequestProductsTableRowProps {
  row: any;
}

interface TableRowProps {
  checked: boolean;
}

const NewRequestProductsTableRow = ({
  row,
}: NewRequestProductsTableRowProps) => {
  const { DetailedRequestStore, NewRequestStore } = usePersistentStore();
  const [openEditProductModal, setOpenEditProductModal] = useState(false);
  const [openAddSampleQualityModal, setOpenAddSampleQualityModal] =
    useState(false);
  const [sampleQualityString, setSampleQualityString] = useState("");

  const handleOpenEditProductModal = () => {
    NewRequestStore.removeAllSelections();
    NewRequestStore.changeSelectionOnProduct(row.uuid);
    setOpenEditProductModal(true);
  };

  const handleCloseEditProductModal = () => {
    NewRequestStore.removeAllSelections();
    setOpenEditProductModal(false);
  };

  const handleOpenAddSampleQualityModal = () => {
    NewRequestStore.removeAllSelections();
    NewRequestStore.changeSelectionOnProduct(row.uuid);
    setOpenAddSampleQualityModal(true);
  };

  const handleCloseAddSampleQualityModal = () => {
    NewRequestStore.removeAllSelections();
    setOpenAddSampleQualityModal(false);
  };

  useEffect(
    () =>
      autorun(() => {
        let newSampleQualityString = "";
        row.SampleQuality.map((e: any) => {
          newSampleQualityString = newSampleQualityString.concat(
            `${e.key.slice(0, 3)} = ${e.value} | `
          );
        });
        setSampleQualityString(newSampleQualityString);
      }),
    [row]
  );

  return (
    <StyledTableRow checked={row.isSelected}>
      <FirstStyledCell
        onClick={() => NewRequestStore.changeSelectionOnProduct(row.uuid)}
      >
        <CheckBox checked={row.isSelected} />
      </FirstStyledCell>
      <StyledCell>
        <TableCellContainer>
          <NameTitle>{row.agro_culture_type.split(" ")[0]}</NameTitle>
          {/* <NameSubtitle>13,5-14,4% (23-24%)</NameSubtitle> */}
        </TableCellContainer>
      </StyledCell>
      <TextRightCell>
        {row.value} {row.measure_id === "Килограмм" ? "кг" : "т"}
      </TextRightCell>
      <TextRightCell>{row.harvest_year}</TextRightCell>
      <TextRightCell>{row.price[0].unit_offered_price}</TextRightCell>
      <TextRightCell>
        <TextRightUnderlinedCell>
          {row.price[0].unit_agreed_price}
        </TextRightUnderlinedCell>
      </TextRightCell>

      <TextRightCell>{row.price[0].unit_delivery_price}</TextRightCell>
      <TextRightCell>{row.price[0].unit_agreed_delivery_price}</TextRightCell>
      <UnderlinedCell>
        {row.SampleQuality.length === 0 ? (
          <AddSampleQualityButton
            onClick={() => handleOpenAddSampleQualityModal()}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8493 9.17039H10.8705V4.19161C10.8705 3.97154 10.7831 3.76048 10.6275 3.60486C10.4718 3.44924 10.2608 3.36182 10.0407 3.36182C9.82063 3.36182 9.60957 3.44924 9.45396 3.60486C9.29834 3.76048 9.21091 3.97154 9.21091 4.19161V9.17039H4.23214C4.01206 9.17039 3.801 9.25781 3.64539 9.41343C3.48977 9.56905 3.40234 9.78011 3.40234 10.0002C3.40234 10.2203 3.48977 10.4313 3.64539 10.5869C3.801 10.7426 4.01206 10.83 4.23214 10.83H9.21091V15.8088C9.21091 16.0288 9.29834 16.2399 9.45396 16.3955C9.60957 16.5511 9.82063 16.6385 10.0407 16.6385C10.2608 16.6385 10.4718 16.5511 10.6275 16.3955C10.7831 16.2399 10.8705 16.0288 10.8705 15.8088V10.83H15.8493C16.0694 10.83 16.2804 10.7426 16.436 10.5869C16.5917 10.4313 16.6791 10.2203 16.6791 10.0002C16.6791 9.78011 16.5917 9.56905 16.436 9.41343C16.2804 9.25781 16.0694 9.17039 15.8493 9.17039Z"
                fill="#3D9A50"
              />
            </svg>
            <div>Добавить пробы</div>
          </AddSampleQualityButton>
        ) : (
          <>
            {sampleQualityString.length > 10
              ? `${sampleQualityString.slice(0, 10).trim()}...`
              : sampleQualityString}
          </>
        )}
      </UnderlinedCell>
      <StyledCell>{row.price[0].unit_agreed_price === 30000}</StyledCell>
      <FirstStyledCell onClick={() => handleOpenEditProductModal()}>
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.71204 18.9363H12.2304C12.3396 18.937 12.4478 18.916 12.5489 18.8747C12.65 18.8334 12.742 18.7726 12.8195 18.6957L18.5617 12.9452L20.9183 10.6384C20.9961 10.5612 21.0578 10.4695 21.1 10.3683C21.1421 10.2672 21.1638 10.1588 21.1638 10.0492C21.1638 9.93967 21.1421 9.83121 21.1 9.7301C21.0578 9.62898 20.9961 9.5372 20.9183 9.46006L17.4 5.90024C17.3229 5.82246 17.2311 5.76073 17.13 5.7186C17.0289 5.67647 16.9204 5.65479 16.8109 5.65479C16.7013 5.65479 16.5928 5.67647 16.4917 5.7186C16.3906 5.76073 16.2988 5.82246 16.2217 5.90024L13.8817 8.24856L8.12289 13.999C8.04598 14.0766 7.98514 14.1685 7.94384 14.2696C7.90255 14.3707 7.88162 14.479 7.88225 14.5882V18.1065C7.88225 18.3266 7.96967 18.5377 8.12529 18.6933C8.28091 18.8489 8.49197 18.9363 8.71204 18.9363ZM16.8109 7.6594L19.1592 10.0077L17.9809 11.186L15.6325 8.83771L16.8109 7.6594ZM9.54184 14.9284L14.4625 10.0077L16.8109 12.356L11.8902 17.2767H9.54184V14.9284ZM21.9888 20.5959H7.05245C6.83238 20.5959 6.62131 20.6833 6.4657 20.839C6.31008 20.9946 6.22266 21.2056 6.22266 21.4257C6.22266 21.6458 6.31008 21.8569 6.4657 22.0125C6.62131 22.1681 6.83238 22.2555 7.05245 22.2555H21.9888C22.2089 22.2555 22.4199 22.1681 22.5755 22.0125C22.7311 21.8569 22.8186 21.6458 22.8186 21.4257C22.8186 21.2056 22.7311 20.9946 22.5755 20.839C22.4199 20.6833 22.2089 20.5959 21.9888 20.5959Z"
            fill="#2F6E3B"
          />
        </svg>
      </FirstStyledCell>
      {openEditProductModal && (
        <NewRequestEditProductModal
          closeFunction={() => handleCloseEditProductModal()}
        />
      )}
      {openAddSampleQualityModal && (
        <NewRequestAddSampleQualityModal
          closeFunction={() => handleCloseAddSampleQualityModal()}
        />
      )}
    </StyledTableRow>
  );
};

export default observer(NewRequestProductsTableRow);

const StyledTableRow = styled(TableRow)<TableRowProps>`
  border: unset !important;
  transition: var(--transition);
  background: ${(props) => (props.checked ? "#F2F5FF" : "white")};

  &:hover {
    background: var(--lightBlue3);
  }
`;

const StyledCell = styled(TableCell)`
  padding: 20px 12px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: unset !important;

  & span {
    text-decoration: underline;
    color: var(--GrayLight11) !important;
  }
`;
const TextRightCell = styled(StyledCell)`
  text-align: right !important;
`;

const UnderlinedCell = styled(StyledCell)`
  text-decoration: underline;
  color: var(--GrayLight11) !important;
`;

const TextRightUnderlinedCell = styled.div`
  text-align: right !important;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;

  text-decoration: underline;
  color: var(--GrayLight11) !important;
`;

const TableCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

const FirstStyledCell = styled(StyledCell)`
  cursor: pointer;
`;

const NameTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: var(--GrayLight12);
`;

const NameSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: var(--GrayLight12);
`;

const AddSampleQualityButton = styled(ButtonTertriary)`
  text-decoration: unset;
  white-space: nowrap;
  padding: unset;
`;
