import { types } from "mobx-state-tree";
import createPersistentStore from "mst-persistent-store";
import { UserStore } from "./UserStore";
import { NotificationStore } from "./NotificationStore";
import { FiltersStore } from "./FiltersStore";
import { RequestStore } from "./RequestStore";
import { DetailedRequestStore } from "./DetailedRequestStore";
import { FilterDetailedRequestStore } from "./FiltersDetailedRequest";
import { FilterApprovalHistoryStore } from "./FiltersApprovalHistoryStore";
import { NewRequestStore } from "./NewRequestStore";
import { SimpleDictionaryStore } from "./SimpleDictionaryStore";

export const RootStore = types.model({
  UserStore: types.optional(UserStore, {}),
  NotificationStore: types.optional(NotificationStore, {}),
  FiltersStore: types.optional(FiltersStore, {}),
  RequestStore: types.optional(RequestStore, {}),
  DetailedRequestStore: types.optional(DetailedRequestStore, {}),
  FiltersDetailedRequestStore: types.optional(FilterDetailedRequestStore, {}),
  FiltersApprovalHistoryStore: types.optional(FilterApprovalHistoryStore, {}),
  NewRequestStore: types.optional(NewRequestStore, {}),
  SimpleDictionaryStore: types.optional(SimpleDictionaryStore, {})
});

export const [PersistentStoreProvider, usePersistentStore] =
  createPersistentStore(RootStore, {}, {}, { writeDelay: 100, logging: false });

