import styled from "styled-components";

export const LoginSubtitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--SageLight12);
  text-align: center;
`;
