import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Wrapper } from "../../styles/Wrapper";
import styled from "styled-components";
import LoginLogo from "../../components/LoginLogo";
import { LoginTitle } from "../../styles/Login/LoginTitle";
import { LoginSubtitle } from "../../styles/Login/LoginSubtitle";
import { useNavigate } from "react-router-dom";
import { FormContainer } from "../../styles/Login/FormContainer";
import { LoginContainer } from "../../styles/Login/LoginContainer";
import axios, { AxiosResponse } from "axios";
import { BaseURL } from "../../utils/BaseUrl";
import { usePersistentStore } from "../../store/RootStore";
import { ButtonSecondary } from "../../styles/Buttons/ButtonSecondary";

export interface InputProps {
  color: string;
}

export interface ResData {
  result: boolean;
  msg: string;
  //   token: string;
}

function Login() {
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [typeErr, setTypeErr] = useState(false);
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const { UserStore } = usePersistentStore();

  const inputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const invalidInput = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTypeErr(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErr(false);

    if (email.split("@")[1].split(".").length > 1) {
      const body = {
        email: email,
        password: pass,
      };

      axios
        .post(`${BaseURL}/token/`, body)
        .then((res: AxiosResponse<any>) => {
          console.log(res);
          if (res.data) {
            // navigate("/login/success", { state: { email: email } });
            // console.log(res.data.token);
            // console.log(
            //   `http://grainrus.oldi.dev/auth?token=${res.data.token}`
            // );
            navigate(`/auth?token=${res.data.refresh}`);
          } else {
            navigate("/login/error");
          }
        })
        .catch((err: any) => {
          setErr(true);
          // console.log(err);
          //   navigate("/login/error");
        });
    } else {
      setTypeErr(true);
    }
  };

  const handleRegistration = () => {
    navigate("/registration");
  };

  return (
    <LoginWrapper>
      <LoginContainer>
        <LoginLogo />
        <FormContainer onSubmit={(e) => handleSubmit(e)}>
          <LoginTitle>Добро пожаловать</LoginTitle>
          {/* <LoginSubtitle>
            Введите адрес электронной почты, добавленной администратором
            организации
          </LoginSubtitle> */}
          <InputContainer>
            <Input
              placeholder="Введите ваш email"
              color={!typeErr ? "#DBDBDB" : "#CA3214"}
              type="email"
              value={email}
              onInvalid={(e) => invalidInput(e)}
              onInput={() => setTypeErr(false)}
              onChange={(e) => inputChange(e)}
              //   pattern="[a-z0-9_\.]+@\w+\.\w{2,5}"
            />
            <Input
              placeholder="Введите пароль"
              color={!typeErr ? "#DBDBDB" : "#CA3214"}
              type="password"
              value={pass}
              onChange={(e) => setPass(e.target.value)}
              //   pattern="[a-z0-9_\.]+@\w+\.\w{2,5}"
            />
            {typeErr && <TypeError>Указан неверный формат почты</TypeError>}
            {err && <TypeError>Неверный email или пароль</TypeError>}
          </InputContainer>
          <Button
            disabled={email === "" || typeErr || pass === ""}
            type="submit"
          >
            Войти
          </Button>
          {/* <RegisterButton onClick={() => handleRegistration()}>
            Зарегестрироваться
          </RegisterButton> */}
          <LoginInfo>
            <LoginSubtitle>
              Руководитель направления - логин: <b>test_star@b5g.ru</b>, пароль:{" "}
              <b>AQ!sw2de3</b>
            </LoginSubtitle>
            <LoginSubtitle>
              Сотрудник - логин: <b>test@b5g.ru</b>, пароль: <b>AQ!sw2de3s</b>
            </LoginSubtitle>
          </LoginInfo>
        </FormContainer>
      </LoginContainer>
    </LoginWrapper>
  );
}

export default observer(Login);

const LoginWrapper = styled(Wrapper)`
  justify-content: center;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

const Input = styled.input<InputProps>`
  font-family: "Inter";
  font-size: 18px;
  line-height: 25px;
  width: 100%;
  height: 56px;
  padding: 8px 12px;
  border: 1px solid var(--GrayLight7);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border-color: ${(props) => props.color};
  transition: var(--transition);

  &::placeholder {
    color: var(--GrayLight11);
  }

  &:hover {
    border-color: var(--blue);
  }

  &:focus {
    border-color: var(--GrayLight12);
  }
`;

const Button = styled.button`
  width: 100%;
  height: 56px;
  background: var(--blue);
  border-radius: 8px;
  color: var(--TextWhite);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  transition: var(--transition);

  &:hover {
    background: var(--lightBlue);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    background: var(--darkBlue);
  }
`;

const TypeError = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--ErrorColor);
  align-self: flex-start;
`;

const RegisterButton = styled(ButtonSecondary)`
  width: 100%;
  height: 56px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;

  justify-content: center;
  color: var(--blue);
`;

const LoginInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;
