import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { DownArrow } from "../../styles/DownArrow";
import SearchBar from "../SearchBar";
import DropdownItem from "./DropdownItem";
import NoResultsDropdown from "../NoResultsDropdown";
import listenForOutsideClicks from "../../utils/ListenForOutsideClicks";

interface DropdownProps {
  items: Array<string>;
  title: string;
  placeholder: string;
  changeValueFunc: Function;
  searchingValue: string;
  changeStatusFunction: Function;
  checkStatusFunc: Function;
}

export interface ButtonActiveProps {
  borderColor: string;
  backGroundColor: string;
}

const Dropdown = ({
  items,
  title,
  placeholder,
  changeValueFunc,
  searchingValue,
  changeStatusFunction,
  checkStatusFunc,
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  //   const [selection, setSelection] = useState();

  const toggleOpen = () => {
    setOpen(!open);
  };

  //   const handleSelection = (item: any) => {
  //     setSelection(item);
  //     // setOpen(false);
  //   };

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  useEffect(() => {
    changeValueFunc("");
  }, [open]);

  return (
    <Container ref={menuRef}>
      <Button
        onClick={toggleOpen}
        borderColor={open ? "#2F52BF" : "#DBDBDB"}
        backGroundColor={open ? "#F2F5FF" : "white"}
      >
        <div>{title}</div>
        <DownArrow src="/assets/Header/AccountMore.svg" />
      </Button>
      {open && (
        <Menu>
          <SearchBar
            placeholder={placeholder}
            changeValueFunc={changeValueFunc}
          />
          {items.filter((e: string) =>
            e.toUpperCase().includes(searchingValue.trim().toUpperCase())
          ).length !== 0 ? (
            items
              .filter((e: string) =>
                e.toUpperCase().includes(searchingValue.trim().toUpperCase())
              )
              .map((item: string, i: number) => (
                <DropdownItem
                  name={item}
                  key={i}
                  changeStatusFunction={changeStatusFunction}
                  checkStatusFunc={checkStatusFunc}
                />
              ))
          ) : (
            <NoResultsDropdown
              searchingItem={searchingValue}
            ></NoResultsDropdown>
          )}
        </Menu>
      )}
    </Container>
  );
};

export default observer(Dropdown);

const Container = styled.div`
  position: relative;
`;

const Button = styled.button<ButtonActiveProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  height: 36px;

  text-align: left;
  cursor: pointer;
  background-color: ${(props) => props.backGroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;
  padding: 7px 9px 7px 16px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: var(--transition);

  &:hover {
    border-color: var(--lightBlue);
  }

  & .active {
    border-color: var(--darkblue);
  }
`;

const Menu = styled.div`
  position: absolute;
  top: calc(100% + 3px);
  right: 0;
  z-index: 1;
  width: 225px;
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);

  max-height: 200px;
  overflow: scroll;
`;
