import { types } from "mobx-state-tree";

interface SimpleDictionaryInterface {
  сategory: CategoryInterface;
  data: DataInterface;
}

interface CategoryInterface {
  id: number;
  dictionary_name: string;
  is_dictionary: boolean;
  dictionary_description: string;
  is_visible: boolean;
  is_active: boolean;
  request_type: string;
  request_id: string;
  record_name_obligatory: boolean;
  record_code_obligatory: boolean;
  type_id: string | null;
}

interface DataInterface {
  id: number;
  record_name: string;
  record_code: string;
  record_id: string;
  record_description: string;
  record_value: string;
  is_visible: boolean;
  is_active: boolean;
  start_at: string;
  end_at: string | null;
  is_actual_now: boolean;
  dictionary_category_id: number;
}

const CategoryModel = types.model({
  id: types.optional(types.number, -1),
  dictionary_name: types.optional(types.string, ""),
  is_dictionary: types.optional(types.boolean, false),
  dictionary_description: types.optional(types.string, ""),
  is_visible: types.optional(types.boolean, false),
  is_active: types.optional(types.boolean, false),
  request_type: types.optional(types.string, ""),
  request_id: types.optional(types.string, ""),
  record_name_obligatory: types.optional(types.boolean, false),
  record_code_obligatory: types.optional(types.boolean, false),
  type_id: types.maybeNull(types.string),
});

const DataModel = types.model({
  id: types.optional(types.number, -1),
  record_name: types.optional(types.string, ""),
  record_code: types.optional(types.string, ""),
  record_id: types.optional(types.string, ""),
  record_description: types.optional(types.string, ""),
  record_value: types.optional(types.string, ""),
  is_visible: types.optional(types.boolean, false),
  is_active: types.optional(types.boolean, false),
  start_at: types.optional(types.string, ""),
  end_at: types.maybeNull(types.string),
  is_actual_now: types.optional(types.boolean, false),
  dictionary_category_id: types.optional(types.number, -1),
});

const SimpleDictionaryModel = types.model({
  category: types.optional(CategoryModel, {}),
  data: types.optional(types.array(DataModel), []),
});

const CounterPartyListModel = types.model({
  id: types.optional(types.number, -1),
  full_name: types.optional(types.string, ""),
  short_name: types.optional(types.string, ""),
  //   start_at_version: types.optional(types.string, ""),
  //   end_at_version: types.maybeNull(types.string),
  //   is_active_version: types.optional(types.boolean, false),
  //   is_actual_now_version: types.optional(types.boolean, false),
  //   inn: types.maybeNull(types.string),
  //   ogrn: types.maybeNull(types.string),
  //   snils: types.maybeNull(types.string),
});

const UserCompanyModel = types.model({
  role: types.optional(types.string, ""),
  user_uuid: types.optional(types.string, ""),
  first_name: types.optional(types.string, ""),
  second_name: types.optional(types.string, ""),
  last_name: types.optional(types.string, ""),
});

const CompanyModel = types.model({
  company_id: types.optional(types.number, -1),
  company_full_name: types.optional(types.string, ""),
  user_list: types.optional(types.array(UserCompanyModel), []),
});

export const SimpleDictionaryStore = types
  .model({
    SimpleDictionary: types.optional(types.array(SimpleDictionaryModel), []),
    CounterPartList: types.optional(types.array(CounterPartyListModel), []),
    Companies: types.optional(types.array(CompanyModel), []),
  })
  .actions((self) => ({
    setSimpleDictionaryStore(payload: any) {
      self.SimpleDictionary = payload;
    },
    setCounterPartyList(payload: any) {
      self.CounterPartList = payload;
    },
    clearSimpleDictionary() {
      self.SimpleDictionary.splice(0, self.SimpleDictionary.length);
    },
    clearCounterPartyList() {
      self.CounterPartList.splice(0, self.CounterPartList.length);
    },
    setCompanies(payload: any) {
      self.Companies = payload;
    },
    clearCompanies() {
      self.Companies.splice(0, self.Companies.length);
    },
  }))
  .views((self) => ({
    getPointList() {
      let indexOf = self.SimpleDictionary.findIndex(
        (e) => e.category.dictionary_name === "getPointList"
      );
      let pointList = [] as Array<string>;
      if (indexOf > -1) {
        pointList = self.SimpleDictionary[indexOf].data.map(
          (e) => e.record_value
        );
      }
      return pointList;
    },
    getCargoClassList() {
      let indexOf = self.SimpleDictionary.findIndex(
        (e) => e.category.dictionary_name === "getCargoClassList"
      );
      let cargoClassList = [] as Array<string>;
      if (indexOf > -1) {
        cargoClassList = self.SimpleDictionary[indexOf].data.map(
          (e) => e.record_value
        );
      }
      return cargoClassList;
    },
    getAgroCultureTypeList() {
      let indexOf = self.SimpleDictionary.findIndex(
        (e) => e.category.dictionary_name === "getAgroCultureTypeList"
      );
      let agroCultureTypeList = [] as Array<string>;
      if (indexOf > -1) {
        agroCultureTypeList = self.SimpleDictionary[indexOf].data.map(
          (e) => e.record_value
        );
      }
      return agroCultureTypeList;
    },
    getCounterPartyList() {
      return self.CounterPartList.map((e) => e.full_name);
    },
    getDeliveryTermList() {
      let indexOf = self.SimpleDictionary.findIndex(
        (e) => e.category.dictionary_name === "getDeliveryTermList"
      );
      let deliveryTermList = [] as Array<string>;
      if (indexOf > -1) {
        deliveryTermList = self.SimpleDictionary[indexOf].data.map(
          (e) => e.record_value
        );
      }
      return deliveryTermList;
    },
    getGrainQualityParameterList() {
      let indexOf = self.SimpleDictionary.findIndex(
        (e) => e.category.dictionary_name === "getGrainQualityParameterList"
      );
      let grainQualityParameterList = [] as Array<string>;
      if (indexOf > -1) {
        grainQualityParameterList = self.SimpleDictionary[indexOf].data.map(
          (e) => e.record_name
        );
      }
      return grainQualityParameterList;
    },
    getCompanies() {
      return self.Companies;
    },
    getUserUUID(companyId: number, firstName: string, lastName: string) {
      let indexOf = self.Companies.findIndex((e) => e.company_id === companyId);
      //   console.log(
      //     "🚀 ~ file: SimpleDictionaryStore.tsx:168 ~ getUserUUID ~ indexOf",
      //     indexOf
      //   );
      let indexOfUser = -1;
      if (indexOf > -1) {
        indexOfUser = self.Companies[indexOf].user_list.findIndex(
          (e) => e.first_name === firstName && e.last_name === lastName
        );
      }
      //   console.log(
      //     "🚀 ~ file: SimpleDictionaryStore.tsx:174 ~ getUserUUID ~ indexOfUser",
      //     indexOfUser
      //   );
      return self.Companies[indexOf].user_list[indexOfUser].user_uuid;
    },
    getCompanyId(company: string) {
      return self.Companies.filter((e) => e.company_full_name === company)[0]
        .company_id;
    },
    getPointId(point: string) {
      let indexOf = self.SimpleDictionary.findIndex(
        (e) => e.category.dictionary_name === "getPointList"
      );
      if (indexOf > -1) {
        return self.SimpleDictionary[indexOf].data.filter(
          (e) => e.record_value === point
        )[0].id;
      }
    },
  }));
