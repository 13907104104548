import axios from "axios";
import { flow, types } from "mobx-state-tree";
import { BaseURL } from "../utils/BaseUrl";

export interface RequestPriceInterface {
  unit_offered_price: number;
  unit_agreed_price: number | null;
  unit_delivery_price: number;
  unit_agreed_delivery_price: number | null;
  comment: string | null;
  start_at_version: string | null;
  end_at_version: Date | null;
  is_active_version: boolean;
  is_actual_now_version: boolean;
}

export interface RequestInterface {
  request_number: number;
  uuid: string;
  counterparty_id: string | null;
  trader_from_company: string | null;
  delivery_tern_id: string;
  departure_point_id: string;
  agro_culture_type: string;
  contract_start_date: string;
  contract_end_date: string | null;
  number: string;
  conclusion_date: string | null;
  measure_id: string;
  sum: number;
  vat: number;
  harvest_year: number;
  contract_status_id: string;
  price: Array<RequestPriceInterface>;
  isFavourite: boolean;
  isSelected: boolean;
}

const CounterPartyCompanyModel = types.model({
  full_name: types.optional(types.string, ""),
  short_name: types.optional(types.string, ""),
});

const PriceModel = types.model({
  id: types.optional(types.number, -1),
  unit_offered_price: types.number,
  unit_agreed_price: types.maybeNull(types.number),
  unit_delivery_price: types.number,
  unit_agreed_delivery_price: types.maybeNull(types.number),
  comment: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  start_at_version: types.maybeNull(types.string),
  end_at_version: types.maybeNull(types.string),
  is_active_version: types.boolean,
  is_actual_now_version: types.boolean,
  unit_id: types.maybeNull(types.number),
  contract_id: types.optional(types.string, ""),
  currency_id: types.optional(types.number, -1),
});

const RequestModel = types
  .model({
    request_number: types.string,
    uuid: types.string,
    counterparty_id: types.maybeNull(types.number),
    trader_from_company: types.maybeNull(types.string),
    delivery_tern_id: types.string,
    departure_point_id: types.optional(types.string, ""),
    agro_culture_type: types.string,
    contract_start_date: types.string,
    contract_end_date: types.maybeNull(types.string),
    number: types.maybeNull(types.string),
    conclusion_date: types.maybeNull(types.string),
    measure: types.optional(types.string, ""),
    sum: types.maybeNull(types.number),
    vat: types.number,
    harvest_year: types.optional(types.number, 0),
    contract_status: types.string,
    price: types.array(PriceModel),
    counterparty_company: types.optional(CounterPartyCompanyModel, {}),
    cargo_class: types.optional(types.string, ""),
    tonna_price: types.optional(types.number, 0),
    isFavourite: types.optional(types.boolean, false),
    isSelected: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setFavourite() {
      self.isFavourite = !self.isFavourite;
    },
  }));

export const RequestStore = types
  .model({
    Requests: types.optional(types.array(RequestModel), []),
    onDetail: types.maybeNull(RequestModel),
  })
  .actions((self) => ({
    setRequests(payload: any) {
      self.Requests = payload;
    },
    setFavouriteRequest(uuid: string) {
      let indexOf = self.Requests.findIndex((el) => el.uuid === uuid);
      if (indexOf !== undefined) {
        self.Requests[indexOf].isFavourite =
          !self.Requests[indexOf].isFavourite;
      }
    },
    setOnDetail(request: any) {
      self.onDetail = request;
    },
    toggleIsSelected(uuid: string) {
      let indexOf = self.Requests.findIndex((e) => e.uuid === uuid);
      if (indexOf !== undefined) {
        self.Requests[indexOf].isSelected = !self.Requests[indexOf].isSelected;
      }
    },
    removeAllSelections() {
      self.Requests.forEach((e) => (e.isSelected = false));
    },
    updateRequests: flow(function* (token: string | null) {
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const result = yield axios.get(
        `${BaseURL}/v1/contracts/get_contracts/`,
        config
      );
      self.Requests = result.data;
    }),
  }))
  .views((self) => ({
    getRequests() {
      return self.Requests;
    },
    getOnDetailed() {
      return self.onDetail;
    },
    getSuppliers() {
      let suppliers = [] as Array<string>;
      self.Requests.forEach((e) => {
        if (
          !suppliers.includes(e.counterparty_company.short_name as string) &&
          e.counterparty_company
        ) {
          suppliers.push(e.counterparty_company.short_name as string);
        }
      });
      return suppliers;
    },
    getShipment() {
      let shipments = [] as Array<string>;
      self.Requests.forEach((e) => {
        if (
          !shipments.includes(e.delivery_tern_id as string) &&
          e.delivery_tern_id
        ) {
          shipments.push(e.delivery_tern_id as string);
        }
      });
      return shipments;
    },
    getCropYears() {
      let cropYears = [] as Array<string>;
      self.Requests.forEach((e) => {
        if (!cropYears.includes(String(e.harvest_year)) && e.harvest_year) {
          cropYears.push(String(e.harvest_year));
        }
      });
      return cropYears.sort((a: string, b: string) => {
        return Number(a) - Number(b);
      });
    },
    getAmountOfNew() {
      return self.Requests.filter((item) => item.contract_status === "NEW")
        .length;
    },
    getTraiders() {
      let traiders = [] as Array<string>;
      self.Requests.forEach((e) => {
        if (
          !traiders.includes(String(e.trader_from_company)) &&
          e.trader_from_company
        ) {
          traiders.push(String(e.trader_from_company));
        }
      });
      return traiders;
    },
    getAmountOfSelected() {
      return self.Requests.filter((e) => e.isSelected === true).length;
    },
    getIfExist(uuid: string) {
      return self.Requests.findIndex((e) => e.uuid === uuid);
    },
    getRequestByUUID(uuid: string) {
      return self.Requests.filter((e) => e.uuid === uuid)[0];
    },
    getSelected() {
      return self.Requests.filter((e) => e.isSelected === true)[0];
    },
  }));
