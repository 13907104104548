import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Wrapper } from "../../styles/Wrapper";
import LoginLogo from "../../components/LoginLogo";
import { LoginContainer } from "../../styles/Login/LoginContainer";
import { FormContainer } from "../../styles/Login/FormContainer";
import { LoginTitle } from "../../styles/Login/LoginTitle";
import { LoginSubtitle } from "../../styles/Login/LoginSubtitle";
import { Oval } from "react-loader-spinner";
import axios, { AxiosResponse } from "axios";
import { BaseURL } from "../../utils/BaseUrl";
import { useNavigate } from "react-router-dom";
import { usePersistentStore } from "../../store/RootStore";

export interface RequestTokenModel {
  access: string;
  refresh: string;
}

function LoginLoading() {
  const { UserStore, SimpleDictionaryStore } = usePersistentStore();
  const navigate = useNavigate();

  useEffect(() => {
    UserStore.clearStore();
    const token = window.location.search;
    const body = {
      refresh: token.split("?token=").join(""),
    };

    axios
      .get(`${BaseURL}/v1/sd/get_simple_dicts_cats/`)
      .then((res: AxiosResponse<any>) => {
        // console.log("🚀 ~ file: LoginLoading.tsx:44 ~ .then ~ res", res);
        SimpleDictionaryStore.setSimpleDictionaryStore(res.data);
      })
      .catch((err) => {
        console.log("🚀 ~ file: LoginLoading.tsx:47 ~ .then ~ err", err);
      });

    axios
      .post(`${BaseURL}/token/refresh/`, body)
      .then((res) => {
        // console.log("🚀 ~ file: LoginLoading.tsx:28 ~ .then ~ res", res);
        if (res) {
          UserStore.setToken(res.data);
          navigate("/request");
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: LoginLoading.tsx:29 ~ useEffect ~ err", err);
      });
  }, []);

  return (
    <Wrapper>
      <LoginContainer>
        <LoginLogo />
        <FormContainer>
          <LoginTitle>Вход в систему StackRUS</LoginTitle>
          <LoginSubtitle>
            Подождите, проверяем учетную запись. Через 10 секунд вы будете
            перенаправлены на платформу
          </LoginSubtitle>
          <Oval
            ariaLabel="loading-indicator"
            height={80}
            width={80}
            strokeWidth={5}
            strokeWidthSecondary={3}
            color="#2F52BF"
            secondaryColor="rgba(139, 187, 75, 0)"
          />
        </FormContainer>
      </LoginContainer>
    </Wrapper>
  );
}

export default observer(LoginLoading);
