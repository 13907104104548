export const GetHistoryTypes = (historyType: string) => {
  switch (historyType) {
    case "CREATE":
      return "Создание";
    case "ADD_PRODUCTS":
      return "Добавление продуктов";
    case "UPDATE_PRODUCTS":
      return "Обновление данных продукта";
    case "CHANGE_STATUS":
      return "Изменение статуса";
    case "TAKE_PROTOCOLS":
      return "Забор образцов";
    case "CHANGE_PRICE":
      return "Изменение стоимости";
    case "ADD_PROTOCOLS":
      return "Добавление проб";
    case "UPDATE_PROTOCOLS":
      return "Обновление проб";
    case "DELETE_PRODUCTS":
      return "Удаление товара";
    case "SEND_FOR_APPROVE":
      return "Отправлено на одобрение";
    case "SEND_PRICE_FOR_APPROVE":
      return 'Цена отправлена на одобрение"';
    case "APPROVED_CONTRACT":
      return "Заявка одобрена";
    case "APPROVED_PRICE":
      return "Цена одобрена";
    case "DECREASE_PRICE":
      return "Понизить цену";
    case "INCREASE_PRICE":
      return "Повысить цену";
    case "CONTRACT_ABORTED":
      return "Контракт разорван";
  }
  return historyType;
};
