import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePersistentStore } from "../store/RootStore";
import listenForOutsideClicks from "../utils/ListenForOutsideClicks";
import NotificationsModal from "./Notifications/Notifications";
import { getSnapshot } from "mobx-state-tree";
import axios, { AxiosResponse } from "axios";
import { BaseURL } from "../utils/BaseUrl";

export interface CurrentPageProps {
  color: string;
  borderColor: string;
}

export interface BellButtonProps {
  backgroundColor: string;
  borderColor: string;
}

function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    NotificationStore,
    UserStore,
    NewRequestStore,
    SimpleDictionaryStore,
  } = usePersistentStore();
  const [openAccount, setOpenAccount] = useState(false);

  const togleOpenAccount = () => {
    setOpenAccount(!openAccount);
  };

  const handleLogout = () => {
    setOpenAccount(false);
    UserStore.Logout();
    SimpleDictionaryStore.clearCounterPartyList();
    SimpleDictionaryStore.clearSimpleDictionary();
    SimpleDictionaryStore.clearCompanies();
    NotificationStore.clearNotifications();
  };

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(
    listenForOutsideClicks(listening, setListening, menuRef, openAccount)
  );

  const handleNewRequest = () => {
    navigate("/new_request/offer");
    NewRequestStore.setClearNewRequest();
  };

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };
    if (SimpleDictionaryStore.getCounterPartyList().length === 0) {
      axios
        .get(`${BaseURL}/get_counterparty_list/`, config)
        .then((res: AxiosResponse<any>) => {
          //   console.log("🚀 ~ file: Header.tsx:60 ~ .then ~ res", res);
          SimpleDictionaryStore.setCounterPartyList(res.data);
        })
        .catch((err) => {
          console.log("🚀 ~ file: Header.tsx:67 ~ .then ~ err", err);
          if (err.response.data.code === "token_not_valid") {
            UserStore.updateToken();
          }
        });
    }
    if (SimpleDictionaryStore.getCompanies().length === 0) {
      axios
        .get(`${BaseURL}/v1/contracts/trader_list_for_company/`, config)
        .then((res) => {
          //   console.log("🚀 ~ file: Header.tsx:69 ~ .then ~ res", res);
          SimpleDictionaryStore.setCompanies(res.data);
        })
        .catch((err) => {
          console.log("🚀 ~ file: Header.tsx:71 ~ .then ~ err", err);
          if (err.response.data.code === "token_not_valid") {
            UserStore.updateToken();
          }
        });
    }
  }, []);

  //   console.log(UserStore.getAccessToken());

  return (
    <Wrapper>
      <Container>
        <SideContainer>
          <Link to="/request" style={{ height: "50px" }}>
            <LogoContainer src="/assets/Header/stackrus_logo.svg" />
          </Link>
          <NavbarContainer>
            {/* <Link to="/">
              <NavTabs
                color={location.pathname === "/" ? "#171717" : "#6B716A"}
                borderColor={
                  location.pathname === "/" ? "#46A758" : "transparent"
                }
              >
                Главная
              </NavTabs>
            </Link> */}
            <Link to="/request">
              <NavTabs
                color={
                  location.pathname.includes("/request") ? "#171717" : "#6B716A"
                }
                borderColor={
                  location.pathname.includes("/request")
                    ? "#2F52BF"
                    : "transparent"
                }
              >
                Реестр заявок
              </NavTabs>
            </Link>
            {/* <Link to="/supplier">
              <NavTabs
                color={
                  location.pathname === "/supplier" ? "#171717" : "#6B716A"
                }
                borderColor={
                  location.pathname === "/supplier" ? "#2F52BF" : "transparent"
                }
              >
                Реестр поставщиков
              </NavTabs>
            </Link> */}
            {/* <Link to="/traiders">
              <NavTabs
                color={
                  location.pathname === "/traiders" ? "#171717" : "#6B716A"
                }
                borderColor={
                  location.pathname === "/traiders" ? "#2F52BF" : "transparent"
                }
              >
                Реестр трейдеров
              </NavTabs>
            </Link> */}
          </NavbarContainer>
        </SideContainer>
        <RightSideContainer>
          <Button onClick={() => handleNewRequest()}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7663 9.12775H10.7875V4.14898C10.7875 3.9289 10.7001 3.71784 10.5445 3.56223C10.3888 3.40661 10.1778 3.31918 9.9577 3.31918C9.73763 3.31918 9.52657 3.40661 9.37095 3.56223C9.21533 3.71784 9.12791 3.9289 9.12791 4.14898V9.12775H4.14913C3.92906 9.12775 3.71799 9.21518 3.56238 9.3708C3.40676 9.52641 3.31934 9.73747 3.31934 9.95755C3.31934 10.1776 3.40676 10.3887 3.56238 10.5443C3.71799 10.6999 3.92906 10.7873 4.14913 10.7873H9.12791V15.7661C9.12791 15.9862 9.21533 16.1973 9.37095 16.3529C9.52657 16.5085 9.73763 16.5959 9.9577 16.5959C10.1778 16.5959 10.3888 16.5085 10.5445 16.3529C10.7001 16.1973 10.7875 15.9862 10.7875 15.7661V10.7873H15.7663C15.9863 10.7873 16.1974 10.6999 16.353 10.5443C16.5086 10.3887 16.5961 10.1776 16.5961 9.95755C16.5961 9.73747 16.5086 9.52641 16.353 9.3708C16.1974 9.21518 15.9863 9.12775 15.7663 9.12775Z"
                fill="white"
              />
            </svg>

            <div>Добавить сделку</div>
          </Button>
          <NotificationsModal />
          <TraiderInfoContainer>
            <TraiderIMG>{`${UserStore.getProfileData().first_name[0]}${
              UserStore.getProfileData().last_name[0]
            }`}</TraiderIMG>
            <TraiderNameContainer>
              <TraiderName>
                {UserStore.getProfileData().first_name}{" "}
                {UserStore.getProfileData().last_name[0]}
              </TraiderName>
              <TraiderSubName>{UserStore.getRole()}</TraiderSubName>
            </TraiderNameContainer>
            <DropdownContainer ref={menuRef}>
              <DownArrow
                src="/assets/Header/AccountMore.svg"
                onClick={() => togleOpenAccount()}
              />
              {openAccount && (
                <AccountMenu>
                  <Item>Связаться с поддержкой</Item>
                  <Item onClick={() => handleLogout()}>Выйти</Item>
                </AccountMenu>
              )}
            </DropdownContainer>
          </TraiderInfoContainer>
        </RightSideContainer>
      </Container>
    </Wrapper>
  );
}

export default observer(Header);

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
  height: 60px;
  background: var(--GrayLight2);
  border-bottom: 1px solid #ededed;
`;

const Container = styled.div`
  max-width: 1576px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
  width: 100%;
`;

const SideContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 65px;
`;

const RightSideContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

const LogoContainer = styled.img`
  width: 150px;
  height: 50px;
`;

const NavbarContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

const NavTabs = styled.div<CurrentPageProps>`
  width: fit-content;
  padding: 19px 8px 15px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  border-bottom: 4px solid ${(props) => props.borderColor};
  cursor: pointer;
`;

const Button = styled.button`
  /* width: 180px; */
  height: 36px;
  padding: 8px 16px;

  background: var(--blue);
  border-radius: 6px;
  color: var(--TextWhite);
  transition: var(--transition);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  & svg,
  div {
    color: "#FFFFFF";
  }

  &:hover {
    background: var(--lightBlue);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    background: var(--darkBlue);
  }
`;

const BellButton = styled.button<BellButtonProps>`
  color: var(--GrassDark8);
  border-radius: 6px;
  border: 1px solid ${(props) => props.borderColor};
  transition: var(--transition);
  padding: 8px;
  /* width: 36px;
  height: 36px; */
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.backgroundColor};

  & svg path {
    fill: ${(props) =>
      props.borderColor === "#55B467" ? props.borderColor : "#6F6F6F"};
    transition: var(--transition);
  }

  &:hover {
    border-color: var(--GrassDark8);
  }
  &:hover svg path {
    fill: var(--GrassDark8);
    transition: var(--transition);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    border-color: var(--GrassDark7);
  }

  &:active svg path {
    fill: var(--GrassDark7);
    transition: var(--transition);
  }
`;

const TraiderInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const TraiderIMG = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 32px;

  background: var(--lightBlue2);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--blue);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const TraiderNameContainer = styled.div`
  max-width: 108px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TraiderName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: black;
  line-height: 18px;
`;

const TraiderSubName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: var(--GrayLight10);
  line-height: 16px;
`;

const DownArrow = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const DropdownContainer = styled.div`
  position: relative;
`;

const AccountMenu = styled.div`
  position: absolute;
  top: calc(100% + 17px);
  right: 0;
  z-index: 100;
  width: 225px;
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);
`;

const Item = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: var(--transition);

  &:hover {
    color: var(--blue);
    background: var(--lightBlue3);
  }
`;
