import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { usePersistentStore } from "../../store/RootStore";
import styled from "styled-components";
import Notification from "./Notification";
import listenForOutsideClicks from "../../utils/ListenForOutsideClicks";
import axios from "axios";
import { BaseURL } from "../../utils/BaseUrl";

interface CurrentTabProps {
  color: string;
  borderColor: string;
}

export interface BellButtonProps {
  backgroundColor: string;
  borderColor: string;
}

function NotificationsModal() {
  const { NotificationStore, UserStore } = usePersistentStore();
  const [open, setOpen] = useState(false);

  const [CurrentTab, setCurrentTab] = useState("all");

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  useEffect(() => {
    const getNotifications = () => {
      const config = {
        headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
      };

      axios
        .get(`${BaseURL}/v1/notifications/get_notifications/`, config)
        .then((res) => {
          //   console.log("🚀 ~ file: Notifications.tsx:39 ~ .then ~ res", res);
          NotificationStore.setNotifications(res.data.data);
        })
        .catch((err) => {
          console.log(
            "🚀 ~ file: Notifications.tsx:42 ~ getNotifications ~ err",
            err
          );
          if (err.response.data.code === "token_not_valid") {
            UserStore.updateToken();
          }
        });
    };

    getNotifications();
    setInterval(() => getNotifications(), 1000 * 5 * 60);
  }, []);

  return (
    <Container ref={menuRef}>
      <BellButton
        onClick={() => setOpen(!open)}
        backgroundColor={open ? "#F2F5FF" : "transparent"}
        borderColor={open ? "#99AFFF" : "#DBDBDB"}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.9365 10.9367V8.29796C14.9353 7.12224 14.5181 5.98488 13.7587 5.08729C12.9993 4.18971 11.9468 3.58982 10.7875 3.39387V2.48939C10.7875 2.26931 10.7001 2.05825 10.5445 1.90263C10.3888 1.74702 10.1778 1.65959 9.9577 1.65959C9.73763 1.65959 9.52657 1.74702 9.37095 1.90263C9.21533 2.05825 9.12791 2.26931 9.12791 2.48939V3.39387C7.96863 3.58982 6.91611 4.18971 6.15673 5.08729C5.39735 5.98488 4.98012 7.12224 4.97893 8.29796V10.9367C4.4947 11.1079 4.07528 11.4247 3.77816 11.8436C3.48105 12.2626 3.32078 12.7631 3.31934 13.2767V14.9363C3.31934 15.1564 3.40676 15.3675 3.56238 15.5231C3.71799 15.6787 3.92906 15.7661 4.14913 15.7661H6.75469C6.94578 16.4693 7.36293 17.09 7.94179 17.5325C8.52066 17.9751 9.22906 18.2148 9.9577 18.2148C10.6863 18.2148 11.3947 17.9751 11.9736 17.5325C12.5525 17.09 12.9696 16.4693 13.1607 15.7661H15.7663C15.9863 15.7661 16.1974 15.6787 16.353 15.5231C16.5086 15.3675 16.5961 15.1564 16.5961 14.9363V13.2767C16.5946 12.7631 16.4344 12.2626 16.1372 11.8436C15.8401 11.4247 15.4207 11.1079 14.9365 10.9367ZM6.63852 8.29796C6.63852 7.41766 6.98822 6.57341 7.61069 5.95094C8.23315 5.32847 9.0774 4.97878 9.9577 4.97878C10.838 4.97878 11.6823 5.32847 12.3047 5.95094C12.9272 6.57341 13.2769 7.41766 13.2769 8.29796V10.7873H6.63852V8.29796ZM9.9577 16.5959C9.66808 16.5942 9.38396 16.5167 9.13358 16.3711C8.88319 16.2255 8.67526 16.0169 8.53045 15.7661H11.385C11.2401 16.0169 11.0322 16.2255 10.7818 16.3711C10.5314 16.5167 10.2473 16.5942 9.9577 16.5959ZM14.9365 14.1065H4.97893V13.2767C4.97893 13.0567 5.06635 12.8456 5.22197 12.69C5.37759 12.5344 5.58865 12.4469 5.80872 12.4469H14.1067C14.3268 12.4469 14.5378 12.5344 14.6934 12.69C14.8491 12.8456 14.9365 13.0567 14.9365 13.2767V14.1065Z"
            fill="#6F6F6F"
          />
        </svg>
      </BellButton>
      {open && (
        <Menu>
          <Title>Уведомления</Title>
          <NavbarContainer>
            <NavTabs
              color={CurrentTab === "all" ? "#171717" : "#6B716A"}
              borderColor={CurrentTab === "all" ? "#365EDC" : "transparent"}
              onClick={() => setCurrentTab("all")}
            >
              Все
            </NavTabs>
            <NavTabs
              color={CurrentTab === "NEW_CONTRACT" ? "#171717" : "#6B716A"}
              borderColor={
                CurrentTab === "NEW_CONTRACT" ? "#365EDC" : "transparent"
              }
              onClick={() => setCurrentTab("NEW_CONTRACT")}
            >
              Заявки
            </NavTabs>
            <NavTabs
              color={CurrentTab === "CHANGE_STATUS" ? "#171717" : "#6B716A"}
              borderColor={
                CurrentTab === "CHANGE_STATUS" ? "#365EDC" : "transparent"
              }
              onClick={() => setCurrentTab("CHANGE_STATUS")}
            >
              Статусы
            </NavTabs>
          </NavbarContainer>
          {NotificationStore.getNotifications()
            .slice()
            .reverse()
            .filter((e: any) => CurrentTab === "all" || CurrentTab === e.action)
            .map((e: any) => {
              return (
                <Notification
                  name={e.from_user}
                  type={e.action}
                  info={e.action}
                  from={e.status_before}
                  to={e.status_now}
                  id={e.id}
                  number={e.contract_number}
                  requestId={e.contract_id}
                  setOpen={setOpen}
                />
              );
            })}
          {/* <Button>Все уведомления</Button> */}
        </Menu>
      )}
    </Container>
  );
}

export default observer(NotificationsModal);

const Container = styled.div`
  position: relative;
`;

const Menu = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  align-self: center;
  width: 390px;
  max-height: 600px;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background: #ffffff;
  box-shadow: 0px 12px 32px -5px rgba(0, 0, 0, 0.2);
  border-radius: 12px;

  z-index: 100;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  padding: 12px 20px;
`;

const NavbarContainer = styled.div`
  width: 100%;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-bottom: 1px solid var(--GrayLight5);
`;

const NavTabs = styled.div<CurrentTabProps>`
  width: fit-content;
  padding: 12px 7px 8px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.color};
  border-bottom: 4px solid ${(props) => props.borderColor};
  cursor: pointer;
`;

const Button = styled.button`
  width: 155px;
  height: 36px;
  margin: 16px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  border-radius: 8px;
  transition: var(--transition);
  border: 1px solid var(--GrayLight8);

  align-self: center;

  &:hover {
    color: var(--GrassDark8);
    border-color: var(--GrassDark8);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    color: var(--GrassDark7);
    border-color: var(--GrassDark7);
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: var(--GrayLight2);
`;

const BellButton = styled.button<BellButtonProps>`
  color: var(--GrassDark8);
  border-radius: 6px;
  border: 1px solid ${(props) => props.borderColor};
  transition: var(--transition);
  padding: 8px;
  /* width: 36px;
  height: 36px; */
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${(props) => props.backgroundColor};

  & svg path {
    fill: ${(props) =>
      props.borderColor === "#55B467" ? props.borderColor : "#6F6F6F"};
    transition: var(--transition);
  }

  &:hover {
    border-color: var(--lightBlue);
  }
  &:hover svg path {
    fill: var(--darkBlue);
    transition: var(--transition);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    border-color: var(--lightBlue2);
  }

  &:active svg path {
    fill: var(--darkBlue);
    transition: var(--transition);
  }
`;
