import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ModalWrapper } from "../../../styles/ModalWrapper";
import styled from "styled-components";
import {
  HeaderInModals,
  TitleContainerInDetailedRequestModals,
  TitleInDetailedRequestModals,
} from "../../../styles/DetailedRequestModals/TitleInDetailedRequestModal";
// import { CloseBtn } from "../../styles/DetailedRequestModals/CloseButton";
import { ButtonPrimary } from "../../../styles/Buttons/ButtonPrimary";
import CloseButton from "../../Buttons/CloseButton";
import Comments from "./Comments";
import { usePersistentStore } from "../../../store/RootStore";
import axios from "axios";
import { BaseURL } from "../../../utils/BaseUrl";

interface SampleSelectionModalProps {
  closeFunction: Function;
}

const SampleSelectionModal = ({ closeFunction }: SampleSelectionModalProps) => {
  const { UserStore, DetailedRequestStore } = usePersistentStore();
  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };
    const body = {
      unit_id: DetailedRequestStore.getSelected().id,
      comment: comment,
    };

    axios
      .post(`${BaseURL}/v1/contracts/take_protocols_request/`, body, config)
      .then((res) => {
        // console.log(
        //   "🚀 ~ file: SampleSelectionModal.tsx:36 ~ .then ~ res",
        //   res
        // );
        DetailedRequestStore.setProtocolStatus(
          DetailedRequestStore.getSelected().id,
          false
        );
        closeFunction();
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: SampleSelectionModal.tsx:42 ~ .then ~ err",
          err
        );
      });
  };

  return (
    <ModalWrapper onClick={() => closeFunction()}>
      <Container onClick={(e) => e.stopPropagation()}>
        <HeaderInModals>
          <TitleContainerInDetailedRequestModals>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.4783 6.22822C15.4783 6.22822 15.4783 6.22822 15.4783 6.16822L15.4333 6.05572C15.4177 6.03185 15.4001 6.00928 15.3808 5.98822C15.3608 5.95647 15.3382 5.92637 15.3133 5.89822L15.2458 5.84572L15.1258 5.78572L9.50084 2.31322C9.38164 2.23872 9.24391 2.19922 9.10334 2.19922C8.96278 2.19922 8.82504 2.23872 8.70584 2.31322L3.12584 5.78572L3.05834 5.84572L2.99084 5.89822C2.96598 5.92637 2.94341 5.95647 2.92334 5.98822C2.90406 6.00928 2.8865 6.03185 2.87084 6.05572L2.82584 6.16822C2.82584 6.16822 2.82584 6.16822 2.82584 6.22822C2.81847 6.29301 2.81847 6.35843 2.82584 6.42322V12.9782C2.82559 13.1057 2.85782 13.2311 2.91949 13.3426C2.98117 13.4542 3.07026 13.5482 3.17834 13.6157L8.80334 17.0882C8.83796 17.1096 8.87603 17.1248 8.91584 17.1332C8.91584 17.1332 8.95334 17.1332 8.97584 17.1332C9.10273 17.1735 9.23896 17.1735 9.36584 17.1332C9.36584 17.1332 9.40334 17.1332 9.42584 17.1332C9.46566 17.1248 9.50372 17.1096 9.53834 17.0882L15.1258 13.6157C15.2339 13.5482 15.323 13.4542 15.3847 13.3426C15.4464 13.2311 15.4786 13.1057 15.4783 12.9782V6.42322C15.4857 6.35843 15.4857 6.29301 15.4783 6.22822ZM8.37584 15.1082L4.25084 12.5582V7.77322L8.37584 10.3157V15.1082ZM9.12584 9.01822L4.92584 6.42322L9.12584 3.83572L13.3258 6.42322L9.12584 9.01822ZM14.0008 12.5582L9.87584 15.1082V10.3157L14.0008 7.77322V12.5582Z"
                fill="#55B467"
              />
            </svg>

            <TitleInDetailedRequestModals>
              Отбор проб
            </TitleInDetailedRequestModals>
          </TitleContainerInDetailedRequestModals>
          <CloseButton closeFunction={() => closeFunction()} />
        </HeaderInModals>
        <Comments title="Комментарий" setChange={setComment} />
        <Button onClick={() => handleSubmit()}>Подтвердить</Button>
      </Container>
    </ModalWrapper>
  );
};

export default observer(SampleSelectionModal);

const Container = styled.div`
  align-self: center;
  padding: 20px;
  width: 410px;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  box-shadow: 0px 12px 32px -5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const Button = styled(ButtonPrimary)`
  width: 125px;
`;
