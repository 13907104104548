import { observer } from "mobx-react-lite";
import React, { Dispatch, SetStateAction, useState } from "react";
import { ModalWrapper } from "../../../styles/ModalWrapper";
import styled from "styled-components";
import {
  HeaderInModals,
  TitleInDetailedRequestModals,
} from "../../../styles/DetailedRequestModals/TitleInDetailedRequestModal";
import CloseButton from "../../Buttons/CloseButton";
import Comments from "./Comments";
import { ButtonPrimary } from "../../../styles/Buttons/ButtonPrimary";
import { usePersistentStore } from "../../../store/RootStore";
import axios from "axios";
import { BaseURL } from "../../../utils/BaseUrl";
import { useNavigate } from "react-router-dom";

interface CancelnProcurementModalProps {
  closeFunction: Function;
  setInProcurement: Dispatch<SetStateAction<boolean>>;
}

const CancelnProcurementModal = ({
  closeFunction,
  setInProcurement,
}: CancelnProcurementModalProps) => {
  const { UserStore, DetailedRequestStore } = usePersistentStore();
  const [comment, setComment] = useState("");
  const [confirm, setConfirm] = useState(false);
  const navigate = useNavigate();

  const handleCancel = () => {
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };
    const body = {
      contract_id: DetailedRequestStore.getUUID(),
      comment: comment,
    };
    axios
      .post(`${BaseURL}/v1/contracts/contract_abort/`, body, config)
      .then((res) => {
        // console.log(
        //   "🚀 ~ file: CancelnProcurementModal.tsx:39 ~ .then ~ res",
        //   res
        // );
        setConfirm(true);
        setInProcurement(false);
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: CancelnProcurementModal.tsx:44 ~ .then ~ err",
          err
        );
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
          handleCancel();
        }
      });
  };

  return (
    <ModalWrapper
      onClick={() => (confirm ? navigate("/request") : closeFunction())}
    >
      <Container onClick={(e) => e.stopPropagation()}>
        <HeaderInModals>
          <TitleInDetailedRequestModals>
            Отмена закупки
          </TitleInDetailedRequestModals>
          <CloseButton
            closeFunction={() =>
              confirm ? navigate("/request") : closeFunction()
            }
          />
        </HeaderInModals>
        <Comments
          title="Укажите причину"
          setChange={setComment}
          isNecessary={true}
        />
        {confirm ? (
          <Button onClick={() => navigate("/request")} disabled={confirm}>
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.5166 6.49406C15.1847 6.16214 14.6868 6.16214 14.3549 6.49406L8.13146 12.7175L5.55909 10.1452C5.22717 9.81324 4.72929 9.81324 4.39738 10.1452C4.06546 10.4771 4.06546 10.975 4.39738 11.3069L7.5506 14.4601C7.71656 14.6261 7.88252 14.709 8.13146 14.709C8.3804 14.709 8.54636 14.6261 8.71231 14.4601L15.5166 7.65577C15.8486 7.32385 15.8486 6.82597 15.5166 6.49406Z"
                fill="white"
              />
            </svg>
            <span>Закупка отменена</span>
          </Button>
        ) : (
          <Button disabled={comment === ""} onClick={() => handleCancel()}>
            Подтвердить
          </Button>
        )}
      </Container>
    </ModalWrapper>
  );
};

export default observer(CancelnProcurementModal);

const Container = styled.div`
  align-self: center;
  padding: 20px;
  width: 410px;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  box-shadow: 0px 12px 32px -5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const Button = styled(ButtonPrimary)`
  width: fit-content;
  padding: 8px 16px;
`;
