import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

interface CloseButtonProps {
  closeFunction: Function;
}

const CloseButton = ({ closeFunction }: CloseButtonProps) => {
  return (
    <Container
      width="36"
      height="36"
      viewBox="0 0 36 36"
      //   fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => closeFunction()}
    >
      <path
        d="M19.4099 18.625L25.7099 12.335C25.8982 12.1467 26.004 11.8913 26.004 11.625C26.004 11.3587 25.8982 11.1033 25.7099 10.915C25.5216 10.7267 25.2662 10.6209 24.9999 10.6209C24.7336 10.6209 24.4782 10.7267 24.2899 10.915L17.9999 17.215L11.7099 10.915C11.5216 10.7267 11.2662 10.6209 10.9999 10.6209C10.7336 10.6209 10.4782 10.7267 10.2899 10.915C10.1016 11.1033 9.99585 11.3587 9.99585 11.625C9.99585 11.8913 10.1016 12.1467 10.2899 12.335L16.5899 18.625L10.2899 24.915C10.1962 25.008 10.1218 25.1186 10.071 25.2404C10.0203 25.3623 9.99414 25.493 9.99414 25.625C9.99414 25.757 10.0203 25.8877 10.071 26.0096C10.1218 26.1314 10.1962 26.242 10.2899 26.335C10.3829 26.4287 10.4935 26.5031 10.6154 26.5539C10.7372 26.6047 10.8679 26.6308 10.9999 26.6308C11.132 26.6308 11.2627 26.6047 11.3845 26.5539C11.5064 26.5031 11.617 26.4287 11.7099 26.335L17.9999 20.035L24.2899 26.335C24.3829 26.4287 24.4935 26.5031 24.6154 26.5539C24.7372 26.6047 24.8679 26.6308 24.9999 26.6308C25.132 26.6308 25.2627 26.6047 25.3845 26.5539C25.5064 26.5031 25.617 26.4287 25.7099 26.335C25.8037 26.242 25.8781 26.1314 25.9288 26.0096C25.9796 25.8877 26.0057 25.757 26.0057 25.625C26.0057 25.493 25.9796 25.3623 25.9288 25.2404C25.8781 25.1186 25.8037 25.008 25.7099 24.915L19.4099 18.625Z"
        fill="#858585"
      />
    </Container>
  );
};

export default observer(CloseButton);

const Container = styled.svg`
  cursor: pointer;
  transition: var(--transition);
  border-radius: 6px;

  & path {
    transition: var(--transition);
  }

  &:hover {
    background: #f2f5ff;
  }

  &:hover path {
    fill: #365edc;
  }

  &:active path {
    fill: var(--darkBlue);
  }
`;
