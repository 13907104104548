import { TableCell, TableHead, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";
import TableDropdown from "../../RequestTable/TableDropdowns/TableDropdown";
import { usePersistentStore } from "../../../store/RootStore";
import PriceDropdown from "../../RequestTable/TableDropdowns/PriceDropdown";
import AmountDropdownInDetailedRequestTable from "./AmountDropdownInDetailedRequestTable";

const DetailedRequestTableHead = () => {
  const {
    DetailedRequestStore,
    FiltersDetailedRequestStore,
    SimpleDictionaryStore,
  } = usePersistentStore();
  const [productNameSearch, setProductNameSearch] = useState("");
  const [yearSearch, setYearSearch] = useState("");

  return (
    <StyledTableHead>
      <TableRow>
        <StyledTableCell />
        <TableDropdown
          label="Наименование"
          items={SimpleDictionaryStore.getAgroCultureTypeList()}
          placeholder="Введите продукт"
          changeValueFunc={setProductNameSearch}
          searchingValue={productNameSearch}
          changeStatusFunction={
            FiltersDetailedRequestStore.changeAgrocultureTypeFilter
          }
          checkStatusFunc={
            FiltersDetailedRequestStore.getAgrocultureTypeFilterStatus
          }
          resetFunction={FiltersDetailedRequestStore.resetAgrocultureTypeFilter}
        />
        <AmountDropdownInDetailedRequestTable />
        <TableDropdown
          label="Урожай"
          items={DetailedRequestStore.getCropYears().reverse()}
          placeholder="Введите год"
          changeValueFunc={setYearSearch}
          searchingValue={yearSearch}
          changeStatusFunction={
            FiltersDetailedRequestStore.changeHarvestYearFilter
          }
          checkStatusFunc={
            FiltersDetailedRequestStore.getHarvestYearFilterStatus
          }
          resetFunction={FiltersDetailedRequestStore.resetHarvestYearFilter}
        />
        <PriceDropdown
          label="Цена, руб/т"
          setFunction={FiltersDetailedRequestStore.setFilterByOfferedPrice}
          removeFunction={
            FiltersDetailedRequestStore.removeFilterByOfferedPrice
          }
          minValueProp={FiltersDetailedRequestStore.getOfferedMinPrice()}
          maxValueProp={FiltersDetailedRequestStore.getOfferedMaxPrice()}
        />
        <PriceDropdown
          label="Согл. цена, руб/т"
          setFunction={FiltersDetailedRequestStore.setFilterByAgreedPrice}
          removeFunction={FiltersDetailedRequestStore.removeFilterByAgreedPrice}
          minValueProp={FiltersDetailedRequestStore.getAgreedMinPrice()}
          maxValueProp={FiltersDetailedRequestStore.getAgreedMaxPrice()}
        />
        <PriceDropdown
          label="Цена с дост., руб/т"
          setFunction={FiltersDetailedRequestStore.setFilterByDeliveryPrice}
          removeFunction={
            FiltersDetailedRequestStore.removeFilterByDeliveryPrice
          }
          minValueProp={FiltersDetailedRequestStore.getDeliveryMinPrice()}
          maxValueProp={FiltersDetailedRequestStore.getDeliveryMaxPrice()}
        />
        <PriceDropdown
          label="Согл цена с дост., руб/т"
          setFunction={
            FiltersDetailedRequestStore.setFilterByAgreedDeliveryPrice
          }
          removeFunction={
            FiltersDetailedRequestStore.removeFilterByAgreedDeliveryPrice
          }
          minValueProp={FiltersDetailedRequestStore.getAgreedDeliveryMinPrice()}
          maxValueProp={FiltersDetailedRequestStore.getAgreedDeliveryMaxPrice()}
        />
        <StyledTableCell>Качество</StyledTableCell>
        <StyledTableCell />
        <StyledTableCell />
      </TableRow>
    </StyledTableHead>
  );
};

export default observer(DetailedRequestTableHead);

const StyledTableHead = styled(TableHead)`
  background: var(--GrayLight4);
  border-radius: 6px;
  border: unset !important;
`;

const StyledTableCell = styled(TableCell)`
  color: #858585 !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: unset !important;
`;
