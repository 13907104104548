import { TableCell } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TableCellProps } from "./TableDropdown";
import { ResetButton } from "../../../styles/Buttons/ResetButton";
import listenForOutsideClicks from "../../../utils/ListenForOutsideClicks";
import { usePersistentStore } from "../../../store/RootStore";

interface PriceDropdownProps {
  label: string;
  setFunction: Function;
  removeFunction: Function;
  minValueProp: string;
  maxValueProp: string;
}

const PriceDropdown = ({
  label,
  setFunction,
  removeFunction,
  minValueProp,
  maxValueProp,
}: PriceDropdownProps) => {
  const { FiltersStore } = usePersistentStore();
  const [open, setOpen] = useState(false);
  const [minValue, setMinValue] = useState(minValueProp);
  const [maxValue, setMaxValue] = useState(maxValueProp);
  const [reset, setReset] = useState(false);

  const handleChangeMinValue = (e: React.FormEvent<HTMLInputElement>) => {
    setMinValue(e.currentTarget.value);
  };

  const handleChangeMaxValue = (e: React.FormEvent<HTMLInputElement>) => {
    setMaxValue(e.currentTarget.value);
  };

  const handleReset = () => {
    removeFunction();
    setReset(!reset);
  };

  useEffect(() => {
    setMinValue(minValueProp);
    setMaxValue(maxValueProp);
  }, [reset]);

  useEffect(() => {
    if (minValue !== "" || maxValue !== "") {
      setFunction(minValue, maxValue);
    }
  }, [minValue, maxValue]);

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  return (
    <Container ref={menuRef}>
      <Button
        onClick={() => setOpen(!open)}
        color={open ? "#365EDC" : "#858585"}
      >
        <div>{label}</div>
        <svg
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
            fill="#858585"
          />
        </svg>
      </Button>
      {open && (
        <Menu>
          <PriceItemContainer>
            <PriceItem>
              <PriceLabel>от</PriceLabel>
              <PriceInput
                min="0"
                type="number"
                placeholder="1000"
                value={minValue}
                onKeyDown={(evt) =>
                  (evt.key === "-" || evt.key === "+" || evt.key === "e") &&
                  evt.preventDefault()
                }
                onChange={(e) =>
                  Number(e.target.value) > -1
                    ? handleChangeMinValue(e)
                    : () => {}
                }
              />
            </PriceItem>
            <PriceItem>
              <PriceLabel>до</PriceLabel>
              <PriceInput
                min="0"
                type="number"
                placeholder="10000"
                value={maxValue}
                onKeyDown={(evt) =>
                  (evt.key === "-" || evt.key === "+" || evt.key === "e") &&
                  evt.preventDefault()
                }
                onChange={(e) =>
                  Number(e.target.value) > -1
                    ? handleChangeMaxValue(e)
                    : () => {}
                }
              />
            </PriceItem>
          </PriceItemContainer>
          <ResetButton onClick={() => handleReset()}>Сбросить</ResetButton>
        </Menu>
      )}
    </Container>
  );
};

export default observer(PriceDropdown);

const Container = styled(TableCell)`
  position: relative;
  border: unset !important;
`;

const Button = styled.button<TableCellProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  cursor: pointer;
  background-color: transparent;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  transition: var(--transition);

  & svg path {
    fill: ${(props) => props.color};
  }

  &:hover {
  }

  & .active {
  }
`;

const PriceItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: 0px 12px;
  margin: 8px 0px;
`;

const PriceItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
`;

const PriceLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
`;

const PriceInput = styled.input`
  width: 127px;
  height: 32px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  padding: 0px 4px;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--GrayLight8);
  }

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Menu = styled.div`
  position: absolute;
  top: calc(100%);
  right: 0;
  left: 0;
  z-index: 1;
  width: 285px;
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);
`;
