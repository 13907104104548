import styled from "styled-components";

export const ModalWrapper = styled.div`
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: calc(100vh + 60px);
  width: 100%;
  background: rgba(17, 28, 24, 0.25);
  display: flex;
  justify-content: center;
  /* overflow: scroll; */

  @media screen and (max-width: 1500px) {
    height: calc(100vh / 0.8);
  }
`;
