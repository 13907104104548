import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { ModalWrapper } from "../../../styles/ModalWrapper";
import styled from "styled-components";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { usePersistentStore } from "../../../store/RootStore";
import DetailedRequestDropdown from "../DetailedRequestDropdown/DetailedRequestDropdown";
import * as ru from "date-fns/locale/ru";
import { CargoClassbuttonInterface } from "../../NewRequest/NewRequestOffer";
import { ButtonTertriary } from "../../../styles/Buttons/ButtonTertriary";
import { GreyLabelInput } from "../../../styles/DetailedRequestModals/GreyLabelInput";
import axios from "axios";
import { BaseURL } from "../../../utils/BaseUrl";
import { ColoredInput } from "../../../styles/DetailedRequestModals/ColoredInput";
registerLocale("ru", ru);

interface RequestEditModalProps {
  closeFunction: Function;
}

const RequestEditModal = ({ closeFunction }: RequestEditModalProps) => {
  const { DetailedRequestStore, SimpleDictionaryStore, UserStore } =
    usePersistentStore();
  const [startDate, setStartDate] = useState(
    new Date(DetailedRequestStore.getContractStartDate())
  );
  const [endDate, setEndDate] = useState(
    new Date(DetailedRequestStore.getContractEndDate() as string)
  );
  const [selectedSupplier, setSelectedSupplier] = useState(
    DetailedRequestStore.getCounterpartyId()
  );
  const [searchingSupplier, setSearchingSupplier] = useState("");
  const [selectedGoods, setSelectedGoods] = useState(
    DetailedRequestStore.getAgroCultureType()
  );
  const [searchingGoods, setSearchingGoods] = useState("");
  const [deliveryConditions, setDeliveryConditions] = useState(
    DetailedRequestStore.getDeliveryTern()
  );
  const [searchingDeliveryConditions, setSearchingDeliveryConditions] =
    useState("");
  const [deliveryPlace, setDeliveryPlace] = useState(
    DetailedRequestStore.getDeparturePoint()
  );
  const [searchingDeliveryPlace, setSearchingDeliveryPlace] = useState("");
  const [cargoClass, setCargoClass] = useState(
    DetailedRequestStore.getCargoClass()
  );
  const [deliveryPoints, setDeliveryPoints] = useState([] as Array<string>);

  const handleChangeStartDate = (dates: any) => {
    // console.log(dates);
    setStartDate(dates);
  };

  const handleChangeEndDate = (dates: any) => {
    setEndDate(dates);
  };

  const handleSubmit = () => {
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };

    const body = {
      contract_id: DetailedRequestStore.getUUID(),
      new_coteragent: SimpleDictionaryStore.getCompanyId(
        selectedSupplier as string
      ),
      start_date: `${startDate.toLocaleDateString().split(".")[2]}-${
        startDate.toLocaleDateString().split(".")[1]
      }-${startDate.toLocaleDateString().split(".")[0]}`,
      end_date: `${endDate.toLocaleDateString().split(".")[2]}-${
        endDate.toLocaleDateString().split(".")[1]
      }-${endDate.toLocaleDateString().split(".")[0]}`,
      delivery_tern: deliveryConditions,
      departure_point: deliveryPlace,
    };
    // console.log(
    //   "🚀 ~ file: RequestEditModal.tsx:59 ~ handleSubmit ~ body",
    //   body
    // );

    axios
      .post(`${BaseURL}/v1/contracts/update_contract/`, body, config)
      .then((res) => {
        // console.log("🚀 ~ file: RequestEditModal.tsx:72 ~ .then ~ res", res);
        if (res.data.result) {
          closeFunction();
          DetailedRequestStore.setDeliveryTerm(deliveryConditions);
          DetailedRequestStore.setCounterParty(selectedSupplier);
          DetailedRequestStore.changeStatus("UPDATED");
        }
      })
      .catch((err) => {
        console.log("🚀 ~ file: RequestEditModal.tsx:76 ~ .then ~ err", err);
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
          handleSubmit();
        }
      });
  };
  return (
    <ModalWrapper onClick={() => closeFunction()}>
      <Container onClick={(e) => e.stopPropagation()}>
        <Header>
          <TitleContainer>
            <Title>Редактирование заявки</Title>
          </TitleContainer>
          <CloseBtn
            src="/assets/CloseBtn.svg"
            onClick={() => closeFunction()}
          />
        </Header>
        <BlockContainer>
          <BlockTitle>Заявка</BlockTitle>
          <DoubleInputInRow>
            <InputContainer>
              <DetailedRequestDropdown
                title="Контрагент"
                items={SimpleDictionaryStore.getCounterPartyList()}
                placeholder="Введите контрагента"
                changeValueFunc={setSearchingSupplier}
                changeSelectedValue={setSelectedSupplier}
                searchingValue={searchingSupplier}
                selectedValue={selectedSupplier}
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Номер</InputLabel>
              <NumberPlate>{DetailedRequestStore.getNumber()}</NumberPlate>
            </InputContainer>
          </DoubleInputInRow>
          <DoubleInputInRow>
            <InputContainer>
              <InputLabel>Дата начала контракта</InputLabel>
              <ReactDatePicker
                maxDate={endDate}
                selected={startDate}
                onChange={handleChangeStartDate}
                dateFormat="dd.MM.yyyy"
                placeholderText="Выберите дату"
                className="date-picker-request"
                locale="ru"
                // inline
              />
            </InputContainer>
            <InputContainer>
              <InputLabel>Дата окончания контракта</InputLabel>
              <ReactDatePicker
                minDate={startDate}
                selected={endDate}
                onChange={handleChangeEndDate}
                dateFormat="dd.MM.yyyy"
                placeholderText="Выберите дату"
                className="date-picker-request"
                locale="ru"
                // inline
              />
            </InputContainer>
          </DoubleInputInRow>
          <InputContainer>
            <GreyLabelInput>Карго-класс</GreyLabelInput>
            <CargoCalssLine>
              <OilButton active={cargoClass === "Масличные"}>
                Масличные
              </OilButton>
              <GrainButton active={cargoClass === "Зерновые"}>
                Зерновые
              </GrainButton>
            </CargoCalssLine>
          </InputContainer>
        </BlockContainer>
        <BlockContainer>
          <BlockTitle>Товар</BlockTitle>
          <DoubleInputInRow>
            <InputContainer>
              {/* <DetailedRequestDropdown
                title="Товары в заявке"
                items={SimpleDictionaryStore.getAgroCultureTypeList()}
                placeholder="Введите товар"
                changeValueFunc={setSearchingGoods}
                changeSelectedValue={setSelectedGoods}
                searchingValue={searchingGoods}
                selectedValue={selectedGoods}
              /> */}
              <GreyLabelInput>Товары в заявке</GreyLabelInput>
              <ColoredInput>{selectedGoods}</ColoredInput>
            </InputContainer>
            <InputContainer>
              <DetailedRequestDropdown
                title="Условия поставки"
                items={SimpleDictionaryStore.getDeliveryTermList()}
                placeholder="Введите условие"
                changeValueFunc={setSearchingDeliveryConditions}
                changeSelectedValue={setDeliveryConditions}
                searchingValue={searchingDeliveryConditions}
                selectedValue={deliveryConditions}
              />
            </InputContainer>
          </DoubleInputInRow>
          <InputRow>
            {/* <DetailedRequestDropdown
              title="Пункт поставки"
              items={deliveryPoints}
              placeholder="Введите пункт"
              changeValueFunc={setSearchingDeliveryPlace}
              changeSelectedValue={setDeliveryPlace}
              searchingValue={searchingDeliveryPlace}
              selectedValue={deliveryPlace}
            /> */}
            <GreyLabelInput>Пункт поставки</GreyLabelInput>
            <ColoredInput>{deliveryPlace}</ColoredInput>
          </InputRow>
        </BlockContainer>
        <ButtonLine>
          <Button onClick={() => handleSubmit()}>Сохранить</Button>
        </ButtonLine>
      </Container>
    </ModalWrapper>
  );
};

export default observer(RequestEditModal);

const Container = styled.div`
  width: 580px;
  /* height: 645px; */

  background: white;
  border-radius: 16px;

  align-self: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--GrayLight4);
  padding: 16px 20px;
  /* position: sticky;
  top: 0; */

  background: white;
  z-index: 101;
  border-radius: 16px 16px 0px 0px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--GrayLight12);
  margin-left: 10px;
`;

const CloseBtn = styled.img`
  width: 36px;
  height: 36px;
  cursor: pointer;
`;

const BlockContainer = styled.div`
  width: calc(100% - 40px);
  padding: 20px;

  border-radius: 8px;
  background: var(--GrayLight2);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const BlockTitle = styled.div`
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--GrayLight12);
`;

const DoubleInputInRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const InputRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const InputLabel = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight10);
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 230px;
  height: 60px;
`;

const NumberPlate = styled.div`
  width: 100%;
  height: 36px;
  padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight10);

  border: 1px solid var(--GrayLight3);
  border-radius: 4px;
  background: var(--GrayLight1);
`;

const ButtonLine = styled.div`
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

const Button = styled.button`
  height: 36px;
  padding: 8px 16px;

  background: var(--blue);
  border-radius: 6px;
  color: white;
  transition: var(--transition);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    background: var(--lightBlue);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    background: var(--darkBlue);
  }
`;

const CargoCalssLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const OilButton = styled(ButtonTertriary)<CargoClassbuttonInterface>`
  width: 144px;
  border: 1px solid #f3f3f3;
  border-radius: 4px 0px 0px 4px;
  color: ${(props) => (props.active ? "#2F52BF" : "#171717")};

  background: ${(props) => (props.active ? "#F2F5FF" : "white")};
`;

const GrainButton = styled(ButtonTertriary)<CargoClassbuttonInterface>`
  width: 144px;
  border: 1px solid #f3f3f3;
  border-radius: 0px 4px 4px 0px;
  color: ${(props) => (props.active ? "#2F52BF" : "#171717")};

  background: ${(props) => (props.active ? "#F2F5FF" : "white")};
`;
