import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Wrapper } from "../styles/Wrapper";
import { LoginContainer } from "../styles/Login/LoginContainer";
import LoginLogo from "../components/LoginLogo";
import { FormContainer } from "../styles/Login/FormContainer";
import { LoginTitle } from "../styles/Login/LoginTitle";
import styled from "styled-components";
import { InputProps } from "./Login/Login";
import { Tooltip } from "react-tooltip";

const Registration = () => {
  const [typeErr, setTypeErr] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [confirmPass, setConfirmPass] = useState("");
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [passErr, setPassErr] = useState(false);

  const inputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  };

  const invalidInput = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setTypeErr(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (pass !== confirmPass) {
      setPassErr(true);
    }
  };

  return (
    <Wrapper>
      <LoginContainer>
        <LoginLogo />
        <FormContainer onSubmit={handleSubmit}>
          <LoginTitle>Регистрация</LoginTitle>
          <InputContainer>
            <InputEmail
              placeholder="Введите ваш email"
              color={!typeErr ? "#DBDBDB" : "#EB5757"}
              type="email"
              value={email}
              onInvalid={(e) => invalidInput(e)}
              onInput={() => setTypeErr(false)}
              onChange={(e) => inputChange(e)}
              //   pattern="[a-z0-9_\.]+@\w+\.\w{2,5}"
              id="email"
            />
            <InputPassContainer
              color={!passErr ? "#DBDBDB" : "#EB5757"}
              id="pass"
            >
              <InputPass
                placeholder="Введите пароль"
                type={showPass ? "text" : "password"}
                value={pass}
                onChange={(e) => setPass(e.target.value)}
              ></InputPass>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowPass(!showPass)}
                style={{ marginRight: "12px", cursor: "pointer" }}
              >
                <mask
                  id="mask0_2120_201520"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="32"
                  height="32"
                >
                  <rect width="32" height="32" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2120_201520)">
                  <path
                    d="M16 22C17.6667 22 19.0836 21.4169 20.2507 20.2507C21.4169 19.0836 22 17.6667 22 16C22 14.3333 21.4169 12.9164 20.2507 11.7493C19.0836 10.5831 17.6667 10 16 10C14.3334 10 12.9165 10.5831 11.7494 11.7493C10.5832 12.9164 10 14.3333 10 16C10 17.6667 10.5832 19.0836 11.7494 20.2507C12.9165 21.4169 14.3334 22 16 22ZM16 19.6C15 19.6 14.1503 19.2498 13.4507 18.5493C12.7503 17.8498 12.4 17 12.4 16C12.4 15 12.7503 14.1498 13.4507 13.4493C14.1503 12.7498 15 12.4 16 12.4C17 12.4 17.8503 12.7498 18.5507 13.4493C19.2503 14.1498 19.6 15 19.6 16C19.6 17 19.2503 17.8498 18.5507 18.5493C17.8503 19.2498 17 19.6 16 19.6ZM16 26C12.7556 26 9.80004 25.0942 7.13337 23.2827C4.46671 21.472 2.53337 19.0444 1.33337 16C2.53337 12.9556 4.46671 10.5276 7.13337 8.716C9.80004 6.90533 12.7556 6 16 6C19.2445 6 22.2 6.90533 24.8667 8.716C27.5334 10.5276 29.4667 12.9556 30.6667 16C29.4667 19.0444 27.5334 21.472 24.8667 23.2827C22.2 25.0942 19.2445 26 16 26ZM16 23.3333C18.5112 23.3333 20.8169 22.672 22.9174 21.3493C25.0169 20.0276 26.6223 18.2444 27.7334 16C26.6223 13.7556 25.0169 11.972 22.9174 10.6493C20.8169 9.32756 18.5112 8.66667 16 8.66667C13.4889 8.66667 11.1832 9.32756 9.08271 10.6493C6.98315 11.972 5.37782 13.7556 4.26671 16C5.37782 18.2444 6.98315 20.0276 9.08271 21.3493C11.1832 22.672 13.4889 23.3333 16 23.3333Z"
                    fill="#333333"
                  />
                </g>
              </svg>
            </InputPassContainer>
            <InputPassContainer
              color={!passErr ? "#DBDBDB" : "#EB5757"}
              id="confirmPass"
            >
              <InputPass
                placeholder="Подтвердите пароль"
                type={showConfirmPass ? "text" : "password"}
                value={confirmPass}
                onChange={(e) => setConfirmPass(e.target.value)}
              ></InputPass>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => setShowConfirmPass(!showConfirmPass)}
                style={{ marginRight: "12px", cursor: "pointer" }}
              >
                <mask
                  id="mask0_2120_201520"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="32"
                  height="32"
                >
                  <rect width="32" height="32" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_2120_201520)">
                  <path
                    d="M16 22C17.6667 22 19.0836 21.4169 20.2507 20.2507C21.4169 19.0836 22 17.6667 22 16C22 14.3333 21.4169 12.9164 20.2507 11.7493C19.0836 10.5831 17.6667 10 16 10C14.3334 10 12.9165 10.5831 11.7494 11.7493C10.5832 12.9164 10 14.3333 10 16C10 17.6667 10.5832 19.0836 11.7494 20.2507C12.9165 21.4169 14.3334 22 16 22ZM16 19.6C15 19.6 14.1503 19.2498 13.4507 18.5493C12.7503 17.8498 12.4 17 12.4 16C12.4 15 12.7503 14.1498 13.4507 13.4493C14.1503 12.7498 15 12.4 16 12.4C17 12.4 17.8503 12.7498 18.5507 13.4493C19.2503 14.1498 19.6 15 19.6 16C19.6 17 19.2503 17.8498 18.5507 18.5493C17.8503 19.2498 17 19.6 16 19.6ZM16 26C12.7556 26 9.80004 25.0942 7.13337 23.2827C4.46671 21.472 2.53337 19.0444 1.33337 16C2.53337 12.9556 4.46671 10.5276 7.13337 8.716C9.80004 6.90533 12.7556 6 16 6C19.2445 6 22.2 6.90533 24.8667 8.716C27.5334 10.5276 29.4667 12.9556 30.6667 16C29.4667 19.0444 27.5334 21.472 24.8667 23.2827C22.2 25.0942 19.2445 26 16 26ZM16 23.3333C18.5112 23.3333 20.8169 22.672 22.9174 21.3493C25.0169 20.0276 26.6223 18.2444 27.7334 16C26.6223 13.7556 25.0169 11.972 22.9174 10.6493C20.8169 9.32756 18.5112 8.66667 16 8.66667C13.4889 8.66667 11.1832 9.32756 9.08271 10.6493C6.98315 11.972 5.37782 13.7556 4.26671 16C5.37782 18.2444 6.98315 20.0276 9.08271 21.3493C11.1832 22.672 13.4889 23.3333 16 23.3333Z"
                    fill="#333333"
                  />
                </g>
              </svg>
            </InputPassContainer>
          </InputContainer>
          <Button disabled={!!!email || !!!confirmPass || !!!pass}>
            Зарегестрироваться
          </Button>
        </FormContainer>
      </LoginContainer>
      {typeErr && (
        <Tooltip
          anchorId="email"
          place="top"
          content="Неверный формат электронной почты"
          className="tooltip-custom-class"
        />
      )}
      {passErr && (
        <Tooltip
          anchorId="pass"
          place="top"
          content="Пароли не совпадают"
          className="tooltip-custom-class"
        />
      )}
      {passErr && (
        <Tooltip
          anchorId="confirmPass"
          place="top"
          content="Пароли не совпадают"
          className="tooltip-custom-class"
        />
      )}
    </Wrapper>
  );
};

export default observer(Registration);

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
`;

const InputEmail = styled.input<InputProps>`
  font-family: "Inter";
  font-size: 18px;
  line-height: 25px;
  width: 100%;
  height: 56px;
  padding: 8px 12px;
  border: 1px solid var(--GrayLight7);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border-color: ${(props) => props.color};
  transition: var(--transition);

  &::placeholder {
    color: var(--GrayLight11);
  }

  &:hover {
    border-color: var(--blue);
  }

  &:focus {
    border-color: var(--GrayLight12);
  }
`;

const Button = styled.button`
  width: 100%;
  height: 56px;
  background: var(--blue);
  border-radius: 8px;
  color: var(--TextWhite);
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  transition: var(--transition);

  &:hover {
    background: var(--lightBlue);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    background: var(--darkBlue);
  }
`;

const InputPass = styled.input`
  /* flex: 1; */
  width: 100%;
  padding-left: 12px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 110%;

  border: unset;
  height: 50px;
  border-radius: 8px;

  &::placeholder {
    color: var(--GrayLight11);
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 110%;
  }
`;

const InputPassContainer = styled.div<InputProps>`
  width: 100%;
  height: 56px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;

  border: 1px solid var(--GrayLight7);
  border-color: ${(props) => props.color};
  /* Shadow/xs */

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
`;
