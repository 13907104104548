import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
// import Table from "react-paginate";
// import Tr from "react-paginate";
// import Td from "react-paginate";
import ReactPaginate from "react-paginate";
import styled from "styled-components";
// import { Table, Tr, Td, Pagination } from 'react-paginate';
import { Table, TableBody, TableContainer } from "@mui/material";
import RequestTableHead from "./RequestTableHead";
import RequestTableRow from "./RequestTableRow";
import NoResultsSearch from "../../NoResultsSearch";
import { usePersistentStore } from "../../../store/RootStore";
import { RequestInterface } from "../../../store/RequestStore";
import { autorun } from "mobx";

const RequestTable = () => {
  const { FiltersStore, RequestStore } = usePersistentStore();

  const [pageCount, setPageCount] = useState(
    Math.ceil(RequestStore.getRequests().length / 10)
  );
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(10);

  const handlePageChange = (page: any) => {
    // console.log(
    //   "🚀 ~ file: RequestTable.tsx:27 ~ handlePageChange ~ page",
    //   page
    // );
    const pageSize = 10;
    setStartIndex(page.selected * pageSize);
    setEndIndex(page.selected * pageSize + pageSize);
  };

  //   useEffect(() => {
  //     console.log(startIndex, endIndex);
  //   }, [startIndex, endIndex]);

  useEffect(
    () =>
      autorun(() => {
        setPageCount(
          Math.ceil(
            FiltersStore.FilteredData(RequestStore.getRequests()).length / 10
          )
        );
      }),
    []
  );

  //   useEffect(() => {
  //     setState({
  //       currentPageData: FiltersStore.FilteredData(
  //         RequestStore.getRequests().slice(0, 10)
  //       ),
  //     });
  //   }, [FiltersStore]);

  return (
    <StyledTableContainer>
      {!FiltersStore.getNoResults() ? (
        <Table>
          <RequestTableHead />
          <StyledTableBody>
            {FiltersStore.FilteredData(RequestStore.getRequests().slice().reverse())
              .slice(startIndex, endIndex)
              .map((row: any, index: number) => (
                <RequestTableRow row={row} key={index} />
              ))}
          </StyledTableBody>
        </Table>
      ) : (
        <NoResultsSearch />
      )}
      {pageCount > 0 && (
        <ReactPaginate
          pageCount={pageCount}
          onPageChange={handlePageChange}
          previousLabel={"Назад"}
          nextLabel={"Вперед"}
          containerClassName={"pagination-class"}
          previousClassName={"prev-class"}
          nextClassName={"next-class"}
          activeClassName={"active-class"}
          pageClassName={"page-class"}
          breakClassName={"page-class"}
          pageRangeDisplayed={1}
          marginPagesDisplayed={2}
        />
      )}
    </StyledTableContainer>
  );
};

export default observer(RequestTable);

const StyledTableContainer = styled(TableContainer)`
  margin: 8px 0px;
  border-radius: 8px;
  overflow: initial !important;
`;

const StyledTableBody = styled(TableBody)`
  background: white;
  border-radius: 8px;
`;
