import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { usePersistentStore } from "../store/RootStore";

interface NavbarProps {
  tabs: Array<string>;
  currentTab: string;
  changeTabFunc: Function;
}

interface NavTabProps {
  borderColor: string;
}

const RequestNavbar = ({ tabs, currentTab, changeTabFunc }: NavbarProps) => {
  const { RequestStore } = usePersistentStore();

  return (
    <NavbarContainer>
      {tabs.map((e: string) => {
        return (
          <NavTabs
            borderColor={currentTab === e ? "#2F52BF" : "transparent"}
            onClick={() => changeTabFunc(e)}
          >
            {e !== "Новые" ? (
              <div>{e}</div>
            ) : (
              <>
                <div>{e}</div>
                <NewTab>
                  <div>{RequestStore.getAmountOfNew()}</div>
                  <RedDot />
                </NewTab>
              </>
            )}
          </NavTabs>
        );
      })}
    </NavbarContainer>
  );
};

export default observer(RequestNavbar);

const NavbarContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

const NavTabs = styled.div<NavTabProps>`
  width: fit-content;
  padding: 19px 8px 14px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  border-bottom: 4px solid ${(props) => props.borderColor};
  cursor: pointer;
`;

const NewTab = styled.div`
  position: relative;

  /* width: 18px; */
  height: 18px;
  padding: 0px 2px;
  margin-left: 4px;

  border: 1px solid var(--GrayLight7);
  border-radius: 4px;
  background: white;

  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--GrayLight11);
`;

const RedDot = styled.div`
  width: 7px;
  height: 7px;
  /* border-radius: 100%; */

  position: absolute;
  top: -4px;
  right: -5px;
  background: var(--ErrorColor);

  clip-path: ellipse(50% 50%);
`;
