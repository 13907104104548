export const GetNotificationType = (notificationType: string) => {
  switch (notificationType) {
    case "NEW_CONTRACT":
      return "Новая заявка";
    case "PRICE_FOR_APPROVE":
      return "Пришла цена на одобрение";
    case "CHANGE_STATUS":
      return "Изменение статуса";
    case "UPDATE_PRODUCTS":
      return "Товары обновлены";
    case "UPDATE_PROTOCOLS":
      return "Протоколы качества обновлены";
    case "APPROVED_CONTRACT":
      return "Заявка одобрена";
    case "DELETE_PRODUCTS":
      return "Товар был удален в заявке";
    case "APPROVED_PRICE":
      return "Цена одобрена";
    case "DECREASE_PRICE":
      return "Понизить цену";
    case "INCREASE_PRICE":
      return "Повысить цену";
    case "CONTRACT_ABORTED":
      return "Контракт разорван";
    case "CONTRACT_FINISHED":
      return "Заявка выполнена";
    case "TAKE_PROTOCOLS":
      return "Отбор проб";
    case "CONTRACT_SEND_FOR_APPROVE":
      return "Отправлено на одобрение";
  }
  return notificationType;
};
