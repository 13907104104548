import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { ButtonActiveProps } from "../../../Dropdown/Dropdown";
import styled from "styled-components";
import listenForOutsideClicks from "../../../../utils/ListenForOutsideClicks";
import { DownArrow } from "../../../../styles/DownArrow";
import SearchBar from "../../../SearchBar";
import NoResultsDropdown from "../../../NoResultsDropdown";
import SampleQulityDropdownItem from "./SampleQulityDropdownItem";

interface SampleQulityDropdownProps {
  items: Array<string>;
  placeholder: string;
  changeValueFunc: Function;
  changeSelectedValue: Function;
  searchingValue: string;
  selectedValue: string | null;
  disableSearch?: boolean;
  idNumber: number;
}

const SampleQualityDropdown = ({
  items,
  placeholder,
  changeValueFunc,
  changeSelectedValue,
  searchingValue,
  selectedValue,
  disableSearch,
  idNumber,
}: SampleQulityDropdownProps) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  useEffect(() => {
    changeValueFunc("");
  }, [open]);

  return (
    <Container ref={menuRef}>
      <Button
        onClick={() => toggleOpen()}
        borderColor={open ? "#2F52BF" : "#F3F3F3"}
        backGroundColor={open ? "#FCFCFC" : "#FCFCFC"}
      >
        <div>{selectedValue}</div>
        <DownArrow src="/assets/Header/AccountMore.svg" />
      </Button>
      {open && (
        <Menu>
          {!disableSearch && (
            <SearchBar
              placeholder={placeholder}
              changeValueFunc={changeValueFunc}
            />
          )}
          {items.filter((e: string) =>
            e.toUpperCase().includes(searchingValue.trim().toUpperCase())
          ).length !== 0 ? (
            items
              .filter((e: string) =>
                e.toUpperCase().includes(searchingValue.trim().toUpperCase())
              )
              .map((e: string, i: number) => (
                <SampleQulityDropdownItem
                  name={e}
                  changeSelectedValue={changeSelectedValue}
                  idNumber={idNumber}
                  key={i}
                  closeFunction={() => setOpen(false)}
                />
              ))
          ) : (
            <NoResultsDropdown
              searchingItem={searchingValue}
            ></NoResultsDropdown>
          )}
        </Menu>
      )}
    </Container>
  );
};

export default observer(SampleQualityDropdown);

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight10);
`;

const Button = styled.button<ButtonActiveProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 36px;

  text-align: left;
  cursor: pointer;
  background-color: ${(props) => props.backGroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;
  padding: 7px 9px 7px 16px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  transition: var(--transition);

  &:hover {
    border-color: var(--lightBlue);
  }

  & .active {
    border-color: var(--darkBlue);
  }
`;

const Menu = styled.div`
  position: absolute;
  top: calc(100%);
  right: 0;
  left: 0px;
  z-index: 1;
  width: calc(100%);
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);

  max-height: 150px;
  overflow-y: scroll;
`;
