import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { ButtonActiveProps } from "../../Dropdown/Dropdown";
import { DownArrow } from "../../../styles/DownArrow";
import DropdownItem from "./DropdownItem";
import { ResetButton } from "../../../styles/Buttons/ResetButton";
import listenForOutsideClicks from "../../../utils/ListenForOutsideClicks";
import { FiltersStore } from "../../../store/FiltersStore";

interface Props {
  items: Array<string>;
  title: string;
  placeholder: string;
  //   changeValueFunc: Function;
  changeStatusFunction?: Function;
  checkStatusFunc?: Function;
  resetFunction?: Function;
  toggleAll?: Function;
  isAllReset: boolean;
}

const FiltersModalDropdown = ({
  items,
  title,
  placeholder,
  changeStatusFunction,
  checkStatusFunc,
  resetFunction,
  toggleAll,
  isAllReset,
}: //   changeValueFunc,
Props) => {
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleReset = () => {
    resetFunction && resetFunction();
    setSelection(false);
  };

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  //   useEffect(() => {
  //     console.log(open, listening);
  //   }, [open, listening]);

  const handleSelectAll = () => {
    if (!selection) {
      items.forEach((e: string) => {
        toggleAll && toggleAll(e, "set");
      });
    } else {
      items.forEach((e: string) => {
        toggleAll && toggleAll(e, "delete");
      });
    }
    setSelection(!selection);
  };

  useEffect(() => {
    setSelection(false);
  }, [isAllReset]);

  return (
    <Container ref={menuRef}>
      <Title>{title}</Title>
      <Button
        onClick={() => toggleOpen()}
        borderColor={open ? "#2F52BF" : "#DBDBDB"}
        backGroundColor={open ? "#F2F5FF" : "white"}
      >
        <div>{placeholder}</div>
        <DownArrow src="/assets/Header/AccountMore.svg" />
      </Button>
      {open && (
        <Menu>
          <DropdownItem
            name={placeholder}
            // onClick={() => handleSelection()}
            allSelectionFunc={handleSelectAll}
            checkStatusFunc={() => {
              return selection;
            }}
          ></DropdownItem>
          {items.map((e: string) => {
            return (
              <DropdownItem
                name={e}
                allSelectionFunc={null}
                changeStatusFunction={changeStatusFunction}
                checkStatusFunc={checkStatusFunc}
              />
            );
          })}
          <ResetButton onClick={() => handleReset()}>Сбросить</ResetButton>
        </Menu>
      )}
    </Container>
  );
};

export default observer(FiltersModalDropdown);

const Container = styled.div`
  position: relative;
  width: 100%;
  padding: 0px 20px;
  margin: 12px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
`;

const Button = styled.button<ButtonActiveProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 36px;

  text-align: left;
  cursor: pointer;
  background-color: ${(props) => props.backGroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;
  padding: 7px 9px 7px 16px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: var(--transition);

  &:hover {
    border-color: var(--darkBlue);
  }

  & .active {
    border-color: var(--darkBlue);
  }
`;

const Menu = styled.div`
  position: absolute;
  top: calc(100%);
  right: 0;
  left: 20px;
  z-index: 1;
  width: calc(100% - 40px);
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);

  max-height: 200px;
  overflow: scroll;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--GrayLight12);
`;
