import styled from "styled-components";

export const ButtonSecondary = styled.button`
  width: fit-content;
  height: 28px;

  border: 1px solid var(--GrayLight8);
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: var(--transition);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  &:hover {
    border-color: var(--blue);
    color: var(--blue);
  }

  &:active {
    border-color: var(--darkBlue);
    color: var(--darkBlue);
  }
`;
