import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styled from "styled-components";
import {
  SampleQualityArrayInterface,
  SampleQualityObjectInterface,
} from "../../../../store/DetailedRequestStore";
import { usePersistentStore } from "../../../../store/RootStore";
import { getSnapshot } from "mobx-state-tree";
import { MathchSampleQualityHeaders } from "../../../../utils/MatchSampleQualityHeaders";

interface SampleQualityTableRowProps {
  setOpenEdit: Dispatch<SetStateAction<boolean>>;
  sampleQuality: SampleQualityObjectInterface;
  id: number;
  departurePoint: string;
  date: string;
}

const SampleQualityTableRow = ({
  setOpenEdit,
  sampleQuality,
  id,
  departurePoint,
  date,
}: SampleQualityTableRowProps) => {
  const { DetailedRequestStore, SimpleDictionaryStore } = usePersistentStore();
  const [newSampleQuality, setNewSampleQuality] = useState(
    [] as Array<SampleQualityArrayInterface>
  );

  useEffect(() => {
    let newArray = [] as Array<SampleQualityArrayInterface>;
    for (const [key, value] of Object.entries(sampleQuality)) {
      if (value) {
        let newObject = {
          key: MathchSampleQualityHeaders(key),
          value: Number(value).toString(),
        };
        newArray.push(newObject);
      }
    }
    setNewSampleQuality(newArray);
    // console.log(
    //   "🚀 ~ file: SampleQualityTableRow.tsx:23 ~ newSampleQuality",
    //   newArray
    // );
  }, []);

  const handleOpenModal = () => {
    DetailedRequestStore.removeAllSelection();
    DetailedRequestStore.changeSelectionOnProduct(id);
    setOpenEdit(true);
  };

  return (
    <StyledTableRow>
      <FixedCell
        onClick={() =>
          DetailedRequestStore.getStatus() !== "FIXED" &&
          DetailedRequestStore.getStatus() !== "CONTRACT_ABORTED" &&
          DetailedRequestStore.getStatus() !== "FINISHED" &&
          DetailedRequestStore.getStatus() !== "DECLINED"
            ? handleOpenModal()
            : () => {}
        }
      >
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.71204 18.9363H12.2304C12.3396 18.937 12.4478 18.916 12.5489 18.8747C12.65 18.8334 12.742 18.7726 12.8195 18.6957L18.5617 12.9452L20.9183 10.6384C20.9961 10.5612 21.0578 10.4695 21.1 10.3683C21.1421 10.2672 21.1638 10.1588 21.1638 10.0492C21.1638 9.93967 21.1421 9.83121 21.1 9.7301C21.0578 9.62898 20.9961 9.5372 20.9183 9.46006L17.4 5.90024C17.3229 5.82246 17.2311 5.76073 17.13 5.7186C17.0289 5.67647 16.9204 5.65479 16.8109 5.65479C16.7013 5.65479 16.5928 5.67647 16.4917 5.7186C16.3906 5.76073 16.2988 5.82246 16.2217 5.90024L13.8817 8.24856L8.12289 13.999C8.04598 14.0766 7.98514 14.1685 7.94384 14.2696C7.90255 14.3707 7.88162 14.479 7.88225 14.5882V18.1065C7.88225 18.3266 7.96967 18.5377 8.12529 18.6933C8.28091 18.8489 8.49197 18.9363 8.71204 18.9363ZM16.8109 7.6594L19.1592 10.0077L17.9809 11.186L15.6325 8.83771L16.8109 7.6594ZM9.54184 14.9284L14.4625 10.0077L16.8109 12.356L11.8902 17.2767H9.54184V14.9284ZM21.9888 20.5959H7.05245C6.83238 20.5959 6.62131 20.6833 6.4657 20.839C6.31008 20.9946 6.22266 21.2056 6.22266 21.4257C6.22266 21.6458 6.31008 21.8569 6.4657 22.0125C6.62131 22.1681 6.83238 22.2555 7.05245 22.2555H21.9888C22.2089 22.2555 22.4199 22.1681 22.5755 22.0125C22.7311 21.8569 22.8186 21.6458 22.8186 21.4257C22.8186 21.2056 22.7311 20.9946 22.5755 20.839C22.4199 20.6833 22.2089 20.5959 21.9888 20.5959Z"
            fill="#2F52BF"
          />
        </svg>
      </FixedCell>
      <StyledCell>{DetailedRequestStore.getAgroCultureType()}</StyledCell>
      <TableCellTextRight>
        {new Date(date).toLocaleDateString()}
      </TableCellTextRight>
      <TableCellTextRight>{departurePoint}</TableCellTextRight>
      {/* <StyledCell>Грейнрус Агро ООО</StyledCell> */}
      <TableCellTextRight>
        {DetailedRequestStore.getAmountOfProduct(id)}
      </TableCellTextRight>
      {SimpleDictionaryStore.getGrainQualityParameterList().map((e, i) => {
        return (
          <TableCellTextRight key={i}>
            {newSampleQuality.find((quality: any) => quality.key === e)?.value}
          </TableCellTextRight>
        );
      })}
    </StyledTableRow>
  );
};

export default observer(SampleQualityTableRow);

const StyledTableRow = styled(TableRow)`
  border: unset !important;
  transition: var(--transition);
  background: "white";
  position: relative;

  /* &:hover {
    background: var(--GrassLight2);
  } */
`;

const StyledCell = styled(TableCell)`
  padding: 20px 12px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: unset !important;
`;

const TableCellTextRight = styled(StyledCell)`
  text-align: right !important;
`;

const FixedCell = styled(StyledCell)`
  position: sticky;
  left: 0;
  background: white;
  cursor: pointer;
`;
