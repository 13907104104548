import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";

interface NoResultsDropdownProps {
  searchingItem: string;
}

const NoResultsDropdown = ({ searchingItem }: NoResultsDropdownProps) => {
  return <Container>Нет результатов поиска "{searchingItem}"</Container>;
};

export default observer(NoResultsDropdown);

const Container = styled.div`
  padding: 8px 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
`;
