import { Table, TableBody, TableContainer } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ApprovalHistoryTableHead from "./ApprovalHistoryTableHead";
import { usePersistentStore } from "../../../store/RootStore";
import ApprovalHistoryTableRow from "./ApprovalHistoryTableRow";
import { DetailedRequestApprovalHistoryInterface } from "../../../store/DetailedRequestStore";
import axios, { AxiosResponse } from "axios";
import { BaseURL } from "../../../utils/BaseUrl";
import { Oval } from "react-loader-spinner";

const ApprovalHistoryTable = () => {
  const { FiltersApprovalHistoryStore, DetailedRequestStore, UserStore } =
    usePersistentStore();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const body = {
      contract_id: DetailedRequestStore.getUUID(),
    };
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };

    axios
      .post(`${BaseURL}/v1/contracts/get_change_history/`, body, config)
      .then((res: AxiosResponse<any>) => {
        // console.log(
        //   "🚀 ~ file: ApprovalHistoryTable.tsx:27 ~ .then ~ res",
        //   res.data
        // );
        if (res.data.result) {
          DetailedRequestStore.setApprovalHostory(res.data.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("🚀 ~ file: ApprovalHistoryTable.tsx:32 ~ .then ~ rr", err);
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
        }
      });
  }, []);
  return (
    <StyledTableContainer>
      {!loading ? (
        <Table>
          <ApprovalHistoryTableHead />
          <StyledTableBody>
            {FiltersApprovalHistoryStore.FilteredData(
              DetailedRequestStore.getApprovalHistory()
            ).slice().reverse().map((e: any, i: number) => {
              return <ApprovalHistoryTableRow row={e} key={i} />;
            })}
          </StyledTableBody>
        </Table>
      ) : (
        <OvalContainer>
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            strokeWidthSecondary={3}
            color="#365EDC"
            secondaryColor="rgba(139, 187, 75, 0)"
          />
        </OvalContainer>
      )}
    </StyledTableContainer>
  );
};

export default observer(ApprovalHistoryTable);

const StyledTableContainer = styled(TableContainer)`
  border-radius: 8px;
  overflow: initial !important;
`;

const StyledTableBody = styled(TableBody)`
  background: white;
  border-radius: 8px;
`;

const OvalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;
