import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Wrapper } from "../styles/Wrapper";
import Title from "../components/Title";
import styled from "styled-components";
import Dropdown from "../components/Dropdown/Dropdown";
import { usePersistentStore } from "../store/RootStore";
import FiltersModal from "../components/Filters/FiltersModal";
import { BellButtonProps } from "../components/Header";
import RequestNavbar from "../components/RequestNavbar";
import ExportAction from "../components/ExportAction/ExportAction";
import RequestTable from "../components/RequestTable/Table/RequestTable";
import axios, { AxiosError, AxiosResponse } from "axios";
import { BaseURL } from "../utils/BaseUrl";
import {
  CompanyInterface,
  ProfileDataInterface,
  RoleInterface,
} from "../store/UserStore";
import { Oval } from "react-loader-spinner";
import { ResetButton } from "../styles/Buttons/ResetButton";

interface SearchInputProps {
  focused: boolean;
}

interface UserDataInterface {
  data: {
    company: CompanyInterface;
    role: RoleInterface;
  };
  profile_data: ProfileDataInterface;
}

function Request() {
  const { FiltersStore, RequestStore, UserStore, SimpleDictionaryStore } =
    usePersistentStore();
  const [search, setSearch] = useState("");
  const [traiderSearch, setTraiderSearch] = useState("");
  const [supplierSearch, setSupplierSearch] = useState("");
  const [productSearch, setProductSearch] = useState("");
  const [currentTab, setCurrentTab] = useState("Все заявки");
  const [focused, setFocused] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
    FiltersStore.setNoResults();
  };

  const handleOnBlur = () => {
    setFocused(false);
    FiltersStore.removeNoResults();
  };

  const NameFunc = (currentTab: string): string => {
    switch (currentTab) {
      case "Все заявки":
        return "";
        break;
      case "Новые":
        return "NEW";
        break;
      case "Согласование":
        return "SEND";
        break;
      case "Отклонённые":
        return "DECLINED";
        break;
      case "Отбор проб":
        return "InTest";
        break;
      case "Избранные":
        return "Favourite";
        break;
      case "Архив":
        return "Archived";
        break;
      case "В закупке":
        return "FIXED";
        break;

      case "Контракт разорван":
        return "CONTRACT_ABORTED";
    }
    return "";
  };

  useEffect(() => {
    FiltersStore.clearSearching();
    FiltersStore.removeNoResults();
  }, []);
  useEffect(() => {
    FiltersStore.setCurrentStatusTab(NameFunc(currentTab));
  }, [currentTab]);

  useEffect(() => {
    if (UserStore.getProfileData().first_name === "") {
      const config = {
        headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
      };
      axios
        .get(`${BaseURL}/get_user_data/`, config)
        .then((res: AxiosResponse<UserDataInterface>) => {
          //   console.log("🚀 ~ file: Request.tsx:76 ~ useEffect ~ res", res);
          UserStore.setRole(res.data.data.role);
          UserStore.setCompany(res.data.data.company);
          UserStore.setProfileData(res.data.profile_data);
        })
        .catch((err: any) => {
          console.log("🚀 ~ file: Request.tsx:78 ~ useEffect ~ err", err);
          if (err.response.data.code === "token_not_valid") {
            UserStore.updateToken();
          }
        });
    }
  }, []);

  useEffect(() => {
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };
    setLoading(true);
    axios
      .get(`${BaseURL}/v1/contracts/get_contracts/`, config)
      .then((res: AxiosResponse<any>) => {
        // console.log("🚀 ~ file: Request.tsx:108 ~ .then ~ res", res);
        RequestStore.setRequests(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("🚀 ~ file: Request.tsx:109 ~ axios.get ~ err", err);
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
        }
        // setLoading(false);
      });
  }, []);

  return (
    <Wrapper>
      <Title title="Реестр заявок" />
      <FilterSearchContainer>
        <FiltersContainer>
          <FiltersButton
            onClick={() => FiltersStore.toggleIsOpen()}
            borderColor={FiltersStore.getIsOpen() ? "#365EDC" : "#DBDBDB"}
            backgroundColor={FiltersStore.getIsOpen() ? "#F2F5FF" : "white"}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.25 1.5H3.75C3.15326 1.5 2.58097 1.73705 2.15901 2.15901C1.73705 2.58097 1.5 3.15326 1.5 3.75V4.6275C1.49989 4.93721 1.56372 5.2436 1.6875 5.5275V5.5725C1.79346 5.81323 1.94354 6.032 2.13 6.2175L6.75 10.8075V15.75C6.74974 15.8775 6.78198 16.0029 6.84365 16.1144C6.90533 16.226 6.99442 16.3199 7.1025 16.3875C7.22186 16.4615 7.35958 16.5005 7.5 16.5C7.61741 16.4993 7.73301 16.471 7.8375 16.4175L10.8375 14.9175C10.9612 14.8552 11.0652 14.7598 11.138 14.642C11.2108 14.5242 11.2496 14.3885 11.25 14.25V10.8075L15.84 6.2175C16.0265 6.032 16.1765 5.81323 16.2825 5.5725V5.5275C16.4166 5.24582 16.4907 4.93933 16.5 4.6275V3.75C16.5 3.15326 16.2629 2.58097 15.841 2.15901C15.419 1.73705 14.8467 1.5 14.25 1.5ZM9.9675 9.9675C9.89799 10.0376 9.843 10.1207 9.80567 10.2121C9.76835 10.3034 9.74943 10.4013 9.75 10.5V13.785L8.25 14.535V10.5C8.25057 10.4013 8.23165 10.3034 8.19433 10.2121C8.157 10.1207 8.10201 10.0376 8.0325 9.9675L4.0575 6H13.9425L9.9675 9.9675ZM15 4.5H3V3.75C3 3.55109 3.07902 3.36032 3.21967 3.21967C3.36032 3.07902 3.55109 3 3.75 3H14.25C14.4489 3 14.6397 3.07902 14.7803 3.21967C14.921 3.36032 15 3.55109 15 3.75V4.5Z"
                fill="#365EDC"
              />
            </svg>
            <div>Все фильтры</div>
          </FiltersButton>
          <Dropdown
            items={RequestStore.getTraiders()}
            title="Трейдер"
            placeholder="Введите имя"
            changeValueFunc={setTraiderSearch}
            searchingValue={traiderSearch}
            changeStatusFunction={FiltersStore.changeTradersFilter}
            checkStatusFunc={FiltersStore.getTraderFilterStatus}
          />
          <Dropdown
            items={RequestStore.getSuppliers()}
            title="Поставщик"
            placeholder="Введите название"
            changeValueFunc={setSupplierSearch}
            searchingValue={supplierSearch}
            changeStatusFunction={FiltersStore.changeSupplierFilter}
            checkStatusFunc={FiltersStore.getSupplierFilterStatus}
          />
          <Dropdown
            items={SimpleDictionaryStore.getAgroCultureTypeList()}
            title="Продукция"
            placeholder="Введите продукт"
            changeValueFunc={setProductSearch}
            searchingValue={productSearch}
            changeStatusFunction={FiltersStore.changeAgrocultureTypeFilter}
            checkStatusFunc={FiltersStore.getAgrocultureTypeFilterStatus}
          />
        </FiltersContainer>
        <SearchInputContainer
          focused={focused}
          onSubmit={(e) => e.preventDefault()}
        >
          <svg
            width="18"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.2825 15.2175L13.5001 12.4575C14.5801 11.1108 15.1032 9.40146 14.9617 7.68096C14.8201 5.96047 14.0248 4.35958 12.7392 3.20747C11.4536 2.05537 9.77541 1.43962 8.04974 1.48684C6.32408 1.53406 4.68209 2.24066 3.46141 3.46135C2.24072 4.68203 1.53412 6.32402 1.4869 8.04968C1.43968 9.77534 2.05543 11.4535 3.20753 12.7391C4.35964 14.0247 5.96053 14.8201 7.68103 14.9616C9.40152 15.1031 11.1109 14.5801 12.4576 13.5L15.2176 16.26C15.2873 16.3303 15.3702 16.3861 15.4616 16.4242C15.553 16.4622 15.651 16.4818 15.7501 16.4818C15.8491 16.4818 15.9471 16.4622 16.0385 16.4242C16.1299 16.3861 16.2128 16.3303 16.2825 16.26C16.4177 16.1201 16.4933 15.9332 16.4933 15.7387C16.4933 15.5442 16.4177 15.3573 16.2825 15.2175ZM8.25005 13.5C7.2117 13.5 6.19666 13.1921 5.33331 12.6152C4.46995 12.0383 3.79704 11.2184 3.39968 10.2591C3.00232 9.29977 2.89836 8.24417 3.10093 7.22577C3.3035 6.20737 3.80351 5.2719 4.53774 4.53768C5.27197 3.80345 6.20743 3.30344 7.22583 3.10087C8.24423 2.89829 9.29983 3.00226 10.2591 3.39962C11.2185 3.79698 12.0384 4.46989 12.6153 5.33325C13.1921 6.1966 13.5001 7.21164 13.5001 8.24999C13.5001 9.64238 12.9469 10.9777 11.9624 11.9623C10.9778 12.9469 9.64244 13.5 8.25005 13.5Z"
              fill="#6F6F6F"
            />
          </svg>
          <SearchInput
            type="text"
            placeholder="Введите название поставщика, продукции или пункт отгрузки"
            value={FiltersStore.getSeacrhing()}
            onChange={(e) => FiltersStore.setSearching(e.target.value)}
            onFocus={() => setFocused(true)}
            onBlur={() => handleOnBlur()}
          />
        </SearchInputContainer>
      </FilterSearchContainer>
      {FiltersStore.getIfFiltered() && (
        <RemoveFiltersLine>
          <RemoveFiltersButton onClick={() => FiltersStore.removeAllFilters()}>
            Сбросить все фильтры
          </RemoveFiltersButton>
        </RemoveFiltersLine>
      )}
      <NavbarExportContainer>
        <RequestNavbar
          tabs={[
            "Все заявки",
            "Новые",
            "Согласование",
            "В закупке",
            "Контракт разорван",
            "Избранные",
          ]}
          currentTab={currentTab}
          changeTabFunc={setCurrentTab}
        />
        <ExportAction />
      </NavbarExportContainer>
      {!loading ? (
        <RequestTable />
      ) : (
        <Oval
          ariaLabel="loading-indicator"
          height={100}
          width={100}
          strokeWidth={5}
          strokeWidthSecondary={3}
          color="#365EDC"
          secondaryColor="rgba(139, 187, 75, 0)"
        />
      )}
      {FiltersStore.getIsOpen() && <FiltersModal />}
    </Wrapper>
  );
}

export default observer(Request);

const FilterSearchContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FiltersButton = styled.button<BellButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;

  height: 36px;

  background: ${(props) => props.backgroundColor};

  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--blue);
  transition: var(--transition);

  &:hover {
    border-color: var(--lightBlue);
  }

  &:active {
    background: var(--lightBlue3);
    border-color: var(--blue);
  }
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const SearchInput = styled.input`
  flex: 1;
  /* width: calc(100% - 10px); */
  margin-left: 8px;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  border: unset;
  border-radius: 8px;
  background: var(--GrayLight1);
  height: 30px;

  &::placeholder {
    color: var(--GrayLight9);
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;

const SearchInputContainer = styled.form<SearchInputProps>`
  width: 556px;
  height: 36px;
  padding-left: 16px;
  border: 1px solid ${(props) => (props.focused ? "#2F52BF" : "#DBDBDB")};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  & ${SearchInput}:focus {
    border-color: var(--darkBlue);
  }
`;

const NavbarExportContainer = styled.div`
  width: 100%;
  margin-top: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--GrayLight4);
`;

const RemoveFiltersLine = styled.div`
  width: 100%;
  max-width: 200px;
  height: fit-content;
  margin: 10px 0px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-self: flex-start;
`;

const RemoveFiltersButton = styled(ResetButton)`
  margin: unset;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 140%;
`;
