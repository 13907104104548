import { flow, types } from "mobx-state-tree";
import { RequestTokenModel } from "../pages/Login/LoginLoading";
import axios from "axios";
import { BaseURL } from "../utils/BaseUrl";

export interface RoleInterface {
  id: number;
  role_name: string;
  description: string | null;
}

export interface CompanyInterface {
  id: number;
  full_name: string;
  short_name: string;
  kpp: string;
  okato: string;
  okpo: string;
  description: string;
  register_date: string;
  register_number: string | null;
  start_at_version: string;
  end_at_version: string | null;
  is_active_version: boolean;
  is_actual_now_version: boolean;
  parent_id: number | null;
  inn: string | null;
  ogrn: string | null;
  snils: string | null;
  register_issue_by: string | null;
}

export interface ProfileDataInterface {
  first_name: string;
  second_name: string;
  last_name: string;
  snils: string;
  inn: string;
  passport_series: string;
  passport_number: string;
}

const TokenModel = types.model({
  access: types.maybeNull(types.string),
  refresh: types.optional(types.string, ""),
});

const RoleModel = types.model({
  id: types.optional(types.number, -1),
  role_name: types.optional(types.string, ""),
  description: types.maybeNull(types.string),
});

const ProfileDataModel = types.model({
  first_name: types.optional(types.string, ""),
  second_name: types.optional(types.string, ""),
  last_name: types.optional(types.string, ""),
  snils: types.optional(types.string, ""),
  inn: types.optional(types.string, ""),
  passport_series: types.optional(types.string, ""),
  passport_number: types.optional(types.string, ""),
});

const CompanyModel = types.model({
  id: types.optional(types.number, -1),
  full_name: types.optional(types.string, ""),
  short_name: types.optional(types.string, ""),
  //   kpp: types.optional(types.string, ""),
  //   okato: types.optional(types.string, ""),
  //   okpo: types.optional(types.string, ""),
  //   description: types.optional(types.string, ""),
  //   register_date: types.optional(types.string, ""),
  //   register_number: types.maybeNull(types.string),
  //   start_at_version: types.optional(types.string, ""),
  //   end_at_version: types.maybeNull(types.string),
  //   is_active_version: types.optional(types.boolean, false),
  //   is_actual_now_version: types.optional(types.boolean, false),
  //   parent_id: types.maybeNull(types.number),
  //   inn: types.maybeNull(types.string),
  //   ogrn: types.maybeNull(types.string),
  //   snils: types.maybeNull(types.string),
  //   register_issue_by: types.maybeNull(types.string),
});

export const UserStore = types
  .model({
    Token: types.optional(TokenModel, {}),
    isLogin: types.optional(types.boolean, false),
    Role: types.optional(RoleModel, {}),
    Company: types.optional(CompanyModel, {}),
    ProfileData: types.optional(ProfileDataModel, {}),
  })
  .actions((self) => ({
    setToken(payload: RequestTokenModel) {
      self.Token = payload;
      self.isLogin = true;
    },
    setRole(role: RoleInterface) {
      self.Role = role;
    },
    setCompany(company: CompanyInterface) {
      self.Company = company;
    },
    setProfileData(userData: ProfileDataInterface) {
      self.ProfileData = userData;
    },
    Logout() {
      self.Token.access = null;
      self.Token.refresh = "";
      self.isLogin = false;
      self.Role.role_name = "";
      self.ProfileData.first_name = "";
      self.ProfileData.last_name = "";
      self.Company.id = -1;
    },
    updateToken: flow(function* () {
      const body = {
        refresh: self.Token.refresh,
      };
      const result = yield axios.post(`${BaseURL}/token/refresh/`, body);
      self.Token = result.data;
    }),
    clearStore() {
      self.Role.role_name = "";
      self.ProfileData.first_name = "";
      self.ProfileData.last_name = "";
      self.Company.full_name = "";
      self.Company.short_name = "";
    },
  }))
  .views((self) => ({
    isLoginFunc() {
      return !!self.Token.access;
    },
    getAccessToken() {
      return self.Token.access;
    },
    getRole() {
      return self.Role.role_name;
    },
    getProfileData() {
      return self.ProfileData;
    },
    getCompanyData() {
      return self.Company;
    },
  }));
