import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CurrentPageProps } from "../Header";
import { Link, Outlet, useLocation } from "react-router-dom";
import { ButtonSecondary } from "../../styles/Buttons/ButtonSecondary";
import { usePersistentStore } from "../../store/RootStore";
import { ButtonActiveProps } from "../Dropdown/Dropdown";
import { DownArrow } from "../../styles/DownArrow";
import listenForOutsideClicks from "../../utils/ListenForOutsideClicks";

const NewRequestProducts = () => {
  const { NewRequestStore } = usePersistentStore();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <Container>
      <NavbarContainer>
        <NavTabsContainer>
          <Link to="">
            <NavTabs
              color={"#171717"}
              borderColor={
                !location.pathname.includes("sample_quality")
                  ? "#365EDC"
                  : "transparent"
              }
            >
              <span>Товары</span>
              <AddButton
                onClick={() => NewRequestStore.toggleAddProductModal(true)}
              >
                +
              </AddButton>
            </NavTabs>
          </Link>
          <Link to="sample_quality">
            <NavTabs
              color={"#171717"}
              borderColor={
                location.pathname.includes("sample_quality")
                  ? "#365EDC"
                  : "transparent"
              }
            >
              Качество образцов
            </NavTabs>
          </Link>
        </NavTabsContainer>
        <ActionContainer ref={menuRef}>
          <ActionButton
            onClick={() => toggleOpen()}
            borderColor={open ? "#3F6DFF" : "#DBDBDB"}
            backGroundColor={open ? "#F2F5FF" : "transparent"}
            disabled={NewRequestStore.getAmountOfSelected() === 0}
          >
            <div>Действия</div>
            <DownArrow src="/assets/Header/AccountMore.svg" />
          </ActionButton>
          {open && (
            <ExportMenu>
              {NewRequestStore.getAmountOfSelected() > 1 ? (
                <Item
                  onClick={() => {
                    NewRequestStore.deleteProducts();
                    setOpen(false);
                  }}
                >
                  Удалить выбранное ({NewRequestStore.getAmountOfSelected()})
                </Item>
              ) : (
                <>
                  <Item
                    onClick={() => {
                      NewRequestStore.deleteProducts();
                      setOpen(false);
                    }}
                  >
                    Удалить строку
                  </Item>
                </>
              )}
            </ExportMenu>
          )}
        </ActionContainer>
      </NavbarContainer>
      <Outlet />
    </Container>
  );
};

export default observer(NewRequestProducts);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
`;

const NavbarContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: white;
  width: 100%;
  padding: 0px 20px;
  border-radius: 8px 8px 0px 0px;
`;

const NavTabsContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
`;

const NavTabs = styled.div<CurrentPageProps>`
  width: fit-content;
  padding: 19px 8px 15px;

  display: flex;
  align-items: center;
  gap: 4px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
  border-bottom: 4px solid ${(props) => props.borderColor};
  cursor: pointer;
`;

const AddButton = styled(ButtonSecondary)`
  width: 20px;
  height: 20px;

  text-align: center;
  justify-content: center;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  border-radius: 4px;
`;

const ActionContainer = styled.div`
  position: relative;
`;

const ActionButton = styled.button<ButtonActiveProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  /* height: 36px; */

  text-align: left;
  cursor: pointer;
  background-color: ${(props) => props.backGroundColor};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;
  padding: 7px 9px 7px 16px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: var(--transition);

  &:hover {
    border-color: var(--lightBlue);
  }

  & .active {
    border-color: var(--darkBlue);
  }

  &:disabled {
    border-color: var(--GrayLight10);
    color: var(--GrayLight10);
    cursor: unset;
  }
`;

const ExportMenu = styled.div`
  position: absolute;
  top: calc(100% + 3px);
  right: 0;
  z-index: 1;
  width: 225px;
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);
`;

const Item = styled.div`
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);

  transition: var(--transition);

  &:hover {
    background: var(--GrayLight2);
    color: var(--blue);
  }
`;
