import { Table, TableBody, TableContainer } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { usePersistentStore } from "../../../../store/RootStore";
import EmptyTable from "../EmptyTable";
import NewRequestSampleQualityTableHeader from "./NewRequestSampleQualityTableHeader";
import NewRequestSampleQualityTableRow from "./NewRequestSampleQualityTableRow";

const NewRequestSampleQualityTable = () => {
  const { NewRequestStore } = usePersistentStore();

  return (
    <Container>
      <StyledTableContainer>
        {NewRequestStore.getProducts().length !== 0 ? (
          <StyledTable>
            <NewRequestSampleQualityTableHeader />
            <StyledTableBody>
              {NewRequestStore.getProducts().map((e, i) => {
                return (
                  <NewRequestSampleQualityTableRow
                    key={i}
                    sampleQuality={e.SampleQuality}
                    uuid={e.uuid}
                    departurePoint={e.receiving_point_id}
                    mass={e.value}
                    measure={e.measure_id === "Килограмм" ? "кг" : "т"}
                  />
                );
              })}
            </StyledTableBody>
          </StyledTable>
        ) : (
          <EmptyTable />
        )}
      </StyledTableContainer>
    </Container>
  );
};

export default observer(NewRequestSampleQualityTable);

const Container = styled.div`
  width: 100%;
  max-width: 1250px;
`;

const StyledTableContainer = styled(TableContainer)`
  width: 1250px !important;
  border-radius: 8px;
  overflow: scroll !important;
`;

const StyledTableBody = styled(TableBody)`
  background: white;
  border-radius: 8px;
`;

const StyledTable = styled(Table)`
  width: 1250px !important;
  overflow: initial !important;
`;
