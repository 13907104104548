import styled from "styled-components";

export const FormContainer = styled.form`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 556px;
  width: 100%;
`;
