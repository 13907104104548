import { types } from "mobx-state-tree";
import { RequestInterface } from "./RequestStore";

const FilterPriceModel = types.model({
  low: types.string,
  high: types.string,
});

const DateFilterModel = types.model({
  start: types.optional(types.string, ""),
  end: types.optional(types.string, ""),
});

const AmountModel = types.model({
  low: types.number,
  high: types.number,
  lowType: types.string,
  highType: types.string,
});

export const FiltersStore = types
  .model({
    isOpen: types.optional(types.boolean, false),
    noResults: types.optional(types.boolean, false),
    OfferedPrice: types.optional(FilterPriceModel, {
      low: "",
      high: "",
    }),
    AgreedPrice: types.optional(FilterPriceModel, {
      low: "",
      high: "",
    }),
    DeliveryPrice: types.optional(FilterPriceModel, {
      low: "",
      high: "",
    }),
    AgreedDeliveryPrice: types.optional(FilterPriceModel, {
      low: "",
      high: "",
    }),
    PriceTonn: types.optional(FilterPriceModel, {
      low: "",
      high: "",
    }),
    CurrentStatusTab: types.optional(types.string, ""),
    HarvestYear: types.optional(types.array(types.string), []),
    Supplier: types.optional(types.array(types.string), []),
    Status: types.optional(types.array(types.string), []),
    Shipment: types.optional(types.array(types.string), []),
    Traders: types.optional(types.array(types.string), []),
    Amount: types.optional(AmountModel, {
      low: 0,
      high: 0,
      lowType: "кг",
      highType: "кг",
    }),
    AgroCultureType: types.optional(types.array(types.string), []),
    Searching: types.optional(types.string, ""),
    Date: types.optional(DateFilterModel, {}),
    DeparturePoint: types.optional(types.array(types.string), []),
  })
  .actions((self) => ({
    toggleIsOpen() {
      self.isOpen = !self.isOpen;
    },
    setNoResults() {
      self.noResults = true;
    },
    removeNoResults() {
      self.noResults = false;
    },
    setFilterByOfferedPrice(low: string, high: string) {
      self.OfferedPrice.low = low;
      self.OfferedPrice.high = high;
    },
    removeFilterByOfferedPrice() {
      self.OfferedPrice.low = "";
      self.OfferedPrice.high = "";
    },
    setFilterByAgreedPrice(low: string, high: string) {
      self.AgreedPrice.low = low;
      self.AgreedPrice.high = high;
    },
    removeFilterByAgreedPrice() {
      self.AgreedPrice.low = "";
      self.AgreedPrice.high = "";
    },
    setFilterByDeliveryPrice(low: string, high: string) {
      self.DeliveryPrice.low = low;
      self.DeliveryPrice.high = high;
    },
    removeFilterByDeliveryPrice() {
      self.DeliveryPrice.low = "";
      self.DeliveryPrice.high = "";
    },
    setFilterByAgreedDeliveryPrice(low: string, high: string) {
      self.AgreedDeliveryPrice.low = low;
      self.AgreedDeliveryPrice.high = high;
    },
    removeFilterByAgreedDeliveryPrice() {
      self.AgreedDeliveryPrice.low = "";
      self.AgreedDeliveryPrice.high = "";
    },
    setFilterByPriceTonn(low: string, high: string) {
      self.PriceTonn.low = low;
      self.PriceTonn.high = high;
    },
    removeFilterByPriceTonn() {
      self.PriceTonn.low = "";
      self.PriceTonn.high = "";
    },
    setCurrentStatusTab(currentStatus: string) {
      self.CurrentStatusTab = currentStatus;
    },
    setSearching(searching: string) {
      self.Searching = searching;
    },
    clearSearching() {
      self.Searching = "";
    },
    changeHarvestYearFilter(harvestYear: string) {
      let indexOf = self.HarvestYear.findIndex((e) => e === harvestYear);
      if (indexOf > -1) {
        self.HarvestYear.splice(indexOf, 1);
      } else {
        self.HarvestYear.push(harvestYear);
      }
    },
    toggleAllHarvestYearFilters(harvestYear: string, types: string) {
      let indexOf = self.HarvestYear.findIndex((e) => e === harvestYear);
      if (types === "set") {
        if (indexOf === -1) self.HarvestYear.push(harvestYear);
      } else {
        self.HarvestYear.splice(indexOf, 1);
      }
    },
    resetHarvestYearFilter() {
      self.HarvestYear.splice(0, self.HarvestYear.length);
    },
    changeSupplierFilter(supplier: string) {
      let indexOf = self.Supplier.findIndex((e) => e === supplier);
      if (indexOf > -1) {
        self.Supplier.splice(indexOf, 1);
      } else {
        self.Supplier.push(supplier);
      }
    },
    resetSupplierFilter() {
      self.Supplier.splice(0, self.Supplier.length);
    },
    changeStatusFilter(status: string) {
      let indexOf = self.Status.findIndex((e) => e === status);
      if (indexOf > -1) {
        self.Status.splice(indexOf, 1);
      } else {
        self.Status.push(status);
      }
    },
    resetStatusFilter() {
      self.Status.splice(0, self.Status.length);
    },
    toggleAllStatusesFilter(status: string, type: string) {
      let indexOf = self.Status.findIndex((e) => e === status);
      if (type === "set") {
        if (indexOf === -1) self.Status.push(status);
      } else {
        self.Status.splice(indexOf, 1);
      }
    },
    changeShipmentFilter(shipment: string) {
      let indexOf = self.Shipment.findIndex((e) => e === shipment);
      if (indexOf > -1) {
        self.Shipment.splice(indexOf, 1);
      } else {
        self.Shipment.push(shipment);
      }
    },
    resetShipmentFilter() {
      self.Shipment.splice(0, self.Shipment.length);
    },
    changeTradersFilter(trader: string) {
      let indexOf = self.Traders.findIndex((e) => e === trader);
      if (indexOf > -1) {
        self.Traders.splice(indexOf, 1);
      } else {
        self.Traders.push(trader);
      }
    },
    resetTraidersFilter() {
      self.Traders.splice(0, self.Traders.length);
    },
    toggleAllTraidersFilter(trader: string, types: string) {
      let indexOf = self.Traders.findIndex((e) => e === trader);
      if (types === "set") {
        if (indexOf === -1) self.Traders.push(trader);
      } else {
        self.Traders.splice(indexOf, 1);
      }
    },
    changeAgrocultureTypeFilter(agrocultureType: string) {
      let indexOf = self.AgroCultureType.findIndex(
        (e) => e === agrocultureType
      );
      if (indexOf > -1) {
        self.AgroCultureType.splice(indexOf, 1);
      } else {
        self.AgroCultureType.push(agrocultureType);
      }
    },
    toggleAllAgrocultureFilters(agrocultureType: string, types: string) {
      let indexOf = self.AgroCultureType.findIndex(
        (e) => e === agrocultureType
      );
      if (types === "set") {
        if (indexOf === -1) self.AgroCultureType.push(agrocultureType);
      } else {
        self.AgroCultureType.splice(indexOf, 1);
      }
    },
    resetAgrocultureTypeFilter() {
      self.AgroCultureType.splice(0, self.AgroCultureType.length);
    },
    setFilterByAmount(
      low: number,
      high: number,
      lowType: string,
      highType: string
    ) {
      self.Amount.low = low;
      self.Amount.high = high;
      self.Amount.lowType = lowType;
      self.Amount.highType = highType;
    },
    removeFilterByAmount() {
      self.Amount.low = 0;
      self.Amount.high = 0;
      self.Amount.lowType = "кг";
      self.Amount.highType = "кг";
    },
    setStartDate(startDate: string) {
      self.Date.start = startDate;
    },
    setEndDate(endDate: string) {
      self.Date.end = endDate;
    },
    clearDateFilter() {
      self.Date.start = "";
      self.Date.end = "";
    },
    changeDeparturePointFilter(deparurePoint: string) {
      let indexOf = self.DeparturePoint.findIndex((e) => e === deparurePoint);
      if (indexOf > -1) {
        self.DeparturePoint.splice(indexOf, 1);
      } else {
        self.DeparturePoint.push(deparurePoint);
      }
    },
    resetDeparturePointFilter() {
      self.DeparturePoint.splice(0, self.DeparturePoint.length);
    },
    toggleAllDeparturePointFilter(deparurePoint: string, types: string) {
      let indexOf = self.DeparturePoint.findIndex((e) => e === deparurePoint);
      if (types === "set") {
        if (indexOf === -1) self.DeparturePoint.push(deparurePoint);
      } else {
        self.DeparturePoint.splice(indexOf, 1);
      }
    },
    removeAllFilters() {
      this.removeFilterByAgreedDeliveryPrice();
      this.removeFilterByAgreedPrice();
      this.removeFilterByAmount();
      this.removeFilterByDeliveryPrice();
      this.removeFilterByOfferedPrice();
      this.clearDateFilter();
      this.clearSearching();
      this.resetAgrocultureTypeFilter();
      this.resetDeparturePointFilter();
      this.resetHarvestYearFilter();
      this.resetShipmentFilter();
      this.resetStatusFilter();
      this.resetSupplierFilter();
      this.resetTraidersFilter();
      this.removeFilterByPriceTonn();
    },
  }))
  .views((self) => ({
    getIsOpen() {
      return self.isOpen;
    },
    getNoResults() {
      return self.noResults;
    },
    getOfferedMinPrice() {
      return self.OfferedPrice.low;
    },
    getOfferedMaxPrice() {
      return self.OfferedPrice.high;
    },
    getAgreedMinPrice() {
      return self.AgreedPrice.low;
    },
    getAgreedMaxPrice() {
      return self.AgreedPrice.high;
    },
    getDeliveryMinPrice() {
      return self.DeliveryPrice.low;
    },
    getDeliveryMaxPrice() {
      return self.DeliveryPrice.high;
    },
    getAgreedDeliveryMinPrice() {
      return self.AgreedDeliveryPrice.low;
    },
    getAgreedDeliveryMaxPrice() {
      return self.AgreedDeliveryPrice.high;
    },
    getPriceTonnMin() {
      return self.PriceTonn.low;
    },
    getPriceTonnMax() {
      return self.PriceTonn.high;
    },
    getHarvestYearFilterStatus(year: string) {
      return self.HarvestYear.includes(year);
    },
    getSupplierFilterStatus(supplier: string) {
      return self.Supplier.includes(supplier);
    },
    getStatusFilterStatus(status: string) {
      return self.Status.includes(status);
    },
    getShipmentFilterStatus(shipment: string) {
      return self.Shipment.includes(shipment);
    },
    getTraderFilterStatus(trader: string) {
      return self.Traders.includes(trader);
    },
    getDeparutrePointFilterStatus(departurePoint: string) {
      return self.DeparturePoint.includes(departurePoint);
    },
    getAgrocultureTypeFilterStatus(agrocultureType: string) {
      return self.AgroCultureType.includes(agrocultureType);
    },
    getAmountMin() {
      return self.Amount.low;
    },
    getAmountMax() {
      return self.Amount.high;
    },
    getAmountValueMin() {
      return self.Amount.lowType;
    },
    getAmountValueMax() {
      return self.Amount.highType;
    },
    getSeacrhing() {
      return self.Searching;
    },
    getStartDate() {
      return self.Date.start;
    },
    getEndDate() {
      return self.Date.end;
    },
    getIfFiltered() {
      return (
        self.AgreedDeliveryPrice.low !== "" ||
        self.AgreedDeliveryPrice.high !== "" ||
        self.AgreedPrice.low !== "" ||
        self.AgreedPrice.high !== "" ||
        self.AgroCultureType.length !== 0 ||
        self.Amount.high !== 0 ||
        self.Amount.low !== 0 ||
        self.Date.start !== "" ||
        self.Date.end !== "" ||
        self.DeliveryPrice.low !== "" ||
        self.DeliveryPrice.high !== "" ||
        self.PriceTonn.low !== "" ||
        self.PriceTonn.high !== "" ||
        self.DeparturePoint.length !== 0 ||
        self.HarvestYear.length !== 0 ||
        self.OfferedPrice.low !== "" ||
        self.OfferedPrice.high !== "" ||
        self.Searching !== "" ||
        self.Shipment.length !== 0 ||
        self.Status.length !== 0 ||
        self.Supplier.length !== 0 ||
        self.Traders.length !== 0
      );
    },
    FilteredData(payload: Array<any>) {
      let filteredData = payload;

      // filter for unit_offered_price
      if (self.OfferedPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: RequestInterface) =>
            Number(e.price[0]?.unit_offered_price) >
            Number(self.OfferedPrice.low)
        );
      }
      if (self.OfferedPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: RequestInterface) =>
            Number(e.price[0]?.unit_offered_price) <
            Number(self.OfferedPrice.high)
        );
      }

      // filter for unit_agreed_price
      if (self.AgreedPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: RequestInterface) =>
            Number(e.price[0]?.unit_agreed_price) > Number(self.AgreedPrice.low)
        );
      }
      if (self.AgreedPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: RequestInterface) =>
            Number(e.price[0]?.unit_agreed_price) <
            Number(self.AgreedPrice.high)
        );
      }

      //filter for unit_delivery_price
      if (self.DeliveryPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: RequestInterface) =>
            Number(e.price[0]?.unit_delivery_price) >
            Number(self.DeliveryPrice.low)
        );
      }
      if (self.DeliveryPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: RequestInterface) =>
            Number(e.price[0]?.unit_delivery_price) <
            Number(self.DeliveryPrice.high)
        );
      }

      //-----filter for unit_agreed_delivery_price------//
      if (self.AgreedDeliveryPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: RequestInterface) =>
            Number(e.price[0]?.unit_agreed_delivery_price) >
            Number(self.AgreedDeliveryPrice.low)
        );
      }
      if (self.AgreedDeliveryPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: RequestInterface) =>
            Number(e.price[0].unit_agreed_delivery_price) <
            Number(self.AgreedDeliveryPrice.high)
        );
      }

      if (self.PriceTonn.low !== "") {
        filteredData = filteredData.filter(
          (e: any) => Number(e.tonna_price) > Number(self.PriceTonn.low)
        );
      }

      if (self.PriceTonn.high !== "") {
        filteredData = filteredData.filter(
          (e: any) => Number(e.tonna_price) < Number(self.PriceTonn.high)
        );
      }

      //-----filter for showing data using tabs above table------//
      if (
        self.CurrentStatusTab !== "" &&
        self.CurrentStatusTab !== "Favourite"
      ) {
        filteredData = filteredData.filter(
          (e: any) => e.contract_status === self.CurrentStatusTab
        );
      }

      if (
        self.CurrentStatusTab !== "" &&
        self.CurrentStatusTab === "Favourite"
      ) {
        filteredData = filteredData.filter(
          (e: RequestInterface) => e.isFavourite === true
        );
      }

      //-----filter  for harvest year------//
      if (self.HarvestYear.length !== 0) {
        filteredData = filteredData.filter((e: RequestInterface) =>
          self.HarvestYear.includes(String(e.harvest_year))
        );
      }

      //-----filter for counterparty_id-----//
      if (self.Supplier.length !== 0) {
        filteredData = filteredData.filter((e: any) =>
          self.Supplier.includes(String(e.counterparty_company.short_name))
        );
      }

      //----filter for contract_status_id----///
      if (self.Status.length !== 0) {
        filteredData = filteredData.filter((e: any) =>
          self.Status.includes(String(e.contract_status))
        );
      }

      //---filter for delivery_tern_id---//
      if (self.Shipment.length !== 0) {
        filteredData = filteredData.filter((e: RequestInterface) =>
          self.Shipment.includes(String(e.delivery_tern_id))
        );
      }

      //-----filter for agrocultureType----///
      if (self.AgroCultureType.length !== 0) {
        filteredData = filteredData.filter((e: RequestInterface) =>
          self.AgroCultureType.includes(e.agro_culture_type)
        );
      }

      //---filter for trader_from_company----///
      if (self.Traders.length !== 0) {
        filteredData = filteredData.filter((e: RequestInterface) =>
          self.Traders.includes(String(e.trader_from_company))
        );
      }

      //---filter for vat---///
      if (self.Amount.low !== 0) {
        filteredData = filteredData.filter((e: any) => {
          if (self.Amount.lowType === "кг" && e.measure === "кг") {
            return e.vat > self.Amount.low;
          } else if (self.Amount.lowType === "т" && e.measure_id === "кг") {
            return e.vat > self.Amount.low * 1000;
          } else if (self.Amount.lowType === "кг" && e.measure === "т") {
            return e.vat * 1000 > self.Amount.low;
          } else {
            return e.vat * 1000 > self.Amount.low * 1000;
          }
        });
      }

      if (self.Amount.high !== 0) {
        filteredData = filteredData.filter((e: RequestInterface) => {
          if (self.Amount.highType === "т" && e.measure_id === "т") {
            return e.vat * 1000 < self.Amount.high * 1000;
          } else if (self.Amount.highType === "кг" && e.measure_id === "ƒ") {
            return e.vat * 1000 < self.Amount.high;
          } else if (self.Amount.highType === "т" && e.measure_id === "кг") {
            return e.vat < self.Amount.high * 1000;
          } else {
            return e.vat < self.Amount.high;
          }
        });
      }

      //---filter by searching bar -----///

      if (self.Searching !== "") {
        self.removeNoResults();
        filteredData = filteredData.filter(
          (e: any) =>
            e.counterparty_company.short_name
              .toUpperCase()
              .includes(self.Searching.toUpperCase().trim()) ||
            e.agro_culture_type
              .toUpperCase()
              .includes(self.Searching.toUpperCase().trim()) ||
            e.delivery_tern_id
              .toUpperCase()
              .includes(self.Searching.toUpperCase().trim())
        );
        if (filteredData.length === 0) {
          self.setNoResults();
        }
      }

      //--filter for date--///
      if (self.Date.start !== "") {
        self.removeNoResults();
        filteredData = filteredData.filter(
          (e: any) => new Date(self.Date.start) < new Date(e.conclusion_date)
        );
      }

      if (self.Date.end !== "") {
        self.removeNoResults();
        filteredData = filteredData.filter(
          (e: any) => new Date(self.Date.end) > new Date(e.conclusion_date)
        );
      }

      ////----departure_point filter---////
      if (self.DeparturePoint.length !== 0) {
        filteredData = filteredData.filter((e: any) =>
          self.DeparturePoint.includes(String(e.departure_point_id))
        );
      }

      return filteredData;
    },
  }));
