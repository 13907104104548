import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { ButtonPrimary } from "../../styles/Buttons/ButtonPrimary";
import { usePersistentStore } from "../../store/RootStore";
import axios, { AxiosResponse } from "axios";
import { BaseURL } from "../../utils/BaseUrl";
import { useNavigate } from "react-router-dom";
import { DetailedRequestStore } from "../../store/DetailedRequestStore";

const NewRequestFooter = () => {
  const { NewRequestStore, UserStore, SimpleDictionaryStore } =
    usePersistentStore();
  const navigate = useNavigate();

  const handleSave = () => {
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };
    const products = NewRequestStore.getProducts().map((e) => {
      let prices = {
        unit_offered_price: e.price[0].unit_offered_price,
        unit_agreed_price: e.price[0].unit_agreed_price,
        unit_delivery_price:
          e.price[0].unit_delivery_price + e.price[0].unit_offered_price,
        unit_agreed_delivery_price: e.price[0].unit_agreed_price
          ? e.price[0]?.unit_agreed_price + e.price[0].unit_delivery_price
          : e.price[0].unit_delivery_price,
        currency_id: "RUB",
        comment: "some testing text",
      };
      let sampleQuality = {
        humidity: e.SampleQuality.find((e) => e.key === "Влажность, %")?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Влажность, %")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        weed_impurity: e.SampleQuality.find(
          (e) => e.key === "Сорная примесь, %"
        )?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Сорная примесь, %")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        infection: e.SampleQuality.find((e) => e.key === "Зараженность, %")
          ?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Зараженность, %")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        grain_admixture: e.SampleQuality.find(
          (e) => e.key === "Зерновая примесь, %"
        )?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Зерновая примесь, %")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        gluten: e.SampleQuality.find((e) => e.key === "Клейковина")?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Клейковина")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        gluten_deformation_index: e.SampleQuality.find((e) => e.key === "ИДК")
          ?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "ИДК")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        protein: e.SampleQuality.find((e) => e.key === "Белок, %")?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Белок, %")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        indicator_of_a_amylase_activity: e.SampleQuality.find(
          (e) => e.key === "ЧП"
        )?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "ЧП")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        viability_for_tetrazole: e.SampleQuality.find(
          (e) => e.key === "Жизнеспособность по тетразолу, %"
        )?.value
          ? Number(
              e.SampleQuality.find(
                (e) => e.key === "Жизнеспособность по тетразолу, %"
              )
                ?.value.split(",")
                .join(".")
            )
          : 0,
        large_grain: e.SampleQuality.find((e) => e.key === "Крупное зерно, %")
          ?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Крупное зерно, %")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        small_grain: e.SampleQuality.find((e) => e.key === "Мелкое зерно, %")
          ?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Мелкое зерно, %")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        oil_impurity: e.SampleQuality.find(
          (e) => e.key === "Масляничная примесь,%"
        )?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Масляничная примесь,%")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        nature: e.SampleQuality.find((e) => e.key === "Натура")?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Натура")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        damaged_grains: e.SampleQuality.find(
          (e) => e.key === "Поврежденные зерна, %"
        )?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Поврежденные зерна, %")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        purity: e.SampleQuality.find((e) => e.key === "Чистота")?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Чистота")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        oil_content: e.SampleQuality.find((e) => e.key === "Масличность, %")
          ?.value
          ? Number(
              e.SampleQuality.find((e) => e.key === "Масличность, %")
                ?.value.split(",")
                .join(".")
            )
          : 0,
        file_id: e.file_id,
        created_at: NewRequestStore.getSampleQualityDate(e.uuid)
          ? `${NewRequestStore.getSampleQualityDate(e.uuid)
              ?.toLocaleDateString()
              .split(".")
              .reverse()
              .join("-")}`
          : null,
      };
      return {
        unit_value: e.measure_id === "Килограмм" ? "кг" : "т",
        product_value: e.value,
        departure_point_id: NewRequestStore.getDeparturePoint(),
        receiving_point_id: e.receiving_point_id,
        harvest_year: e.harvest_year,
        quality: sampleQuality,
        prices: prices,
      };
    });
    const body = {
      request_number: NewRequestStore.getRequestNumber(),
      delivery_tern_id: NewRequestStore.getDeliveryCondition(),
      agro_culture_type: NewRequestStore.getAgroCultureType(),
      contract_start_date: !!NewRequestStore.getContractStartDate()
        ? `${NewRequestStore.getContractStartDate().split(".")[2]}-${
            NewRequestStore.getContractStartDate().split(".")[1]
          }-${NewRequestStore.getContractStartDate().split(".")[0]}`
        : null,
      contract_end_date: !!NewRequestStore.getContractEndDate()
        ? `${NewRequestStore.getContractEndDate().split(".")[2]}-${
            NewRequestStore.getContractEndDate().split(".")[1]
          }-${NewRequestStore.getContractEndDate().split(".")[0]}`
        : null,
      full_name: NewRequestStore.getCounterpartyId(),
      products: products,
      trader_from_company: "987654",
      cargo_class: NewRequestStore.getCargoClass(),
      comment: NewRequestStore.getComment(),
    };

    // console.log("🚀 ~ file: NewRequestFooter.tsx:83 ~ handleSave ~ body", body);
    axios
      .post(`${BaseURL}/v1/contracts/add_contract/`, body, config)
      .then((res: AxiosResponse<{ contract_id: string }>) => {
        // console.log("🚀 ~ file: NewRequestFooter.tsx:21 ~ .then ~ res", res);
        if (res.data.contract_id) {
          navigate("/request");
        }
      })
      .catch((err) => {
        console.log(
          "🚀 ~ file: NewRequestFooter.tsx:23 ~ handleSave ~ err",
          err
        );
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
          handleSave();
        }
      });
  };

  return (
    <Container>
      <Button
        disabled={
          (!NewRequestStore.getCanSave() as boolean) ||
          NewRequestStore.getProducts().length === 0
        }
        onClick={() => handleSave()}
      >
        Сохранить
      </Button>
    </Container>
  );
};

export default observer(NewRequestFooter);

const Container = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 72px;
  padding: 18px 32px;

  background: white;
  box-shadow: 0px 12px 32px -5px rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 8px 8px;

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

const Button = styled(ButtonPrimary)`
  width: fit-content;
  padding: 8px 32px;
`;
