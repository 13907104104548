import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import CheckBox from "../CheckBox";

interface DropdownItemProps {
  name: string;
  changeStatusFunction: Function;
  checkStatusFunc: Function;
}

const DropdownItem = ({ name, changeStatusFunction, checkStatusFunc }: DropdownItemProps) => {

  return (
    <Item onClick={() => changeStatusFunction(name)}>
      {/* <CheckBox type="checkbox" checked={selected} /> */}
      <CheckBox checked={checkStatusFunc(name)} />
      <ItemName>{name}</ItemName>
    </Item>
  );
};

export default observer(DropdownItem);

const ItemName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  transition: var(--transition);
`;

const Item = styled.div`
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: var(--transition);

  &:hover {
    background: var(--GrayLight2);
  }

  &:hover ${ItemName} {
    color: var(--GrassLight11);
  }
`;

// const CheckBox = styled.input`
//   width: 16px;
//   height: 16px;
//   border: 1px solid var(--GrayLight7);
//   cursor: pointer;

//   &:checked {
//     color: white;
//     background-color: black;
//   }
// `;
