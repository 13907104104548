import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ModalWrapper } from "../../../styles/ModalWrapper";
import styled from "styled-components";
import {
  HeaderInModals,
  TitleContainerInDetailedRequestModals,
  TitleInDetailedRequestModals,
} from "../../../styles/DetailedRequestModals/TitleInDetailedRequestModal";
import CloseButton from "../../Buttons/CloseButton";
import Comments from "./Comments";
import { usePersistentStore } from "../../../store/RootStore";
import { ButtonPrimary } from "../../../styles/Buttons/ButtonPrimary";
import { InputContainer } from "../../../styles/DetailedRequestModals/InputContainer";
import { BlackTitleInput } from "../../../styles/DetailedRequestModals/BlackLabelInput";
import { UncoloredInput } from "../../../styles/DetailedRequestModals/UncoloredInput";
import { GreyLabelInput } from "../../../styles/DetailedRequestModals/GreyLabelInput";
import { ColoredInput } from "../../../styles/DetailedRequestModals/ColoredInput";
import { PriceInput } from "../../../styles/DetailedRequestModals/PriceInput";
import axios from "axios";
import { BaseURL } from "../../../utils/BaseUrl";
import { NumberArray } from "../../../utils/NumberArray";

interface DecreaseModalProps {
  closeFunction: Function;
}

const DecreasePriceModal = ({ closeFunction }: DecreaseModalProps) => {
  const { DetailedRequestStore, UserStore } = usePersistentStore();
  const [comment, setComment] = useState("");
  const [newOfferedPrice, setNewOfferedPrice] = useState("");

  const handleSubmit = () => {
    const body = {
      prices: [
        {
          id: DetailedRequestStore.getPriceOfSelected().id,
          unit_agreed_price: Number(newOfferedPrice),
          unit_agreed_delivery_price:
            (DetailedRequestStore.getPriceOfSelected()
              .unit_agreed_delivery_price as number) -
            (DetailedRequestStore.getPriceOfSelected()
              .unit_agreed_price as number) +
            Number(newOfferedPrice),
          comment: comment,
          reason: "Decrease",
        },
      ],
    };
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };
    axios
      .post(`${BaseURL}/v1/contracts/decrease_and_increase/`, body, config)
      .then((res) => {
        // console.log(
        //   "🚀 ~ file: IncreasePriceModal.tsx:45 ~ .then ~ res",
        //   res.data
        // );
        closeFunction();
      })
      .catch((err) => {
        console.log("🚀 ~ file: IncreasePriceModal.tsx:47 ~ .then ~ err", err);
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
          handleSubmit();
        }
        // closeFunction();
      });
  };

  return (
    <ModalWrapper>
      <Container>
        <HeaderInModals>
          <TitleContainerInDetailedRequestModals>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0421 1.70117C5.47822 1.70117 1.74414 5.43525 1.74414 9.99913C1.74414 14.563 5.47822 18.2971 10.0421 18.2971C14.606 18.2971 18.3401 14.563 18.3401 9.99913C18.3401 5.43525 14.606 1.70117 10.0421 1.70117ZM13.1123 10.58L10.623 13.0694C10.54 13.1524 10.457 13.2353 10.374 13.2353C10.2081 13.3183 9.95912 13.3183 9.71018 13.2353C9.6272 13.2353 9.54422 13.1524 9.46124 13.0694L6.97185 10.58C6.63994 10.2481 6.63994 9.75019 6.97185 9.41827C7.30377 9.08636 7.80165 9.08636 8.13357 9.41827L9.2123 10.497V7.50974C9.2123 7.01187 9.54422 6.67995 10.0421 6.67995C10.54 6.67995 10.8719 7.01187 10.8719 7.50974V10.497L11.9506 9.41827C12.2825 9.08636 12.7804 9.08636 13.1123 9.41827C13.4443 9.75019 13.4443 10.2481 13.1123 10.58Z"
                fill="#0081F1"
              />
            </svg>

            <TitleInDetailedRequestModals>
              Снизить цену
            </TitleInDetailedRequestModals>
          </TitleContainerInDetailedRequestModals>
          <CloseButton closeFunction={() => closeFunction()} />
        </HeaderInModals>
        <ChangePriceContainer>
          <InputContainer>
            <BlackTitleInput>Исходная, руб/т</BlackTitleInput>
            <UncoloredInput>
              {DetailedRequestStore.getPriceOfSelected().unit_agreed_price}
            </UncoloredInput>
          </InputContainer>
          <InputContainer>
            <BlackTitleInput>Снизить до, руб/т</BlackTitleInput>
            <PriceInputTag
              min="0"
              type="number"
              value={newOfferedPrice}
              onKeyDown={(evt) =>
                (evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "e" ||
                  (newOfferedPrice.length === 1 &&
                    !NumberArray.includes(newOfferedPrice) &&
                    evt.key === "0")) &&
                evt.preventDefault()
              }
              onChange={(e) =>
                Number(e.target.value) > -1
                  ? Number(e.target.value) > 1
                    ? setNewOfferedPrice(e.target.value)
                    : setNewOfferedPrice(e.target.value)
                  : () => {}
              }
            ></PriceInputTag>
          </InputContainer>
          <InputContainer>
            <GreyLabelInput>Цена с доставкой, руб/т</GreyLabelInput>
            <UncoloredInput>
              {
                DetailedRequestStore.getPriceOfSelected()
                  .unit_agreed_delivery_price
              }
            </UncoloredInput>
          </InputContainer>
          <InputContainer>
            <GreyLabelInput>Цена с доставкой, руб/т</GreyLabelInput>
            <ColoredInput>
              {newOfferedPrice
                ? (DetailedRequestStore.getPriceOfSelected()
                    .unit_agreed_delivery_price as number) -
                  (DetailedRequestStore.getPriceOfSelected()
                    .unit_agreed_price as number) +
                  Number(newOfferedPrice)
                : 0}
            </ColoredInput>
          </InputContainer>
        </ChangePriceContainer>
        <Comments title="Причина" setChange={setComment} />
        <Button
          onClick={() => handleSubmit()}
          disabled={
            !newOfferedPrice ||
            Number(newOfferedPrice) >
              (DetailedRequestStore.getPriceOfSelected()
                .unit_agreed_price as number)
          }
        >
          Подтвердить
        </Button>
      </Container>
    </ModalWrapper>
  );
};

export default observer(DecreasePriceModal);

const Container = styled.div`
  align-self: center;
  padding: 20px;
  width: 410px;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  box-shadow: 0px 12px 32px -5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const Button = styled(ButtonPrimary)``;

const ChangePriceContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
`;

const PriceInputTag = styled(PriceInput)`
  height: 36px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
