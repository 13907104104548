import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ModalWrapper } from "../../../styles/ModalWrapper";
import styled from "styled-components";
import {
  HeaderInModals,
  TitleContainerInDetailedRequestModals,
  TitleInDetailedRequestModals,
} from "../../../styles/DetailedRequestModals/TitleInDetailedRequestModal";
import CloseButton from "../../Buttons/CloseButton";
import { ButtonPrimary } from "../../../styles/Buttons/ButtonPrimary";
import { InputContainer } from "../../../styles/DetailedRequestModals/InputContainer";
import { BlackTitleInput } from "../../../styles/DetailedRequestModals/BlackLabelInput";
import { UncoloredInput } from "../../../styles/DetailedRequestModals/UncoloredInput";
import { usePersistentStore } from "../../../store/RootStore";
import { PriceInput } from "../../../styles/DetailedRequestModals/PriceInput";
import { GreyLabelInput } from "../../../styles/DetailedRequestModals/GreyLabelInput";
import { ColoredInput } from "../../../styles/DetailedRequestModals/ColoredInput";
import Comments from "./Comments";
import axios from "axios";
import { BaseURL } from "../../../utils/BaseUrl";
import { NumberArray } from "../../../utils/NumberArray";

interface IncreasePriceProps {
  closeFunction: Function;
}

const IncreasePrice = ({ closeFunction }: IncreasePriceProps) => {
  const { DetailedRequestStore, UserStore } = usePersistentStore();
  const [comment, setComment] = useState("");
  const [newOfferedPrice, setNewOfferedPrice] = useState("");

  const handleSubmit = () => {
    const body = {
      prices: [
        {
          id: DetailedRequestStore.getPriceOfSelected().id,
          unit_agreed_price: Number(newOfferedPrice),
          unit_agreed_delivery_price:
            (DetailedRequestStore.getPriceOfSelected()
              .unit_agreed_delivery_price as number) -
            (DetailedRequestStore.getPriceOfSelected()
              .unit_agreed_price as number) +
            Number(newOfferedPrice),
          comment: comment,
          reason: "Increase",
        },
      ],
    };
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };
    axios
      .post(`${BaseURL}/v1/contracts/decrease_and_increase/`, body, config)
      .then((res) => {
        // console.log(
        //   "🚀 ~ file: IncreasePriceModal.tsx:45 ~ .then ~ res",
        //   res.data
        // );
        closeFunction();
      })
      .catch((err) => {
        console.log("🚀 ~ file: IncreasePriceModal.tsx:47 ~ .then ~ err", err);
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
          handleSubmit();
        }
        // closeFunction();
      });
  };

  return (
    <ModalWrapper onClick={() => closeFunction()}>
      <Container onClick={(e) => e.stopPropagation()}>
        <HeaderInModals>
          <TitleContainerInDetailedRequestModals>
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.8839 2.30273C6.32002 2.30273 2.58594 6.03682 2.58594 10.6007C2.58594 15.1646 6.32002 18.8987 10.8839 18.8987C15.4478 18.8987 19.1819 15.1646 19.1819 10.6007C19.1819 6.03682 15.4478 2.30273 10.8839 2.30273ZM13.9541 11.1815C13.6222 11.5135 13.1243 11.5135 12.7924 11.1815L11.7137 10.1028V13.0901C11.7137 13.588 11.3818 13.9199 10.8839 13.9199C10.386 13.9199 10.0541 13.588 10.0541 13.0901V10.1028L8.97537 11.1815C8.64345 11.5135 8.14557 11.5135 7.81365 11.1815C7.48173 10.8496 7.48173 10.3518 7.81365 10.0198L10.303 7.53045C10.635 7.19853 11.1328 7.19853 11.4648 7.53045L13.9541 10.0198C14.2861 10.3518 14.2861 10.8496 13.9541 11.1815Z"
                fill="#F2555A"
              />
            </svg>

            <TitleInDetailedRequestModals>
              Повысить цену
            </TitleInDetailedRequestModals>
          </TitleContainerInDetailedRequestModals>
          <CloseButton closeFunction={() => closeFunction()} />
        </HeaderInModals>
        <ChangePriceContainer>
          <InputContainer>
            <BlackTitleInput>Исходная, руб/т</BlackTitleInput>
            <UncoloredInput>
              {DetailedRequestStore.getPriceOfSelected().unit_agreed_price}
            </UncoloredInput>
          </InputContainer>
          <InputContainer>
            <BlackTitleInput>Повысить до, руб/т</BlackTitleInput>
            <PriceInputTag
              min="0"
              type="number"
              value={newOfferedPrice}
              onKeyDown={(evt) =>
                (evt.key === "-" ||
                  evt.key === "+" ||
                  evt.key === "e" ||
                  (newOfferedPrice.length === 1 &&
                    !NumberArray.includes(newOfferedPrice) &&
                    evt.key === "0")) &&
                evt.preventDefault()
              }
              onChange={(e) =>
                Number(e.target.value) > -1
                  ? Number(e.target.value) > 1
                    ? setNewOfferedPrice(e.target.value)
                    : setNewOfferedPrice(e.target.value)
                  : () => {}
              }
            />
          </InputContainer>
          <InputContainer>
            <GreyLabelInput>Цена с доставкой, руб/т</GreyLabelInput>
            <UncoloredInput>
              {DetailedRequestStore.getPriceOfSelected().unit_delivery_price}
            </UncoloredInput>
          </InputContainer>
          <InputContainer>
            <GreyLabelInput>Цена с доставкой, руб/т</GreyLabelInput>
            <ColoredInput>
              {newOfferedPrice
                ? (DetailedRequestStore.getPriceOfSelected()
                    .unit_agreed_delivery_price as number) -
                  (DetailedRequestStore.getPriceOfSelected()
                    .unit_agreed_price as number) +
                  Number(newOfferedPrice)
                : 0}
            </ColoredInput>
          </InputContainer>
        </ChangePriceContainer>
        <Comments title="Причина" setChange={setComment} isNecessary={true} />
        <Button
          onClick={() => handleSubmit()}
          disabled={
            comment === "" ||
            !newOfferedPrice ||
            Number(newOfferedPrice) <
              (DetailedRequestStore.getPriceOfSelected()
                .unit_agreed_price as number)
          }
        >
          Подтвердить
        </Button>
      </Container>
    </ModalWrapper>
  );
};

export default observer(IncreasePrice);

const Container = styled.div`
  align-self: center;
  padding: 20px;
  width: 410px;
  background: white;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  box-shadow: 0px 12px 32px -5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const Button = styled(ButtonPrimary)`
  width: 125px;
`;

const ChangePriceContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
`;

const PriceInputTag = styled(PriceInput)`
  height: 36px;
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
