import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DetailedRequestDropdown from "../DetailedRequest/DetailedRequestDropdown/DetailedRequestDropdown";
import { InputContainer } from "../../styles/DetailedRequestModals/InputContainer";
import { GreyLabelInput } from "../../styles/DetailedRequestModals/GreyLabelInput";
import ReactDatePicker, { registerLocale } from "react-datepicker";
import { usePersistentStore } from "../../store/RootStore";
import { ButtonSecondary } from "../../styles/Buttons/ButtonSecondary";
import { ButtonTertriary } from "../../styles/Buttons/ButtonTertriary";
import * as ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import { ColoredInput } from "../../styles/DetailedRequestModals/ColoredInput";
import axios from "axios";
import { BaseURL } from "../../utils/BaseUrl";
registerLocale("ru", ru);

interface AgroCultureInputProps {
  active: boolean;
}

export interface CargoClassbuttonInterface {
  active: boolean;
}

const NewRequestOffer = () => {
  const { NewRequestStore, SimpleDictionaryStore, UserStore } =
    usePersistentStore();

  const today = new Date();
  const [supplier, setSupplier] = useState(NewRequestStore.getCounterpartyId());
  const [supplierSearch, setSupplierSearch] = useState("");
  const [startDate, setStartDate] = useState(
    NewRequestStore.getContractStartDate()
  );
  const [endDate, setEndDate] = useState(NewRequestStore.getContractEndDate());
  const [product, setProduct] = useState(NewRequestStore.getAgroCultureType());
  const [productSearch, setProductSearch] = useState("");
  const [deliveryCondition, setDeliveryCondition] = useState(
    NewRequestStore.getDeliveryCondition()
  );
  const [deliveryConditionSearch, setDeliveryConditionSearch] = useState("");
  const [deliveryPlace, setDeliveryPlace] = useState(
    NewRequestStore.getDeparturePoint()
  );
  const [searchingDeliveryPlace, setSearchingDeliveryPlace] = useState("");
  const [comment, setComment] = useState(NewRequestStore.getComment());
  const [cargoClass, setCargoClass] = useState(NewRequestStore.getCargoClass());
  const [requestNumber, setRequestNumber] = useState("12341235");
  const [documentNumber, setDocumentNumber] = useState(
    NewRequestStore.getRequestNumber()
  );
  const [agroCultureTypes, setAgroCultureTypes] = useState<Array<string>>([]);

  useEffect(() => {
    setRequestNumber((Math.random() * 1000000000).toFixed(0));
  }, []);

  useEffect(() => {
    NewRequestStore.setCounterpartyId(supplier);
  }, [supplier]);

  useEffect(() => {
    NewRequestStore.setContractStartDate(startDate);
  }, [startDate]);

  useEffect(() => {
    NewRequestStore.setContractEndDate(endDate);
  }, [endDate]);

  useEffect(() => {
    NewRequestStore.setAgroCultureType(product);
  }, [product]);

  useEffect(() => {
    NewRequestStore.setDeliveryCondition(deliveryCondition);
  }, [deliveryCondition]);

  useEffect(() => {
    NewRequestStore.setDeparturePoint(deliveryPlace);
  }, [deliveryPlace]);

  useEffect(() => {
    NewRequestStore.setComment(comment);
  }, [comment]);

  useEffect(() => {
    NewRequestStore.setCargoClass(cargoClass);

    const getAgroCultures = () => {
      const config = {
        headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
      };

      const body = {
        cargo_class: NewRequestStore.getCargoClass(),
      };

      //   NewRequestStore.setAgroCultureType("");
      //   setProduct(NewRequestStore.getAgroCultureType());

      axios
        .post(`${BaseURL}/v1/sd/agro_culture_by_cargo/`, body, config)
        .then((res) => {
          //   console.log(
          //     "🚀 ~ file: NewRequestOffer.tsx:98 ~ .then ~ res:",
          //     res.data.map((e: any) => e.agro.record_value)
          //   );
          if (res) {
            setAgroCultureTypes(res.data.map((e: any) => e.agro.record_value));
          }
        })
        .catch((err) => {
          console.log(
            "🚀 ~ file: NewRequestOffer.tsx:101 ~ getAgroCultures ~ err:",
            err
          );
          if (err.response.data.code === "token_not_valid") {
            UserStore.updateToken();
            getAgroCultures();
          }
        });
    };

    !!NewRequestStore.getCargoClass() && getAgroCultures();
  }, [cargoClass]);

  useEffect(() => {
    NewRequestStore.setRequestNumber(documentNumber);
  }, [documentNumber]);

  return (
    <Container>
      <BlockContainer>
        <BlockTitle>
          Заявка №{requestNumber} от {today.toLocaleDateString()}
        </BlockTitle>
        <DetailedRequestDropdown
          title={"Контрагент"}
          items={SimpleDictionaryStore.getCounterPartyList()}
          placeholder={"Введите контрагента"}
          changeValueFunc={setSupplierSearch}
          changeSelectedValue={setSupplier}
          searchingValue={supplierSearch}
          selectedValue={supplier}
          longName={true}
        />
        <InputLine>
          <Input>
            <GreyLabelInput>Номер договора</GreyLabelInput>
            <RequestNumberInput
              placeholder={"24542145"}
              value={documentNumber}
              onChange={(e) => setDocumentNumber(e.target.value)}
            />
          </Input>
          <Input>
            <GreyLabelInput>Дата начала договора</GreyLabelInput>
            <ReactDatePicker
              value={startDate}
              onChange={(e) => setStartDate(e?.toLocaleDateString() as string)}
              className="date-picker-new-request"
              dateFormat="dd.MM.yyyy"
              maxDate={
                new Date(
                  endDate.split(".")[2] +
                    "-" +
                    endDate.split(".")[1] +
                    "-" +
                    endDate.split(".")[0]
                )
              }
              locale="ru"
            />
          </Input>
          <Input>
            <GreyLabelInput>Дата завершения договора</GreyLabelInput>
            <ReactDatePicker
              value={endDate}
              onChange={(e) => setEndDate(e?.toLocaleDateString() as string)}
              minDate={
                new Date(
                  startDate.split(".")[2] +
                    "-" +
                    startDate.split(".")[1] +
                    "-" +
                    startDate.split(".")[0]
                )
              }
              className="date-picker-new-request"
              dateFormat="dd.MM.yyyy"
              locale="ru"
            />
          </Input>
        </InputLine>
        <InputContainer>
          <GreyLabelInput>Карго-класс</GreyLabelInput>
          <CargoCalssLine>
            <OilButton
              active={cargoClass === "Масличные"}
              onClick={() => setCargoClass("Масличные")}
              disabled={NewRequestStore.getProducts().length !== 0}
            >
              Масличные
            </OilButton>
            <GrainButton
              active={cargoClass === "Зерновые"}
              onClick={() => setCargoClass("Зерновые")}
              disabled={NewRequestStore.getProducts().length !== 0}
            >
              Зерновые
            </GrainButton>
          </CargoCalssLine>
        </InputContainer>
      </BlockContainer>
      <BlockContainer>
        <BlockTitle>Товар</BlockTitle>
        <InputLine>
          {!!NewRequestStore.getCargoClass() &&
          NewRequestStore.getProducts().length === 0 ? (
            <Input>
              <DetailedRequestDropdown
                title={"Товары в заявке"}
                items={agroCultureTypes}
                placeholder={"Введите товар"}
                changeValueFunc={setProductSearch}
                changeSelectedValue={setProduct}
                searchingValue={productSearch}
                selectedValue={product}
              />
            </Input>
          ) : (
            <Input>
              <GreyLabelInput>Товары в заявке</GreyLabelInput>
              <AgroCultureInput
                active={!!NewRequestStore.getCargoClass()}
                style={{ minHeight: "36px" }}
              >
                {!!NewRequestStore.getCargoClass()
                  ? NewRequestStore.getAgroCultureType()
                  : "Выберите карго-класс"}
              </AgroCultureInput>
            </Input>
          )}
          <Input>
            <DetailedRequestDropdown
              title={"Условия поставки"}
              items={SimpleDictionaryStore.getDeliveryTermList()}
              placeholder={"Введите условие"}
              changeValueFunc={setDeliveryConditionSearch}
              changeSelectedValue={setDeliveryCondition}
              searchingValue={deliveryConditionSearch}
              selectedValue={deliveryCondition}
            />
          </Input>
          {NewRequestStore.getProducts().length === 0 ? (
            <Input>
              <DetailedRequestDropdown
                title={"Пункт доставки"}
                items={SimpleDictionaryStore.getPointList()}
                placeholder={"Введите пункт"}
                changeValueFunc={setSearchingDeliveryPlace}
                changeSelectedValue={setDeliveryPlace}
                searchingValue={searchingDeliveryPlace}
                selectedValue={deliveryPlace}
              />
            </Input>
          ) : (
            <Input>
              <GreyLabelInput>Пункт доставки</GreyLabelInput>
              <ColoredInput>
                {deliveryPlace.length > 25
                  ? `${deliveryPlace.slice(0, 25).trim()}...`
                  : deliveryPlace}
              </ColoredInput>
            </Input>
          )}
        </InputLine>
      </BlockContainer>
      <BlockContainer>
        <BlockTitle>Комментарий</BlockTitle>
        <TextArea
          value={comment}
          placeholder="Что еще важно знать об этой заявке?"
          onChange={(e) => setComment(e.target.value)}
        />
      </BlockContainer>
    </Container>
  );
};

export default observer(NewRequestOffer);

const Container = styled.div`
  width: 100%;
  max-width: 850px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const BlockContainer = styled.div`
  width: 100%;
  padding: 20px 20px 32px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;

  background: white;
  border-radius: 8px;
`;

const BlockTitle = styled.div`
  align-self: flex-start;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;

  color: var(--GrayLight12);
`;

const InputLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CargoCalssLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const OilButton = styled(ButtonTertriary)<CargoClassbuttonInterface>`
  width: 144px;
  border: 1px solid #f3f3f3;
  border-radius: 4px 0px 0px 4px;
  color: ${(props) => (props.active ? "#2F52BF" : "#171717")};

  background: ${(props) => (props.active ? "#F2F5FF" : "transparent")};
`;

const GrainButton = styled(ButtonTertriary)<CargoClassbuttonInterface>`
  width: 144px;
  border: 1px solid #f3f3f3;
  border-radius: 0px 4px 4px 0px;
  color: ${(props) => (props.active ? "#2F52BF" : "#171717")};

  background: ${(props) => (props.active ? "#F2F5FF" : "transparent")};
`;

const Input = styled(InputContainer)`
  width: 250px;
  height: 75px;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 100px;
  padding: 16px;

  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  border: 1px solid var(--GrayLight7);
  background: white;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: var(--GrayLight10);

  resize: none;

  &::placeholder {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: var(--GrayLight10);
  }
`;

const RequestNumberInput = styled.input`
  width: 100%;
  padding: 8px 8px 8px 16px;

  border: 1px solid var(--GrayLight3);
  border-radius: 4px;
  background: var(--GrayLight1);
  color: var(--GrayLight12);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &:focus {
    border-color: var(--darkBlue);
  }

  &::placeholder {
    color: var(--GrayLight9);
  }
`;

const AgroCultureInput = styled(ColoredInput)<AgroCultureInputProps>`
  color: ${(props) => (props.active ? "#171717" : "#7F2315")};
`;
