import { TableCell, TableHead, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { usePersistentStore } from "../../../../store/RootStore";

const NewRequestSampleQualityTableHeader = () => {
  const { SimpleDictionaryStore } = usePersistentStore();
  return (
    <StyledTableHead>
      <TableRow>
        <StyledTableCell />
        <StyledTableCell>
          <CellContainer>
            <div>Наименование</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Дата</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Пункт погрузки</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Хозяйство</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Кол-во тонн</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        {SimpleDictionaryStore.getGrainQualityParameterList().map(
          (e: string, i: number) => {
            return (
              <StyledTableCell>
                <CellContainer key={i}>
                  <div>{e}</div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                      fill="#858585"
                    />
                  </svg>
                </CellContainer>
              </StyledTableCell>
            );
          }
        )}
        {/* <StyledTableCell>
          <CellContainer>
            <div>Влажность %</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Сорная примесь %</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Вредная примесь %</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Класс</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Зараженность (шт. на 2 кг)</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Зерновая примесь %</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Клейковина</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>ИДК</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Протеин</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>ЧП, сек</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell>
        <StyledTableCell>
          <CellContainer>
            <div>Натура, г/л</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.354 12.3334L10.4373 14.25L8.52067 12.3334C8.18734 12 7.68734 12 7.354 12.3334C7.02067 12.6667 7.02067 13.1667 7.354 13.5L9.854 16C10.0207 16.1667 10.1873 16.25 10.4373 16.25C10.6873 16.25 10.854 16.1667 11.0207 16L13.5207 13.5C13.854 13.1667 13.854 12.6667 13.5207 12.3334C13.1873 12 12.6873 12 12.354 12.3334ZM8.52067 8.50004L10.4373 6.58337L12.354 8.50004C12.5207 8.66671 12.6873 8.75004 12.9373 8.75004C13.1873 8.75004 13.354 8.66671 13.5207 8.50004C13.854 8.16671 13.854 7.66671 13.5207 7.33337L11.0207 4.83337C10.6873 4.50004 10.1873 4.50004 9.854 4.83337L7.354 7.33337C7.02067 7.66671 7.02067 8.16671 7.354 8.50004C7.68734 8.83337 8.18734 8.83337 8.52067 8.50004Z"
                fill="#858585"
              />
            </svg>
          </CellContainer>
        </StyledTableCell> */}
      </TableRow>
    </StyledTableHead>
  );
};

export default observer(NewRequestSampleQualityTableHeader);

const StyledTableHead = styled(TableHead)`
  background: var(--GrayLight4);
  border-radius: 6px;
  border: unset !important;

  width: 1200px !important;
`;

const StyledTableCell = styled(TableCell)`
  color: #858585 !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: unset !important;
  white-space: nowrap;
`;

const CellContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #858585 !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: unset !important;

  &:hover {
    color: var(--blue) !important;
  }

  &:hover svg path {
    fill: var(--blue);
  }
`;
