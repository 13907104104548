import { Table, TableBody, TableContainer } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import styled from "styled-components";
import SampleQualityTableHead from "./SampleQualityTableHead";
import SampleQualityTableRow from "./SampleQualityTableRow";
import SampleQualityEditRowModal from "../../Modals/SampleQualityEditRowModal";
import { usePersistentStore } from "../../../../store/RootStore";
import { DetailedRequestProductInterface } from "../../../../store/DetailedRequestStore";

const SampleQualityTable = () => {
  const { DetailedRequestStore } = usePersistentStore();
  const [openEdit, setOpenEdit] = useState(false);

  const closeFunction = () => {
    DetailedRequestStore.removeAllSelection();
    setOpenEdit(false);
  };

  return (
    <StyledTableContainer>
      <Table>
        <SampleQualityTableHead />
        <StyledTableBody>
          {DetailedRequestStore.getProducts().map((e: any, i: number) => {
            return (
              <SampleQualityTableRow
                setOpenEdit={setOpenEdit}
                sampleQuality={e.sampleQuality}
                id={e.id}
                departurePoint={e.receiving_point_id}
                date={e.sampleQuality.created_at}
                key={i}
              />
            );
          })}
          {/* <SampleQualityTableRow setOpenEdit={setOpenEdit} /> */}
        </StyledTableBody>
      </Table>
      {openEdit && (
        <SampleQualityEditRowModal closeFunction={() => closeFunction()} />
      )}
    </StyledTableContainer>
  );
};

export default observer(SampleQualityTable);

const StyledTableContainer = styled(TableContainer)`
  border-radius: 8px;
  overflow-y: initial !important;
  width: 1150px;
`;

const StyledTableBody = styled(TableBody)`
  background: white;
  border-radius: 8px;
`;
