import styled from "styled-components";

export const ButtonPrimary = styled.button`
  width: 110px;
  height: 36px;
  background: var(--blue);
  border-radius: 8px;

  color: var(--TextWhite);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: var(--transition);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &:hover {
    background: var(--lightBlue);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
    cursor: unset;
  }

  &:active {
    background: var(--darkBlue);
  }
`;
