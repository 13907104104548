import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import CheckBox from "../../CheckBox";
import Status from "../../Status";

interface FilterStatusDropdownItemProps {
  name: string;
  allSelectionFunc: Function | null;
  changeStatusFunction?: Function;
  checkStatusFunc?: Function;
}

const FilterStatusDropdownItem = ({
  name,
  allSelectionFunc,
  changeStatusFunction,
  checkStatusFunc,
}: FilterStatusDropdownItemProps) => {
  const handleSelect = () => {
    changeStatusFunction && changeStatusFunction(name);
  };

  return (
    <Item
      onClick={() =>
        allSelectionFunc != null ? allSelectionFunc() : handleSelect()
      }
    >
      <CheckBox checked={checkStatusFunc && checkStatusFunc(name)} />
      <Status status={name} border={false} backgroundColor="transparent" />
    </Item>
  );
};

export default observer(FilterStatusDropdownItem);

const Item = styled.div`
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: var(--transition);

  &:hover {
    background: var(--GrayLight2);
  }

  &:hover div {
    color: var(--GrassLight11);
  }

  & div {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    transition: var(--transition);
  }
`;
