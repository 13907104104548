import styled from "styled-components";

export const UncoloredInput = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 8px 8px 16px;
  gap: 10px;

  border: 1px solid var(--GrayLight3);
  border-radius: 4px;
  background: var(--GrayLight1);
  color: var(--GrayLight9);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
