import styled from "styled-components";

export const BlockContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  border-radius: 8px;
  background: var(--GrayLight2);
`;
