import React from "react";
import styled from "styled-components";

function LoginLogo() {
  return <Logo src="/assets/Header/stackrus_logo.svg" alt="logo" />;
}

export default LoginLogo;

const Logo = styled.img`
  width: 365px;
  height: 103px;
`;
