import { types } from "mobx-state-tree";
import { string } from "mobx-state-tree/dist/internal";
import { SampleQualityArrayInterface } from "./DetailedRequestStore";

const PriceModel = types.model({
  unit_offered_price: types.number,
  unit_agreed_price: types.maybeNull(types.number),
  unit_delivery_price: types.number,
  unit_agreed_delivery_price: types.maybeNull(types.number),
  comment: types.maybeNull(types.string),
  start_at_version: types.maybeNull(types.string),
  end_at_version: types.maybeNull(types.Date),
  is_active_version: types.boolean,
  is_actual_now_version: types.boolean,
});

const RequestModel = types.model({
  request_number: types.optional(types.string, ""),
  counterparty_id: types.maybeNull(types.string),
  delivery_tern_id: types.optional(types.string, ""),
  departure_point: types.optional(types.string, ""),
  agro_culture_type: types.optional(types.string, ""),
  contract_start_date: types.optional(types.string, ""),
  contract_end_date: types.optional(types.string, ""),
  number: types.optional(types.string, ""),
  measure_id: types.optional(types.string, ""),
  contract_status_id: types.optional(types.string, "New"),
  price: types.array(PriceModel),
  isFavourite: types.optional(types.boolean, false),
  deliveryCondition: types.optional(types.string, ""),
  comment: types.optional(types.string, ""),
  cargoClass: types.optional(types.string, ""),
});

const QualityModal = types.model({
  key: types.optional(types.string, ""),
  value: types.optional(types.string, ""),
});

const ProductModel = types.model({
  uuid: types.optional(types.string, ""),
  agro_culture_type: types.optional(types.string, ""),
  measure_id: types.optional(types.string, ""),
  value: types.optional(types.number, 0),
  receiving_point_id: types.optional(types.string, ""),
  harvest_year: types.optional(types.number, 2022),
  price: types.optional(types.array(PriceModel), []),
  isSelected: types.optional(types.boolean, false),
  SampleQuality: types.optional(types.array(QualityModal), []),
  file_id: types.optional(types.maybeNull(types.number), null),
  fileName: types.optional(types.string, ""),
  sampleQualityDate: types.maybeNull(types.Date),
});

export const NewRequestStore = types
  .model({
    NewRequest: types.optional(RequestModel, {}),
    Products: types.optional(types.array(ProductModel), []),
    isOpenAddProduct: types.optional(types.boolean, false),
    SamplesQuality: types.optional(types.array(QualityModal), []),
  })
  .actions((self) => ({
    setCounterpartyId(counterParty: string | null) {
      self.NewRequest.counterparty_id = counterParty;
    },
    setAgroCultureType(agroCultureType: string) {
      self.NewRequest.agro_culture_type = agroCultureType;
    },
    setContractStartDate(contractStartDate: string) {
      self.NewRequest.contract_start_date = contractStartDate;
    },
    setContractEndDate(contractEndDate: string) {
      self.NewRequest.contract_end_date = contractEndDate;
    },
    setDeliveryCondition(deliveryCondition: string) {
      self.NewRequest.deliveryCondition = deliveryCondition;
    },
    setDeliveryTernId(deliveryTern: string) {
      self.NewRequest.delivery_tern_id = deliveryTern;
    },
    setComment(comment: string) {
      self.NewRequest.comment = comment;
    },
    setCargoClass(cargoClass: string) {
      self.NewRequest.cargoClass = cargoClass;
    },
    setDeparturePoint(departurePoint: string) {
      self.NewRequest.departure_point = departurePoint;
    },
    setRequestNumber(requestNumber: string) {
      self.NewRequest.request_number = requestNumber;
    },
    setClearNewRequest() {
      self.NewRequest.request_number = "";
      self.NewRequest.counterparty_id = "";
      self.NewRequest.delivery_tern_id = "";
      self.NewRequest.agro_culture_type = "";
      self.NewRequest.contract_start_date = "";
      self.NewRequest.contract_end_date = "";
      self.NewRequest.number = "";
      self.NewRequest.measure_id = "";
      self.NewRequest.contract_status_id = "New";
      self.NewRequest.isFavourite = false;
      self.NewRequest.deliveryCondition = "";
      self.NewRequest.comment = "";
      self.NewRequest.cargoClass = "";
      self.NewRequest.departure_point = "";
      self.NewRequest.price.replace([
        {
          unit_offered_price: 0,
          unit_agreed_price: 0,
          unit_delivery_price: 0,
          unit_agreed_delivery_price: 0,
          comment: "",
          start_at_version: "",
          end_at_version: new Date(),
          is_active_version: false,
          is_actual_now_version: false,
        },
      ]);
      self.Products.splice(0, self.Products.length);
      self.SamplesQuality.splice(0, self.SamplesQuality.length);
    },
    toggleAddProductModal(status: boolean) {
      self.isOpenAddProduct = status;
    },
    addSampleQuality() {
      self.SamplesQuality.push({
        key: "",
        value: "",
      });
    },
    removeSampleQulity(key: string, value: string) {
      let indexOf = self.SamplesQuality.findIndex(
        (e: any) => e.key === key && e.value === value
      );
      //   self.SamplesQuality.filter((e) => e.key !== key && e.value !== value);
      self.SamplesQuality.splice(indexOf, 1);
    },
    setSampleQulityKey(i: number, key: string) {
      self.SamplesQuality[i].key = key;
    },
    setSampleQulityValue(i: number, value: string) {
      self.SamplesQuality[i].value = value;
    },
    addProduct(
      payload: any,
      sampleQuality: Array<SampleQualityArrayInterface>
    ) {
      self.Products.push(payload);
      let indexOf = self.Products.findIndex((e) => e.uuid === payload.uuid);
      if (indexOf > -1) {
        self.Products[indexOf].SampleQuality.replace(sampleQuality);
      }
    },
    changeSelectionOnProduct(uuid: string) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1) {
        self.Products[indexOf].isSelected = !self.Products[indexOf].isSelected;
      }
    },
    removeAllSelections() {
      self.Products.forEach((e) => (e.isSelected = false));
    },
    clearProductSampleQuality(uuid: string) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      self.Products[indexOf].SampleQuality.splice(
        0,
        self.Products[indexOf].SampleQuality.length
      );
    },
    addNewQualityToProduct(
      uuid: string,
      sampleQuality: Array<SampleQualityArrayInterface>
    ) {
      if (sampleQuality.length === 0) {
        this.clearProductSampleQuality(uuid);
      }
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1) {
        self.Products[indexOf].SampleQuality.push({ key: "", value: "" });
      }
    },
    addQualityDate(uuid: string, date: Date | null) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1 && date) {
        self.Products[indexOf].sampleQualityDate = date;
      }
    },
    setProductFile(uuid: string, fileId: number, fileName: string) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1) {
        self.Products[indexOf].file_id = fileId;
        self.Products[indexOf].fileName = fileName;
      }
    },
    deleteProductFile(uuid: string) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1) {
        self.Products[indexOf].file_id = null;
        self.Products[indexOf].fileName = "";
      }
    },
    toggleSaveProduct(
      uuid: string,
      productName: string,
      year: number,
      mass: number,
      measure: string,
      offeredPrice: number,
      agreedPrice: number,
      deliveryPlace: string,
      offeredDeliveryPrice: number,
      agreedDeliveryPrice: number
    ) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1) {
        self.Products[indexOf].agro_culture_type = productName;
        self.Products[indexOf].harvest_year = year;
        self.Products[indexOf].value = mass;
        self.Products[indexOf].measure_id = measure;
        self.Products[indexOf].price[0].unit_offered_price = offeredPrice;
        self.Products[indexOf].price[0].unit_agreed_price = agreedPrice;
        self.Products[indexOf].price[0].unit_delivery_price =
          offeredDeliveryPrice;
        self.Products[indexOf].price[0].unit_agreed_delivery_price =
          agreedDeliveryPrice;
        self.Products[indexOf].receiving_point_id = deliveryPlace;
      }
    },
    toggleSaveSampleQuality(
      uuid: string,
      sampleQuality: Array<SampleQualityArrayInterface>
    ) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1) {
        self.Products[indexOf].SampleQuality.replace(sampleQuality);
      }
    },
    deleteProducts() {
      self.Products.replace(self.Products.filter((e) => !e.isSelected));
    },
  }))
  .views((self) => ({
    getCounterpartyId() {
      return self.NewRequest.counterparty_id;
    },
    getAgroCultureType() {
      return self.NewRequest.agro_culture_type;
    },
    getContractStartDate() {
      return self.NewRequest.contract_start_date;
    },
    getContractEndDate() {
      return self.NewRequest.contract_end_date;
    },
    getDeliveryCondition() {
      return self.NewRequest.deliveryCondition;
    },
    getDeliveryTernId() {
      return self.NewRequest.delivery_tern_id;
    },
    getComment() {
      return self.NewRequest.comment;
    },
    getCargoClass() {
      return self.NewRequest.cargoClass;
    },
    getDeparturePoint() {
      return self.NewRequest.departure_point;
    },
    getRequestNumber() {
      return self.NewRequest.request_number;
    },
    getCanSave() {
      return (
        !!self.NewRequest.counterparty_id &&
        self.NewRequest.agro_culture_type !== "" &&
        self.NewRequest.deliveryCondition !== "" &&
        self.NewRequest.departure_point !== "" &&
        self.NewRequest.cargoClass !== "" &&
        self.NewRequest.request_number !== ""
      );
    },
    getProducts() {
      return self.Products;
    },
    getOpenAddProductModal() {
      return self.isOpenAddProduct;
    },
    getSamplesQuality() {
      return self.SamplesQuality;
    },
    getSelected() {
      return self.Products.filter((e) => e.isSelected === true)[0];
    },
    getPriceOfSelected() {
      return self.Products.filter((e) => e.isSelected === true)[0].price[0];
    },
    getAmountOfSelected() {
      return self.Products.filter((e) => e.isSelected).length;
    },
    getProductFileId(uuid: string) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1) {
        return self.Products[indexOf].file_id;
      }
    },
    getProductFileName(uuid: string) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1) {
        return self.Products[indexOf].fileName;
      }
    },
    getSampleQualityDate(uuid: string) {
      let indexOf = self.Products.findIndex((e) => e.uuid === uuid);
      if (indexOf > -1) {
        return self.Products[indexOf].sampleQualityDate;
      }
    },
    getIsActiveProductsLink() {
      return (
        !!self.NewRequest.counterparty_id &&
        !!self.NewRequest.request_number &&
        !!self.NewRequest.cargoClass &&
        !!self.NewRequest.agro_culture_type &&
        !!self.NewRequest.departure_point &&
        !!self.NewRequest.deliveryCondition
      );
    },
  }));
