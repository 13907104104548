import { observer } from "mobx-react-lite";
import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

interface CommentsProps {
  title: string;
  isNecessary?: boolean;
  setChange: Dispatch<SetStateAction<string>>;
}

const Comments = ({ title, isNecessary, setChange }: CommentsProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChange(e.currentTarget.value);
  };
  return (
    <Container>
      <Title>
        <span>{title}</span>
        {isNecessary && (
          <span style={{ color: "rgba(229, 77, 46, 1)" }}>*</span>
        )}
      </Title>
      <Input onChange={(e) => handleChange(e)} />
    </Container>
  );
};

export default observer(Comments);

const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
`;

const Input = styled.textarea`
  width: 100%;
  height: 80px;

  border: 1px solid var(--GrayLight3);
  border-radius: 4px;
  background: var(--GrayLight1);

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  padding: 8px 8px 8px 16px;

  resize: none;
`;
