import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import TableDropdown from "../TableDropdowns/TableDropdown";
import styled from "styled-components";
import PriceDropdown from "../TableDropdowns/PriceDropdown";
import StatusDropdown from "../TableDropdowns/StatusDropdown";
import AmountDropdown from "../TableDropdowns/AmountDropdown";
import { usePersistentStore } from "../../../store/RootStore";

const RequestTableHead = () => {
  const { RequestStore, FiltersStore, SimpleDictionaryStore } =
    usePersistentStore();
  const [productNameSearch, setProductNameSearch] = useState("");
  const [supplierSearch, setSupplierSearch] = useState("");
  const [yearSearch, setYearSearch] = useState("");
  const [shipmentSearch, setShipmentSearch] = useState("");

  return (
    <StyledTableHead>
      <TableRow>
        <StyledTableCell />
        <TableDropdown
          label="Наименование"
          items={SimpleDictionaryStore.getAgroCultureTypeList()}
          placeholder="Введите продукт"
          changeValueFunc={setProductNameSearch}
          searchingValue={productNameSearch}
          changeStatusFunction={FiltersStore.changeAgrocultureTypeFilter}
          checkStatusFunc={FiltersStore.getAgrocultureTypeFilterStatus}
          resetFunction={FiltersStore.resetAgrocultureTypeFilter}
        />
        <TableDropdown
          label="Поставщик"
          items={RequestStore.getSuppliers()}
          placeholder="Введите название"
          changeValueFunc={setSupplierSearch}
          searchingValue={supplierSearch}
          changeStatusFunction={FiltersStore.changeSupplierFilter}
          checkStatusFunc={FiltersStore.getSupplierFilterStatus}
          resetFunction={FiltersStore.resetSupplierFilter}
        />
        <StyledTableCell>Номер</StyledTableCell>
        {/* <TableDropdown
          label="Статусы"
          items={["КФХ №1", "ООО Оптгрупп", "Первый зерновой", "Центртон"]}
          placeholder="Введите продукт"
        /> */}
        <StatusDropdown
          label="Статусы"
          items={[
            "NEW",
            "DRAFT",
            "SEND",
            "UPDATED",
            "FIXED",
            "DECLINED",
            "CONTRACT_ABORTED",
          ]}
          changeStatusFunction={FiltersStore.changeStatusFilter}
          checkStatusFunc={FiltersStore.getStatusFilterStatus}
          resetFunction={FiltersStore.resetStatusFilter}
        />
        <TableDropdown
          label="Урожай"
          items={RequestStore.getCropYears().reverse()}
          placeholder="Введите год"
          changeValueFunc={setYearSearch}
          searchingValue={yearSearch}
          changeStatusFunction={FiltersStore.changeHarvestYearFilter}
          checkStatusFunc={FiltersStore.getHarvestYearFilterStatus}
          resetFunction={FiltersStore.resetHarvestYearFilter}
        />
        {/* <StyledTableCell>Количество</StyledTableCell> */}
        <AmountDropdown />
        {/* <PriceDropdown
          label="Цена за т."
          setFunction={FiltersStore.setFilterByPriceTonn}
          removeFunction={FiltersStore.removeFilterByPriceTonn}
          minValueProp={FiltersStore.getPriceTonnMin()}
          maxValueProp={FiltersStore.getPriceTonnMax()}
        /> */}
        <PriceDropdown
          label="Цена, руб/т"
          setFunction={FiltersStore.setFilterByOfferedPrice}
          removeFunction={FiltersStore.removeFilterByOfferedPrice}
          minValueProp={FiltersStore.getOfferedMinPrice()}
          maxValueProp={FiltersStore.getOfferedMaxPrice()}
        />
        <PriceDropdown
          label="Согл. цена, руб/т"
          setFunction={FiltersStore.setFilterByAgreedPrice}
          removeFunction={FiltersStore.removeFilterByAgreedPrice}
          minValueProp={FiltersStore.getAgreedMinPrice()}
          maxValueProp={FiltersStore.getAgreedMaxPrice()}
        />
        <PriceDropdown
          label="Цена с дост., руб/т"
          setFunction={FiltersStore.setFilterByDeliveryPrice}
          removeFunction={FiltersStore.removeFilterByDeliveryPrice}
          minValueProp={FiltersStore.getDeliveryMinPrice()}
          maxValueProp={FiltersStore.getDeliveryMaxPrice()}
        />
        <PriceDropdown
          label="Согл цена с дост., руб/т"
          setFunction={FiltersStore.setFilterByAgreedDeliveryPrice}
          removeFunction={FiltersStore.removeFilterByAgreedDeliveryPrice}
          minValueProp={FiltersStore.getAgreedDeliveryMinPrice()}
          maxValueProp={FiltersStore.getAgreedDeliveryMaxPrice()}
        />
        <TableDropdown
          className="last-dropdown"
          label="Отгрузка"
          items={RequestStore.getShipment()}
          placeholder="Введите название"
          changeValueFunc={setShipmentSearch}
          searchingValue={shipmentSearch}
          changeStatusFunction={FiltersStore.changeShipmentFilter}
          checkStatusFunc={FiltersStore.getShipmentFilterStatus}
          resetFunction={FiltersStore.resetShipmentFilter}
        />
        <StyledTableCell />
      </TableRow>
    </StyledTableHead>
  );
};

export default observer(RequestTableHead);

const StyledTableHead = styled(TableHead)`
  background: var(--GrayLight4);
  border-radius: 6px;
  border: unset !important;
`;

const StyledTableCell = styled(TableCell)`
  color: #858585 !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: unset !important;
`;
