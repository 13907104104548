export const MathchSampleQualityHeaders = (header: string) => {
  switch (header) {
    case "humidity":
      return "Влажность, %";
    case "infection":
      return "Зараженность, %";
    case "protein":
      return "Белок, %";
    case "viability_for_tetrazole":
      return "Жизнеспособность по тетразолу, %";
    case "grain_admixture":
      return "Зерновая примесь, %";
    case "gluten":
      return "Клейковина";
    case "large_grain":
      return "Крупное зерно, %";
    case "oil_impurity":
      return "Масляничная примесь,%";
    case "small_grain":
      return "Мелкое зерно, %";
    case "nature":
      return "Натура";
    case "damaged_grains":
      return "Поврежденные зерна, %";
    case "weed_impurity":
      return "Сорная примесь, %";
    case "purity":
      return "Чистота";
    case "indicator_of_a_amylase_activity":
      return "ЧП";
    case "gluten_deformation_index":
      return "ИДК";
    case "oil_content":
      return "Масличность, %";
    case "id":
      return "id";

    case "Влажность, %":
      return "humidity";
    case "Зараженность, %":
      return "infection";
    case "Белок, %":
      return "protein";
    case "Жизнеспособность по тетразолу, %":
      return "viability_for_tetrazole";
    case "Зерновая примесь, %":
      return "grain_admixture";
    case "Клейковина":
      return "gluten";
    case "Крупное зерно, %":
      return "large_grain";
    case "Масляничная примесь,%":
      return "oil_impurity";
    case "Мелкое зерно, %":
      return "small_grain";
    case "Натура":
      return "nature";
    case "Поврежденные зерна, %":
      return "damaged_grains";
    case "Сорная примесь, %":
      return "weed_impurity";
    case "Чистота":
      return "purity";
    case "ЧП":
      return "indicator_of_a_amylase_activity";
    case "ИДК":
      return "gluten_deformation_index";
    case "Масличность, %":
      return "oil_content";
  }
  return "";
};
