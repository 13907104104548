import React from "react";
import styled from "styled-components";

interface TitleProps {
  title: string;
}

function Title({ title }: TitleProps) {
  return <TitleContainer>{title}</TitleContainer>;
}

export default Title;

const TitleContainer = styled.div`
  width: 100%;
  margin: 32px 0px 21px;
  display: flex;
  align-items: flex-start;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: var(--SageLight12);
`;

