import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckBox from "../../CheckBox";

interface DropdownItemProps {
  name: string;
  allSelectionFunc: Function | null;
  changeStatusFunction?: Function;
  checkStatusFunc?: Function;
}

const DropdownItem = ({
  name,
  allSelectionFunc,
  changeStatusFunction,
  checkStatusFunc,
}: DropdownItemProps) => {
  const handleSelect = () => {
    changeStatusFunction && changeStatusFunction(name);
  };

  return (
    <Item
      onClick={() =>
        allSelectionFunc != null ? allSelectionFunc() : handleSelect()
      }
    >
      <CheckBox checked={checkStatusFunc && checkStatusFunc(name)} />
      <ItemName>{name}</ItemName>
    </Item>
  );
};

export default observer(DropdownItem);

const ItemName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);
  transition: var(--transition);
`;

const Item = styled.div`
  cursor: pointer;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: var(--transition);

  &:hover {
    background: var(--GrayLight2);
  }

  &:hover ${ItemName} {
    color: var(--blue);
  }
`;
