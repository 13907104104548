import { types } from "mobx-state-tree";
import { RequestInterface } from "./RequestStore";
import { DetailedRequestProductInterface } from "./DetailedRequestStore";

const FilterPriceModel = types.model({
  low: types.string,
  high: types.string,
});

const AmountModel = types.model({
  low: types.number,
  high: types.number,
  lowType: types.string,
  highType: types.string,
});

export const FilterDetailedRequestStore = types
  .model({
    OfferedPrice: types.optional(FilterPriceModel, {
      low: "",
      high: "",
    }),
    AgreedPrice: types.optional(FilterPriceModel, {
      low: "",
      high: "",
    }),
    DeliveryPrice: types.optional(FilterPriceModel, {
      low: "",
      high: "",
    }),
    AgreedDeliveryPrice: types.optional(FilterPriceModel, {
      low: "",
      high: "",
    }),
    HarvestYear: types.optional(types.array(types.string), []),
    Amount: types.optional(AmountModel, {
      low: 0,
      high: 0,
      lowType: "кг",
      highType: "кг",
    }),
    AgroCultureType: types.optional(types.array(types.string), []),
  })
  .actions((self) => ({
    setFilterByOfferedPrice(low: string, high: string) {
      self.OfferedPrice.low = low;
      self.OfferedPrice.high = high;
    },
    removeFilterByOfferedPrice() {
      self.OfferedPrice.low = "";
      self.OfferedPrice.high = "";
    },
    setFilterByAgreedPrice(low: string, high: string) {
      self.AgreedPrice.low = low;
      self.AgreedPrice.high = high;
    },
    removeFilterByAgreedPrice() {
      self.AgreedPrice.low = "";
      self.AgreedPrice.high = "";
    },
    setFilterByDeliveryPrice(low: string, high: string) {
      self.DeliveryPrice.low = low;
      self.DeliveryPrice.high = high;
    },
    removeFilterByDeliveryPrice() {
      self.DeliveryPrice.low = "";
      self.DeliveryPrice.high = "";
    },
    setFilterByAgreedDeliveryPrice(low: string, high: string) {
      self.AgreedDeliveryPrice.low = low;
      self.AgreedDeliveryPrice.high = high;
    },
    removeFilterByAgreedDeliveryPrice() {
      self.AgreedDeliveryPrice.low = "";
      self.AgreedDeliveryPrice.high = "";
    },
    changeHarvestYearFilter(harvestYear: string) {
      let indexOf = self.HarvestYear.findIndex((e) => e === harvestYear);
      if (indexOf > -1) {
        self.HarvestYear.splice(indexOf, 1);
      } else {
        self.HarvestYear.push(harvestYear);
      }
    },
    resetHarvestYearFilter() {
      self.HarvestYear.splice(0, self.HarvestYear.length);
    },
    setFilterByAmount(
      low: number,
      high: number,
      lowType: string,
      highType: string
    ) {
      self.Amount.low = low;
      self.Amount.high = high;
      self.Amount.lowType = lowType;
      self.Amount.highType = highType;
    },
    removeFilterByAmount() {
      self.Amount.low = 0;
      self.Amount.high = 0;
      self.Amount.lowType = "кг";
      self.Amount.highType = "кг";
    },
    changeAgrocultureTypeFilter(agrocultureType: string) {
      let indexOf = self.AgroCultureType.findIndex(
        (e) => e === agrocultureType
      );
      if (indexOf > -1) {
        self.AgroCultureType.splice(indexOf, 1);
      } else {
        self.AgroCultureType.push(agrocultureType);
      }
    },
    resetAgrocultureTypeFilter() {
      self.AgroCultureType.splice(0, self.AgroCultureType.length);
    },
  }))
  .views((self) => ({
    getOfferedMinPrice() {
      return self.OfferedPrice.low;
    },
    getOfferedMaxPrice() {
      return self.OfferedPrice.high;
    },
    getAgreedMinPrice() {
      return self.AgreedPrice.low;
    },
    getAgreedMaxPrice() {
      return self.AgreedPrice.high;
    },
    getDeliveryMinPrice() {
      return self.DeliveryPrice.low;
    },
    getDeliveryMaxPrice() {
      return self.DeliveryPrice.high;
    },
    getAgreedDeliveryMinPrice() {
      return self.AgreedDeliveryPrice.low;
    },
    getAgreedDeliveryMaxPrice() {
      return self.AgreedDeliveryPrice.high;
    },
    getHarvestYearFilterStatus(year: string) {
      return self.HarvestYear.includes(year);
    },
    getAmountMin() {
      return self.Amount.low;
    },
    getAmountMax() {
      return self.Amount.high;
    },
    getAmountValueMin() {
      return self.Amount.lowType;
    },
    getAmountValueMax() {
      return self.Amount.highType;
    },
    getAgrocultureTypeFilterStatus(agrocultureType: string) {
      return self.AgroCultureType.includes(agrocultureType);
    },
    FilteredData(payload: Array<any>) {
      let filteredData = payload;

      // filter for unit_offered_price
      if (self.OfferedPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_offered_price) >
            Number(self.OfferedPrice.low)
        );
      }
      if (self.OfferedPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_offered_price) <
            Number(self.OfferedPrice.high)
        );
      }

      // filter for unit_agreed_price
      if (self.AgreedPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_agreed_price) > Number(self.AgreedPrice.low)
        );
      }
      if (self.AgreedPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_agreed_price) < Number(self.AgreedPrice.high)
        );
      }

      //filter for unit_delivery_price
      if (self.DeliveryPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_delivery_price) >
            Number(self.DeliveryPrice.low)
        );
      }
      if (self.DeliveryPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_delivery_price) <
            Number(self.DeliveryPrice.high)
        );
      }

      //-----filter for agrocultureType----///
      if (self.AgroCultureType.length !== 0) {
        filteredData = filteredData.filter((e: RequestInterface) =>
          self.AgroCultureType.includes(e.agro_culture_type)
        );
      }

      //-----filter for unit_agreed_delivery_price------//
      if (self.AgreedDeliveryPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_agreed_delivery_price) >
            Number(self.AgreedDeliveryPrice.low)
        );
      }
      if (self.AgreedDeliveryPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_agreed_delivery_price) <
            Number(self.AgreedDeliveryPrice.high)
        );
      }

      //-----filter for showing data using tabs above table------//

      //-----filter  for harvest year------//
      if (self.HarvestYear.length !== 0) {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            self.HarvestYear.includes(String(e.harvest_year))
        );
      }

      //---filter for value---///
      if (self.Amount.low !== 0) {
        filteredData = filteredData.filter((e: any) => {
          if (self.Amount.lowType === "кг" && e.measure_id === "Килограмм") {
            return e.value > self.Amount.low;
          } else if (
            self.Amount.lowType === "т" &&
            e.measure_id === "Килограмм"
          ) {
            return e.value > self.Amount.low * 1000;
          } else if (self.Amount.lowType === "кг" && e.measure_id === "Тонна") {
            return e.value * 1000 > self.Amount.low;
          } else {
            return e.value * 1000 > self.Amount.low * 1000;
          }
        });
      }

      if (self.Amount.high !== 0) {
        filteredData = filteredData.filter((e: any) => {
          if (self.Amount.highType === "т" && e.measure_id === "Тонна") {
            return e.value * 1000 < self.Amount.high * 1000;
          } else if (
            self.Amount.highType === "кг" &&
            e.measure_id === "Тонна"
          ) {
            return e.value * 1000 < self.Amount.high;
          } else if (
            self.Amount.highType === "т" &&
            e.measure_id === "Килограмм"
          ) {
            return e.value < self.Amount.high * 1000;
          } else {
            return e.value < self.Amount.high;
          }
        });
      }

      return filteredData;
    },
    FilteredNewRequestData(payload: any) {
      let filteredData = payload;

      // filter for unit_offered_price
      if (self.OfferedPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_offered_price) >
            Number(self.OfferedPrice.low)
        );
      }
      if (self.OfferedPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_offered_price) <
            Number(self.OfferedPrice.high)
        );
      }

      // filter for unit_agreed_price
      if (self.AgreedPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_agreed_price) > Number(self.AgreedPrice.low)
        );
      }
      if (self.AgreedPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_agreed_price) < Number(self.AgreedPrice.high)
        );
      }

      //filter for unit_delivery_price
      if (self.DeliveryPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_delivery_price) >
            Number(self.DeliveryPrice.low)
        );
      }
      if (self.DeliveryPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_delivery_price) <
            Number(self.DeliveryPrice.high)
        );
      }

      //-----filter for unit_agreed_delivery_price------//
      if (self.AgreedDeliveryPrice.low !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_agreed_delivery_price) >
            Number(self.AgreedDeliveryPrice.low)
        );
      }
      if (self.AgreedDeliveryPrice.high !== "") {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            Number(e.price[0].unit_agreed_delivery_price) <
            Number(self.AgreedDeliveryPrice.high)
        );
      }

      //-----filter for showing data using tabs above table------//

      //-----filter  for harvest year------//
      if (self.HarvestYear.length !== 0) {
        filteredData = filteredData.filter(
          (e: DetailedRequestProductInterface) =>
            self.HarvestYear.includes(String(e.harvest_year))
        );
      }

      //---filter for value---///
      if (self.Amount.low !== 0) {
        filteredData = filteredData.filter((e: any) => {
          if (self.Amount.lowType === "кг" && e.measure_id === "Килограмм") {
            return e.value > self.Amount.low;
          } else if (
            self.Amount.lowType === "т" &&
            e.measure_id === "Килограмм"
          ) {
            return e.value > self.Amount.low * 1000;
          } else if (self.Amount.lowType === "кг" && e.measure_id === "Тонна") {
            return e.value * 1000 > self.Amount.low;
          } else {
            return e.value * 1000 > self.Amount.low * 1000;
          }
        });
      }

      if (self.Amount.high !== 0) {
        filteredData = filteredData.filter((e: any) => {
          if (self.Amount.highType === "т" && e.measure_id === "Тонна") {
            return e.value * 1000 < self.Amount.high * 1000;
          } else if (
            self.Amount.highType === "кг" &&
            e.measure_id === "Тонна"
          ) {
            return e.value * 1000 < self.Amount.high;
          } else if (
            self.Amount.highType === "т" &&
            e.measure_id === "Килограмм"
          ) {
            return e.value < self.Amount.high * 1000;
          } else {
            return e.value < self.Amount.high;
          }
        });
      }

      return filteredData;
    },
    clearFilters() {
      self.removeFilterByAgreedDeliveryPrice();
      self.removeFilterByAgreedPrice();
      self.removeFilterByAmount();
      self.removeFilterByDeliveryPrice();
      self.removeFilterByOfferedPrice();
      self.resetHarvestYearFilter();
    },
  }));
