import { observer } from "mobx-react-lite";
import React from "react";
import { Wrapper } from "../../styles/Wrapper";
import LoginLogo from "../../components/LoginLogo";
import { LoginContainer } from "../../styles/Login/LoginContainer";
import styled from "styled-components";
import { LoginTitle } from "../../styles/Login/LoginTitle";
import { LoginSubtitle } from "../../styles/Login/LoginSubtitle";
import { FormContainer } from "../../styles/Login/FormContainer";
import { Link, useNavigate } from "react-router-dom";

function LoginEror() {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <LoginContainer>
        <LoginLogo />
        <FormContainer onSubmit={(e) => e.preventDefault()}>
          <Img src="/assets/Login/Mail_orange.svg" alt="email" />
          <LoginTitle>
            Электронная почта не зарегистрирована в системе
          </LoginTitle>
          <LoginSubtitle>
            Обратитесь к администратору платформы по номеру телефона <a></a>
            <SmallLink href="tel:+7989-123-45-67" target="_blank">
              +7(989)123-45-67
            </SmallLink>{" "}
            или отправьте запрос на почту{" "}
            <SmallLink href="mailto:ssupport@b5g.ru" target="_blank">
              support@b5g.ru
            </SmallLink>
          </LoginSubtitle>
          <Button onClick={() => navigate("/login")}>
            <Link to="/login">Войти с другой почтой</Link>
          </Button>
        </FormContainer>
      </LoginContainer>
    </Wrapper>
  );
}

export default observer(LoginEror);

const Img = styled.img`
  width: 65px;
  height: 65px;
`;

const SmallLink = styled.a`
  color: var(--blue);
  transition: var(--transition);

  &:hover {
    text-decoration: underline;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 56px;
  background: var(--lightBlue3);
  font-family: "Inter";
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  border-radius: 8px;
  transition: var(--transition);

  & a {
    color: var(--darkBlue);
  }

  &:hover {
    background: var(--lightBlue2);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    background: white;
  }
`;
