import { Table, TableBody, TableContainer } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DetailedRequestTableHead from "./DetailedRequestTableHead";
import { usePersistentStore } from "../../../store/RootStore";
import DetailedRequestTableRow from "./DetailedRequestTableRow";
import ChangePriceModal from "../Modals/ChangePriceModal";
import ProductEditModal from "../Modals/ProductEditModal";
import { DetailedRequestProductInterface } from "../../../store/DetailedRequestStore";
import { Oval } from "react-loader-spinner";
import axios, { AxiosResponse } from "axios";
import { BaseURL } from "../../../utils/BaseUrl";
import { autorun } from "mobx";

const DetailedRequestTable = () => {
  const { DetailedRequestStore, FiltersDetailedRequestStore, UserStore } =
    usePersistentStore();
  const [openChangePriceModal, setOpenChangePriceModal] = useState(false);
  const [openEditProdcutModal, setOpenEditProductModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleCloseChangePriceModal = () => {
    setOpenChangePriceModal(false);
    DetailedRequestStore.removeAllSelection();
  };

  const handleCloseEditProductModal = () => {
    setOpenEditProductModal(false);
    DetailedRequestStore.removeAllSelection();
  };

  useEffect(
    () =>
      autorun(() => {
        setLoading(true);
        const body = {
          contract_id: DetailedRequestStore.getUUID(),
        };
        const config = {
          headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
        };

        axios
          .post(`${BaseURL}/v1/contracts/get_contract_units/`, body, config)
          .then((res: AxiosResponse<any>) => {
            // console.log("🚀 ~ file: DetailedRequest.tsx:49 ~ .then ~ res", res);
            DetailedRequestStore.setProducts(
              res.data.map((e: any) => {
                return e.product;
              })
            );
            setLoading(false);
          })
          .catch((err) => {
            console.log("🚀 ~ file: DetailedRequest.tsx:52 ~ .then ~ err", err);
            if (err.response.data.code === "token_not_valid") {
              UserStore.updateToken();
            }
          });
      }),
    []
  );

  return (
    <StyledTableContainer>
      {!loading ? (
        <Table>
          <DetailedRequestTableHead />
          <StyledTableBody>
            {FiltersDetailedRequestStore.FilteredData(
              DetailedRequestStore.getProducts()
            ).map((e: DetailedRequestProductInterface, i: number) => {
              return (
                <DetailedRequestTableRow
                  row={e}
                  key={i}
                  openChangePriceModal={() => setOpenChangePriceModal(true)}
                  openEditProductModal={() => setOpenEditProductModal(true)}
                  sampleQuality={e.sampleQuality}
                />
              );
            })}
          </StyledTableBody>
        </Table>
      ) : (
        <OvalContainer>
          <Oval
            ariaLabel="loading-indicator"
            height={100}
            width={100}
            strokeWidth={5}
            strokeWidthSecondary={3}
            color="#2F52BF"
            secondaryColor="rgba(139, 187, 75, 0)"
          />
        </OvalContainer>
      )}
      {openChangePriceModal && (
        <ChangePriceModal closeFunction={() => handleCloseChangePriceModal()} />
      )}
      {openEditProdcutModal && (
        <ProductEditModal closeFunction={() => handleCloseEditProductModal()} />
      )}
    </StyledTableContainer>
  );
};

export default observer(DetailedRequestTable);

const StyledTableContainer = styled(TableContainer)`
  border-radius: 8px;
  overflow: initial !important;
`;

const StyledTableBody = styled(TableBody)`
  background: white;
  border-radius: 8px;
`;

const OvalContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
`;
