import { TableCell, TableRow } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import CheckBox from "../../CheckBox";
import { DetailedRequestProductInterface } from "../../../store/DetailedRequestStore";
import { usePersistentStore } from "../../../store/RootStore";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import AddSampleQualityModal from "../Modals/AddSampleQualityModal";
import { ButtonTertriary } from "../../../styles/Buttons/ButtonTertriary";
import { MathchSampleQualityHeaders } from "../../../utils/MatchSampleQualityHeaders";
import { autorun } from "mobx";

interface DetailedRequestTableRowProps {
  row: any;
  openChangePriceModal: Function;
  openEditProductModal: Function;
  sampleQuality: any;
}

interface TableRowProps {
  checked: boolean;
}

const DetailedRequestTableRow = ({
  row,
  openChangePriceModal,
  openEditProductModal,
  sampleQuality,
}: DetailedRequestTableRowProps) => {
  const { DetailedRequestStore, UserStore } = usePersistentStore();
  const [openSampleQualityModal, setOpenSampleQualityModal] = useState(false);
  const [sampleQualityString, setSampleQualityString] = useState("");

  //   console.log(
  //     "🚀 ~ file: DetailedRequestTableRow.tsx:13 ~ DetailedRequestTableRow ~ row",
  //     row
  //   );

  const handleChangePrice = (id: number) => {
    DetailedRequestStore.removeAllSelection();
    DetailedRequestStore.changeSelectionOnProduct(id);
    openChangePriceModal();
  };

  const handleOpenModal = (id: number) => {
    DetailedRequestStore.removeAllSelection();
    DetailedRequestStore.changeSelectionOnProduct(id);
    openEditProductModal();
  };

  const handleOpenSampleQualityModal = (id: number) => {
    DetailedRequestStore.removeAllSelection();
    DetailedRequestStore.changeSelectionOnProduct(id);
    setOpenSampleQualityModal(true);
  };

  const handleCloseOpenSampleQualityModal = () => {
    setOpenSampleQualityModal(false);
    DetailedRequestStore.removeAllSelection();
  };

  useEffect(
    () =>
      autorun(() => {
        let newSampleQualityString = "";
        for (const [key, value] of Object.entries(sampleQuality)) {
          if (
            value &&
            key !== "id" &&
            key !== "last_update" &&
            key !== "is_actual_version" &&
            key !== "comment_for_take" &&
            key !== "created_at"
          ) {
            newSampleQualityString = newSampleQualityString.concat(
              `${MathchSampleQualityHeaders(key).slice(0, 3)} = ${value} |`
            );
          }
        }
        setSampleQualityString(newSampleQualityString);
        // console.log(
        //   "🚀 ~ file: DetailedRequestTableRow.tsx:75 ~ useEffect ~ newSampleQualityString",
        //   newSampleQualityString
        // );
        // console.log(
        //   "🚀 ~ files: DetailedRequestTableRow.tsx:64 ~ useEffect ~ sampleQualitString",
        //   newSampleQualityString
        // );
      }),
    [row]
  );

  return (
    <StyledTableRow checked={row.isSelected}>
      <FirstStyledCell
        onClick={() => DetailedRequestStore.changeSelectionOnProduct(row.id)}
      >
        <CheckBox checked={row.isSelected} />
      </FirstStyledCell>
      <StyledCell>
        <TableCellContainer>
          <NameTitle>{row.agro_culture_type.split(" ")[0]}</NameTitle>
          {/* <NameSubtitle>13,5-14,4% (23-24%)</NameSubtitle> */}
        </TableCellContainer>
      </StyledCell>
      <TextRightCell>
        {row.value} {row.measure_id}
      </TextRightCell>
      <TextRightCell>{row.harvest_year}</TextRightCell>
      <TextRightCell>{row.price[0]?.unit_offered_price}</TextRightCell>
      <TextRightCell>
        <TextRightUnderlinedCell
          onClick={() =>
            DetailedRequestStore.getStatus() !== "FIXED" &&
            DetailedRequestStore.getStatus() !== "CONTRACT_ABORTED" &&
            DetailedRequestStore.getStatus() !== "DECLINED" &&
            DetailedRequestStore.getStatus() !== "FINISHED"
              ? handleChangePrice(row.id)
              : () => {}
          }
        >
          {row.price_for_approve[0]?.reason !== null
            ? row.price[0]?.unit_agreed_price
            : row.price_for_approve[0]?.unit_agreed_price}
          {row.price_for_approve?.length !== 0 &&
            row.price[0]?.reason !== "Approve" &&
            (row.price_for_approve[row.price_for_approve?.length - 1]
              ?.reason === "Decrease" ? (
              <svg
                id={`${row.price[0].id}-decrease`}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0421 1.70117C5.47822 1.70117 1.74414 5.43525 1.74414 9.99913C1.74414 14.563 5.47822 18.2971 10.0421 18.2971C14.606 18.2971 18.3401 14.563 18.3401 9.99913C18.3401 5.43525 14.606 1.70117 10.0421 1.70117ZM13.1123 10.58L10.623 13.0694C10.54 13.1524 10.457 13.2353 10.374 13.2353C10.2081 13.3183 9.95912 13.3183 9.71018 13.2353C9.6272 13.2353 9.54422 13.1524 9.46124 13.0694L6.97185 10.58C6.63994 10.2481 6.63994 9.75019 6.97185 9.41827C7.30377 9.08636 7.80165 9.08636 8.13357 9.41827L9.2123 10.497V7.50974C9.2123 7.01187 9.54422 6.67995 10.0421 6.67995C10.54 6.67995 10.8719 7.01187 10.8719 7.50974V10.497L11.9506 9.41827C12.2825 9.08636 12.7804 9.08636 13.1123 9.41827C13.4443 9.75019 13.4443 10.2481 13.1123 10.58Z"
                  fill="#0081F1"
                />
              </svg>
            ) : (
              row.price_for_approve[row.price_for_approve?.length - 1]
                ?.reason === "Increase" && (
                <svg
                  id={`${row.price[0].id}-increase`}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.8839 2.30273C6.32002 2.30273 2.58594 6.03682 2.58594 10.6007C2.58594 15.1646 6.32002 18.8987 10.8839 18.8987C15.4478 18.8987 19.1819 15.1646 19.1819 10.6007C19.1819 6.03682 15.4478 2.30273 10.8839 2.30273ZM13.9541 11.1815C13.6222 11.5135 13.1243 11.5135 12.7924 11.1815L11.7137 10.1028V13.0901C11.7137 13.588 11.3818 13.9199 10.8839 13.9199C10.386 13.9199 10.0541 13.588 10.0541 13.0901V10.1028L8.97537 11.1815C8.64345 11.5135 8.14557 11.5135 7.81365 11.1815C7.48173 10.8496 7.48173 10.3518 7.81365 10.0198L10.303 7.53045C10.635 7.19853 11.1328 7.19853 11.4648 7.53045L13.9541 10.0198C14.2861 10.3518 14.2861 10.8496 13.9541 11.1815Z"
                    fill="#F2555A"
                  />
                </svg>
              )
            ))}
          {row.price[0]?.reason === "Approve" && (
            <svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.6046 1.70117C6.04072 1.70117 2.30664 5.43525 2.30664 9.99913C2.30664 14.563 6.04072 18.2971 10.6046 18.2971C15.1685 18.2971 18.9026 14.563 18.9026 9.99913C18.9026 5.43525 15.1685 1.70117 10.6046 1.70117ZM14.0897 8.58848L10.1067 12.5715C9.7748 12.9034 9.27693 12.9034 8.94501 12.5715L7.11946 10.7459C6.78754 10.414 6.78754 9.91615 7.11946 9.58423C7.45138 9.25231 7.94925 9.25231 8.28117 9.58423L9.52586 10.8289L12.928 7.42676C13.2599 7.09484 13.7578 7.09484 14.0897 7.42676C14.4217 7.75868 14.4217 8.25656 14.0897 8.58848Z"
                fill="#3D9A50"
              />
            </svg>
          )}
          {row.price_for_approve[0]?.reason === null &&
            row.price[0]?.reason !== "Approve" && (
              <svg
                id={`${row.price[0].id}-approve`}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0421 1.70117C5.47822 1.70117 1.74414 5.43525 1.74414 9.99913C1.74414 14.563 5.47822 18.2971 10.0421 18.2971C14.606 18.2971 18.3401 14.563 18.3401 9.99913C18.3401 5.43525 14.606 1.70117 10.0421 1.70117ZM10.0421 14.1481C9.54422 14.1481 9.2123 13.8162 9.2123 13.3183C9.2123 12.8204 9.54422 12.4885 10.0421 12.4885C10.54 12.4885 10.8719 12.8204 10.8719 13.3183C10.8719 13.8162 10.54 14.1481 10.0421 14.1481ZM10.8719 9.99913C10.8719 10.497 10.54 10.8289 10.0421 10.8289C9.54422 10.8289 9.2123 10.497 9.2123 9.99913V6.67995C9.2123 6.18207 9.54422 5.85015 10.0421 5.85015C10.54 5.85015 10.8719 6.18207 10.8719 6.67995V9.99913Z"
                  fill="#F2555A"
                />
              </svg>
            )}
        </TextRightUnderlinedCell>
      </TextRightCell>

      <TextRightCell>{row.price[0]?.unit_delivery_price}</TextRightCell>
      <TextRightCell>
        {row.price_for_approve[0]?.reason !== null
          ? row.price[0]?.unit_agreed_delivery_price
          : row.price_for_approve[0].unit_agreed_delivery_price}
      </TextRightCell>
      <UnderlinedCell
        onClick={() =>
          DetailedRequestStore.getStatus() !== "FIXED" &&
          DetailedRequestStore.getStatus() !== "DECLINED" &&
          DetailedRequestStore.getStatus() !== "CONTRACT_ABORTED" &&
          DetailedRequestStore.getStatus() !== "FINISHED"
            ? handleOpenSampleQualityModal(row.id)
            : () => {}
        }
      >
        {sampleQualityString === "" ? (
          <AddSampleQualityButton
            disabled={
              DetailedRequestStore.getStatus() === "FIXED" ||
              DetailedRequestStore.getStatus() === "CONTRACT_ABORTED" ||
              DetailedRequestStore.getStatus() === "DECLINED" ||
              DetailedRequestStore.getStatus() === "FINISHED"
            }
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8493 9.17039H10.8705V4.19161C10.8705 3.97154 10.7831 3.76048 10.6275 3.60486C10.4718 3.44924 10.2608 3.36182 10.0407 3.36182C9.82063 3.36182 9.60957 3.44924 9.45396 3.60486C9.29834 3.76048 9.21091 3.97154 9.21091 4.19161V9.17039H4.23214C4.01206 9.17039 3.801 9.25781 3.64539 9.41343C3.48977 9.56905 3.40234 9.78011 3.40234 10.0002C3.40234 10.2203 3.48977 10.4313 3.64539 10.5869C3.801 10.7426 4.01206 10.83 4.23214 10.83H9.21091V15.8088C9.21091 16.0288 9.29834 16.2399 9.45396 16.3955C9.60957 16.5511 9.82063 16.6385 10.0407 16.6385C10.2608 16.6385 10.4718 16.5511 10.6275 16.3955C10.7831 16.2399 10.8705 16.0288 10.8705 15.8088V10.83H15.8493C16.0694 10.83 16.2804 10.7426 16.436 10.5869C16.5917 10.4313 16.6791 10.2203 16.6791 10.0002C16.6791 9.78011 16.5917 9.56905 16.436 9.41343C16.2804 9.25781 16.0694 9.17039 15.8493 9.17039Z"
                fill="#2F52BF"
              />
            </svg>
            <div>Добавить пробы</div>
          </AddSampleQualityButton>
        ) : (
          <>
            {sampleQualityString.length > 10
              ? `${sampleQualityString.slice(0, 10).trim()}...`
              : sampleQualityString}
          </>
        )}
      </UnderlinedCell>
      <StyledCell>
        {!row.sampleQuality.is_actual_version ? (
          <svg
            onClick={() =>
              DetailedRequestStore.getStatus() !== "FIXED" &&
              DetailedRequestStore.getStatus() !== "DECLINED" &&
              DetailedRequestStore.getStatus() !== "CONTRACT_ABORTED" &&
              DetailedRequestStore.getStatus() !== "FINISHED"
                ? handleOpenSampleQualityModal(row.id)
                : () => {}
            }
            id={`${row.price[0].id}-sampleQuality`}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.0421 1.70117C5.47822 1.70117 1.74414 5.43525 1.74414 9.99913C1.74414 14.563 5.47822 18.2971 10.0421 18.2971C14.606 18.2971 18.3401 14.563 18.3401 9.99913C18.3401 5.43525 14.606 1.70117 10.0421 1.70117ZM10.0421 14.1481C9.54422 14.1481 9.2123 13.8162 9.2123 13.3183C9.2123 12.8204 9.54422 12.4885 10.0421 12.4885C10.54 12.4885 10.8719 12.8204 10.8719 13.3183C10.8719 13.8162 10.54 14.1481 10.0421 14.1481ZM10.8719 9.99913C10.8719 10.497 10.54 10.8289 10.0421 10.8289C9.54422 10.8289 9.2123 10.497 9.2123 9.99913V6.67995C9.2123 6.18207 9.54422 5.85015 10.0421 5.85015C10.54 5.85015 10.8719 6.18207 10.8719 6.67995V9.99913Z"
              fill="#F2555A"
            />
          </svg>
        ) : (
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6046 1.70117C6.04072 1.70117 2.30664 5.43525 2.30664 9.99913C2.30664 14.563 6.04072 18.2971 10.6046 18.2971C15.1685 18.2971 18.9026 14.563 18.9026 9.99913C18.9026 5.43525 15.1685 1.70117 10.6046 1.70117ZM14.0897 8.58848L10.1067 12.5715C9.7748 12.9034 9.27693 12.9034 8.94501 12.5715L7.11946 10.7459C6.78754 10.414 6.78754 9.91615 7.11946 9.58423C7.45138 9.25231 7.94925 9.25231 8.28117 9.58423L9.52586 10.8289L12.928 7.42676C13.2599 7.09484 13.7578 7.09484 14.0897 7.42676C14.4217 7.75868 14.4217 8.25656 14.0897 8.58848Z"
              fill="#3D9A50"
            />
          </svg>
        )}
      </StyledCell>
      <FirstStyledCell
        onClick={() =>
          DetailedRequestStore.getStatus() !== "FIXED" &&
          DetailedRequestStore.getStatus() !== "CONTRACT_ABORTED" &&
          DetailedRequestStore.getStatus() !== "DECLINED" &&
          DetailedRequestStore.getStatus() !== "FINISHED"
            ? handleOpenModal(row.id)
            : () => {}
        }
      >
        <svg
          width="29"
          height="28"
          viewBox="0 0 29 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.71204 18.9363H12.2304C12.3396 18.937 12.4478 18.916 12.5489 18.8747C12.65 18.8334 12.742 18.7726 12.8195 18.6957L18.5617 12.9452L20.9183 10.6384C20.9961 10.5612 21.0578 10.4695 21.1 10.3683C21.1421 10.2672 21.1638 10.1588 21.1638 10.0492C21.1638 9.93967 21.1421 9.83121 21.1 9.7301C21.0578 9.62898 20.9961 9.5372 20.9183 9.46006L17.4 5.90024C17.3229 5.82246 17.2311 5.76073 17.13 5.7186C17.0289 5.67647 16.9204 5.65479 16.8109 5.65479C16.7013 5.65479 16.5928 5.67647 16.4917 5.7186C16.3906 5.76073 16.2988 5.82246 16.2217 5.90024L13.8817 8.24856L8.12289 13.999C8.04598 14.0766 7.98514 14.1685 7.94384 14.2696C7.90255 14.3707 7.88162 14.479 7.88225 14.5882V18.1065C7.88225 18.3266 7.96967 18.5377 8.12529 18.6933C8.28091 18.8489 8.49197 18.9363 8.71204 18.9363ZM16.8109 7.6594L19.1592 10.0077L17.9809 11.186L15.6325 8.83771L16.8109 7.6594ZM9.54184 14.9284L14.4625 10.0077L16.8109 12.356L11.8902 17.2767H9.54184V14.9284ZM21.9888 20.5959H7.05245C6.83238 20.5959 6.62131 20.6833 6.4657 20.839C6.31008 20.9946 6.22266 21.2056 6.22266 21.4257C6.22266 21.6458 6.31008 21.8569 6.4657 22.0125C6.62131 22.1681 6.83238 22.2555 7.05245 22.2555H21.9888C22.2089 22.2555 22.4199 22.1681 22.5755 22.0125C22.7311 21.8569 22.8186 21.6458 22.8186 21.4257C22.8186 21.2056 22.7311 20.9946 22.5755 20.839C22.4199 20.6833 22.2089 20.5959 21.9888 20.5959Z"
            fill="#2F52BF"
          />
        </svg>
      </FirstStyledCell>
      <Tooltip
        anchorId={`${row.price[0].id}-decrease`}
        place="top"
        content={
          row.price_for_approve.length !== 0
            ? `Cнизить до: ${
                row.price_for_approve[row.price_for_approve.length - 1]
                  .unit_agreed_price
              }₽. \n Причина: ${row.price_for_approve[0].comment}`
            : ""
        }
        className="tooltip-custom-class"
      />
      <Tooltip
        anchorId={`${row.price[0].id}-increase`}
        place="top"
        content={
          row.price_for_approve.length !== 0
            ? `Повысить до: ${
                row.price_for_approve[row.price_for_approve.length - 1]
                  .unit_agreed_price
              }₽. \n Причина: ${row.price_for_approve[0].comment}`
            : ""
        }
        className="tooltip-custom-class"
      />
      <Tooltip
        anchorId={`${row.price[0].id}-sampleQuality`}
        place="top"
        content={sampleQuality.comment_for_take}
        className="tooltip-custom-class"
      />
      <Tooltip
        anchorId={`${row.price[0].id}-approve`}
        place="top"
        content="Согласовать цену"
        className="tooltip-custom-class"
      />
      {openSampleQualityModal && (
        <AddSampleQualityModal
          closeFunction={() => handleCloseOpenSampleQualityModal()}
        />
      )}
    </StyledTableRow>
  );
};

export default observer(DetailedRequestTableRow);

const StyledTableRow = styled(TableRow)<TableRowProps>`
  border: unset !important;
  transition: var(--transition);
  background: ${(props) => (props.checked ? "#F2F5FF" : "white")};

  &:hover {
    background: var(--lightBlue3);
  }
`;

const StyledCell = styled(TableCell)`
  padding: 20px 12px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border: unset !important;

  & span {
    text-decoration: underline;
    color: var(--GrayLight11) !important;
  }
`;
const TextRightCell = styled(StyledCell)`
  text-align: right !important;
`;

const UnderlinedCell = styled(StyledCell)`
  text-decoration: underline;
  color: var(--GrayLight11) !important;
  cursor: pointer;
`;

const TextRightUnderlinedCell = styled.div`
  text-align: right !important;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;

  text-decoration: underline;
  color: var(--GrayLight11) !important;
  cursor: pointer;
`;

const TableCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
`;

const FirstStyledCell = styled(StyledCell)`
  cursor: pointer;
`;

const NameTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;

  color: var(--GrayLight12);
`;

const NameSubtitle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: var(--GrayLight12);
`;

const AddSampleQualityButton = styled(ButtonTertriary)`
  text-decoration: unset;
  white-space: nowrap;
  padding: unset;
`;
