import { types } from "mobx-state-tree";
import { RequestPriceInterface } from "./RequestStore";
import { MathchSampleQualityHeaders } from "../utils/MatchSampleQualityHeaders";
import { GetHistoryTypes } from "../utils/GetHistoryType";

export interface DetailedRequestProductInterface {
  id: number;
  agro_culture_type: string;
  measure_id: string;
  value: string;
  departure_point_id: string | null;
  receiving_point_id: string | null;
  status_id: string | null;
  harvest_year: number;
  start_at_version: string;
  end_at_version: Date | null;
  price: Array<RequestPriceInterface>;
  price_for_approve: Array<RequestPriceInterface>;
  isSelected: boolean;
  SampleQuality: Array<SampleQualityArrayInterface>;
  sampleQuality: SampleQualityObjectInterface;
}

export interface DetailedRequestApprovalHistoryInterface {
  date: string;
  time: string;
  action: string;
  user_id: string;
  file: string | null;
}

export interface SampleQualityArrayInterface {
  key: string;
  value: string;
}

export interface SampleQualityObjectInterface {
  humidity: number | null;
  weed_impurity: number | null;
  infection: number | null;
  grain_admixture: number | null;
  gluten: number | null;
  gluten_deformation_index: number | null;
  protein: number | null;
  indicator_of_a_amylase_activity: number | null;
  viability_for_tetrazole: number | null;
  large_grain: number | null;
  small_grain: number | null;
  oil_impurity: number | null;
  nature: number | null;
  damaged_grains: number | null;
  purity: number | null;
  oil_content: number | null;
  last_update: string | null;
  id: number;
  is_actual_version: boolean;
}

const FileModel = types.model({
  file_id: types.maybeNull(types.number),
  file_name: types.maybeNull(types.string),
});

const PriceModel = types.model({
  id: types.optional(types.number, -1),
  unit_offered_price: types.optional(types.number, 0),
  unit_agreed_price: types.maybeNull(types.number),
  unit_delivery_price: types.optional(types.number, 0),
  unit_agreed_delivery_price: types.maybeNull(types.number),
  comment: types.maybeNull(types.string),
  reason: types.maybeNull(types.string),
  start_at_version: types.maybeNull(types.string),
  end_at_version: types.maybeNull(types.string),
  is_active_version: types.optional(types.boolean, false),
  is_actual_now_version: types.optional(types.boolean, false),
  unit_id: types.maybeNull(types.number),
  contract_id: types.maybeNull(types.string),
  currency_id: types.maybeNull(types.number),
});

const QualityModel = types.model({
  key: types.optional(types.string, ""),
  value: types.optional(types.string, ""),
});

const ProductinfoModel = types.model({
  id: types.optional(types.number, -1),
  unit_value: types.optional(types.number, -1),
  start_at_version: types.optional(types.string, ""),
  end_at_version: types.maybeNull(types.string),
  is_active_version: types.optional(types.boolean, false),
  is_actual_now_version: types.optional(types.boolean, false),
  harvest_year: types.optional(types.number, 2000),
  departure_point_id: types.optional(types.number, -1),
  receiving_point_id: types.optional(types.number, -1),
  status_id: types.maybeNull(types.number),
});

const SampleQualityModel = types.model({
  humidity: types.optional(types.maybeNull(types.number), null),
  weed_impurity: types.optional(types.maybeNull(types.number), null),
  infection: types.optional(types.maybeNull(types.number), null),
  grain_admixture: types.optional(types.maybeNull(types.number), null),
  gluten: types.optional(types.maybeNull(types.number), null),
  gluten_deformation_index: types.optional(types.maybeNull(types.number), null),
  protein: types.optional(types.maybeNull(types.number), null),
  indicator_of_a_amylase_activity: types.optional(
    types.maybeNull(types.number),
    null
  ),
  viability_for_tetrazole: types.optional(types.maybeNull(types.number), null),
  large_grain: types.optional(types.maybeNull(types.number), null),
  small_grain: types.optional(types.maybeNull(types.number), null),
  oil_impurity: types.optional(types.maybeNull(types.number), null),
  nature: types.optional(types.maybeNull(types.number), null),
  damaged_grains: types.optional(types.maybeNull(types.number), null),
  purity: types.optional(types.maybeNull(types.number), null),
  oil_content: types.optional(types.maybeNull(types.number), null),
  last_update: types.optional(types.maybeNull(types.string), null),
  id: types.optional(types.number, -1),
  is_actual_version: types.optional(types.boolean, true),
  comment_for_take: types.maybeNull(types.string),
  created_at: types.optional(types.string, ""),
});

const ProductModel = types.model({
  id: types.optional(types.number, -1),
  agro_culture_type: types.string,
  measure_id: types.string,
  value: types.number,
  departure_point_id: types.maybeNull(types.string),
  receiving_point_id: types.maybeNull(types.string),
  delivery_tariff: types.optional(types.number, 0),
  status_id: types.maybeNull(types.string),
  harvest_year: types.number,
  start_at_version: types.string,
  end_at_version: types.maybeNull(types.Date),
  price: types.array(PriceModel),
  price_for_approve: types.optional(types.array(PriceModel), []),
  isSelected: types.optional(types.boolean, false),
  SampleQuality: types.optional(types.array(QualityModel), []),
  sampleQuality: types.optional(SampleQualityModel, {}),
  file_data: FileModel,
});

const ApprovalHistoryModel = types.model({
  created_at: types.string,
  action: types.string,
  user_id: types.string,
  comment: types.maybeNull(types.string),
});

const RequestModel = types.model({
  request_number: types.optional(types.string, ""),
  uuid: types.optional(types.string, ""),
  counterparty_id: types.maybeNull(types.string),
  trader_from_company: types.maybeNull(types.string),
  delivery_tern_id: types.optional(types.string, ""),
  departure_point_id: types.optional(types.string, ""),
  agro_culture_type: types.optional(types.string, ""),
  contract_start_date: types.optional(types.string, ""),
  contract_end_date: types.maybeNull(types.string),
  number: types.maybeNull(types.string),
  conclusion_date: types.maybeNull(types.string),
  measure_id: types.optional(types.string, ""),
  sum: types.maybeNull(types.number),
  vat: types.optional(types.number, 0),
  harvest_year: types.optional(types.number, 2000),
  contract_status_id: types.optional(types.string, ""),
  price: types.array(PriceModel),
  cargo_class: types.optional(types.string, ""),
  isFavourite: types.optional(types.boolean, false),
});

export const DetailedRequestStore = types
  .model({
    Request: types.optional(RequestModel, {}),
    Products: types.optional(types.array(ProductModel), []),
    ApprovalHistory: types.optional(types.array(ApprovalHistoryModel), [
      //   {
      //     date: "08.09.21",
      //     time: "12:45",
      //     action: "Добавление проб",
      //     trader: "Константин Константинопольский",
      //     file: "file_03_01_22_results_ainlab.pdf",
      //   },
      //   {
      //     date: "08.09.21",
      //     time: "12:45",
      //     action: "Забор образцов",
      //     trader: "Иванов И. В.",
      //     file: "",
      //   },
      //   {
      //     date: "08.09.21",
      //     time: "12:45",
      //     action: "Изменение стоимости",
      //     trader: "Иванов И. В.",
      //     file: "",
      //   },
    ]),
  })
  .actions((self) => ({
    setRequest(request: any) {
      self.Request = request;
    },
    setProducts(payload: any) {
      self.Products = payload;
    },
    setApprovalHostory(payload: any) {
      self.ApprovalHistory = payload;
    },
    changeSelectionOnProduct(id: number) {
      let indexOf = self.Products.findIndex((el) => el.id === id);
      if (indexOf !== undefined) {
        self.Products[indexOf].isSelected = !self.Products[indexOf].isSelected;
      }
    },
    deleteSelectedProducts() {
      //   self.Products.filter((el) => el.isSelected === false);
      self.Products.replace(
        self.Products.filter((el) => el.isSelected === false)
      );
    },
    clearSelection() {
      self.Products.forEach((el) => {
        if (el.isSelected) {
          el.isSelected = false;
        }
      });
    },
    setAllSeletions() {
      self.Products.forEach((e) => (e.isSelected = true));
    },
    removeAllSelection() {
      self.Products.forEach((e) => (e.isSelected = false));
    },
    setSampleQualityKey(id: number, i: number, key: string) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        self.Products[indexOf].SampleQuality[i].key = key;
      }
    },
    setSampleQualityValue(id: number, i: number, value: string) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        self.Products[indexOf].SampleQuality[i].value = value;
      }
    },
    removeSampleQuality(id: number, key: string, value: string) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        let indexOfSampleQuality = self.Products[
          indexOf
        ].SampleQuality.findIndex((e) => e.key === key && e.value === value);
        if (indexOfSampleQuality > -1) {
          self.Products[indexOf].SampleQuality.splice(indexOfSampleQuality, 1);
        }
      }
    },
    clearProductSampleQuality(id: number) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      self.Products[indexOf].SampleQuality.splice(
        0,
        self.Products[indexOf].SampleQuality.length
      );
    },
    addNewQualityToProduct(
      id: number,
      sampleQuality: Array<SampleQualityArrayInterface>
    ) {
      if (sampleQuality.length === 0) {
        this.clearProductSampleQuality(id);
      }
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        self.Products[indexOf].SampleQuality.push({ key: "", value: "" });
      }
    },
    toggleSaveProduct(
      id: number,
      productName: string,
      year: number,
      mass: number,
      measure: string,
      offeredPrice: number,
      agreedPrice: number,
      deliveryPlace: string | null,
      offeredDeliveryPrice: number,
      agreedDeliveryPrice: number
    ) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        self.Products[indexOf].agro_culture_type = productName;
        self.Products[indexOf].harvest_year = year;
        self.Products[indexOf].value = mass;
        self.Products[indexOf].measure_id = measure;
        self.Products[indexOf].price[0].unit_offered_price = offeredPrice;
        self.Products[indexOf].price[0].unit_agreed_price = agreedPrice;
        self.Products[indexOf].price[0].unit_delivery_price =
          offeredDeliveryPrice;
        self.Products[indexOf].price[0].unit_agreed_delivery_price =
          agreedDeliveryPrice;
        self.Products[indexOf].departure_point_id = deliveryPlace;
      }
    },
    toggleSaveSampleQuality(
      id: number,
      sampleQuality: Array<SampleQualityArrayInterface>
    ) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        let newSampleQuality = {} as any;
        sampleQuality.forEach((e: SampleQualityArrayInterface) => {
          let addKey = MathchSampleQualityHeaders(e.key);
          Object.defineProperty(newSampleQuality, addKey, {
            value: Number(e.value),
            writable: true,
          });
        });
        self.Products[indexOf].sampleQuality = newSampleQuality;
        // self.Products[indexOf].SampleQuality.replace(sampleQuality);
      }
    },
    clearProducts() {
      self.Products.splice(0, self.Products.length);
    },
    changeStatus(status: string) {
      self.Request.contract_status_id = status;
    },
    setDeliveryTerm(deliveryTern: string) {
      self.Request.delivery_tern_id = deliveryTern;
    },
    setCounterParty(counterParty: string | null) {
      self.Request.counterparty_id = counterParty;
    },
    setApprovedPriceStatus() {
      self.Products.filter((e) => e.isSelected)[0].price[0].reason = "Approve";
    },
    setProtocolStatus(id: number, status: boolean) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        self.Products[indexOf].sampleQuality.is_actual_version = status;
      }
    },
    setProductFile(id: number, fileId: number, name: string) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        self.Products[indexOf].file_data.file_id = fileId;
        self.Products[indexOf].file_data.file_name = name;
      }
    },
    deleteProductFile(id: number) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        self.Products[indexOf].file_data.file_id = null;
        self.Products[indexOf].file_data.file_name = null;
      }
    },
    setSampleQualityDate(id: number, date: Date) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        self.Products[indexOf].sampleQuality.created_at = date
          .toLocaleDateString()
          .split(".")
          .reverse()
          .join("-");
      }
    },
  }))
  .views((self) => ({
    getNumber() {
      return self.Request.request_number;
    },
    getStatus() {
      return self.Request.contract_status_id;
    },
    getRequestInfo() {
      return self.Request;
    },
    getMiddlePrice() {
      let middlePrice = 0;
      self.Products.forEach((e) => {
        middlePrice = middlePrice + (e.price[0].unit_agreed_price as number);
      });
      return middlePrice / self.Products.length;
    },
    getCropYears() {
      let cropYears = [] as Array<string>;
      self.Products.forEach((e) => {
        if (!cropYears.includes(String(e.harvest_year)) && e.harvest_year) {
          cropYears.push(String(e.harvest_year));
        }
      });
      return cropYears.sort((a: string, b: string) => {
        return Number(a) - Number(b);
      });
    },
    getProducts() {
      return self.Products;
    },
    getFullPrice() {
      let fullPrice = 0;
      self.Products.forEach((e) => {
        let mass = e.measure_id === 'т' ? 1 : 0.001
        fullPrice = fullPrice + Number(e.price[0].unit_agreed_delivery_price) * e.value * mass;
      });
      return fullPrice;
    },
    getAmountOfSelected() {
      return self.Products.filter((el) => el.isSelected === true).length;
    },
    getId() {
      return self.Request.request_number;
    },
    getApprovalHistory() {
      return self.ApprovalHistory;
    },
    getDeliveryTern() {
      return self.Request.delivery_tern_id;
    },
    getAgroCultureType() {
      return self.Request.agro_culture_type;
    },
    getContractStartDate() {
      return self.Request.contract_start_date;
    },
    getContractEndDate() {
      return self.Request.contract_end_date;
    },
    getDeparturePoint() {
      return self.Request.departure_point_id;
    },
    getUUID() {
      return self.Request.uuid;
    },
    getRecievingPoints() {
      let recievingPointArray = [] as Array<string>;
      self.Products.map((e) => {
        let indexOf = recievingPointArray.findIndex(
          (point) => point === e.receiving_point_id
        );
        if (indexOf === -1) {
          recievingPointArray.push(e.receiving_point_id as string);
        }
      });
      return recievingPointArray.join(", ");
    },
    getApprovalHistoryDates() {
      //   let approvalHistory = [] as Array<string>;
      //   self.ApprovalHistory.forEach((e) => {
      //     if (!approvalHistory.includes(e.date) && e.date) {
      //       approvalHistory.push(e.date);
      //     }
      //   });
      //   return approvalHistory;
    },
    getApprovalHistoryTimes() {
      //   let approvalHistory = [] as Array<string>;
      //   self.ApprovalHistory.forEach((e) => {
      //     if (!approvalHistory.includes(e.time) && e.time) {
      //       approvalHistory.push(e.time);
      //     }
      //   });
      //   return approvalHistory;
    },
    getApprovalHistoryActions() {
      let approvalHistory = [] as Array<string>;
      self.ApprovalHistory.forEach((e) => {
        if (!approvalHistory.includes(GetHistoryTypes(e.action)) && e.action) {
          approvalHistory.push(GetHistoryTypes(e.action));
        }
      });
      return approvalHistory;
    },
    getApprovalHistoryTraders() {
      let approvalHistory = [] as Array<string>;
      self.ApprovalHistory.forEach((e) => {
        if (!approvalHistory.includes(e.user_id) && e.user_id) {
          approvalHistory.push(e.user_id);
        }
      });
      return approvalHistory;
    },
    getCounterpartyId() {
      return self.Request.counterparty_id;
    },
    getPriceOfSelected() {
      return self.Products.filter((e) => e.isSelected === true)[0].price[0];
    },
    getPriceForApproveOfSelected() {
      return self.Products.filter((e) => e.isSelected === true)[0]
        .price_for_approve[0];
    },
    getSelected() {
      return self.Products.filter((e) => e.isSelected === true)[0];
    },
    getIdOfSelected() {
      return self.Products.filter((e) => e.isSelected === true).map(
        (e) => e.id
      );
    },
    getCargoClass() {
      return self.Request.cargo_class;
    },
    getAmountOfProduct(id: number) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        return self.Products[indexOf].value;
      }
    },
    getSampleQualityId(productId: number) {
      let indexOf = self.Products.findIndex((e) => e.id === productId);
      //   console.log(
      //     "🚀 ~ file: DetailedRequestStore.tsx:507 ~ getSampleQualityId ~ indexOf",
      //     indexOf
      //   );
      if (indexOf > -1) {
        return self.Products[indexOf].sampleQuality.id;
      }
    },
    getRecievingPointForRequest() {
      return self.Products[0].receiving_point_id;
    },
    getFullDeliveryPrice() {
      let fullPrice = 0;
      self.Products.forEach((e) => (fullPrice = fullPrice + e.delivery_tariff));
      return fullPrice;
    },
    getProductFileId(id: number) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        return self.Products[indexOf].file_data.file_id;
      }
    },
    getProductFileName(id: number) {
      let indexOf = self.Products.findIndex((e) => e.id === id);
      if (indexOf > -1) {
        return self.Products[indexOf].file_data.file_name;
      }
    },
    getTraderFromCompany() {
      return self.Request.trader_from_company;
    },
  }));
