import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import { ButtonPrimary } from "../../../styles/Buttons/ButtonPrimary";
import { usePersistentStore } from "../../../store/RootStore";
import { useLocation } from "react-router-dom";

const EmptyTable = () => {
  const { NewRequestStore } = usePersistentStore();
  const location = useLocation();

  return (
    <Container>
      <svg
        width="57"
        height="57"
        viewBox="0 0 57 57"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M44.4714 4.98315H11.8047C9.94817 4.98315 8.16769 5.72065 6.85494 7.03341C5.54219 8.34616 4.80469 10.1266 4.80469 11.9832V44.6498C4.80469 46.5063 5.54219 48.2868 6.85494 49.5996C8.16769 50.9123 9.94817 51.6498 11.8047 51.6498H44.4714C46.3279 51.6498 48.1083 50.9123 49.4211 49.5996C50.7339 48.2868 51.4714 46.5063 51.4714 44.6498V11.9832C51.4714 10.1266 50.7339 8.34616 49.4211 7.03341C48.1083 5.72065 46.3279 4.98315 44.4714 4.98315ZM46.8047 44.6498C46.8047 45.2687 46.5589 45.8622 46.1213 46.2997C45.6837 46.7373 45.0902 46.9832 44.4714 46.9832H11.8047C11.1858 46.9832 10.5924 46.7373 10.1548 46.2997C9.71719 45.8622 9.47135 45.2687 9.47135 44.6498V11.9832C9.47135 11.3643 9.71719 10.7708 10.1548 10.3332C10.5924 9.89565 11.1858 9.64982 11.8047 9.64982H44.4714C45.0902 9.64982 45.6837 9.89565 46.1213 10.3332C46.5589 10.7708 46.8047 11.3643 46.8047 11.9832V44.6498ZM37.4714 25.9832H18.8047C18.1858 25.9832 17.5924 26.229 17.1548 26.6666C16.7172 27.1042 16.4714 27.6976 16.4714 28.3165C16.4714 28.9353 16.7172 29.5288 17.1548 29.9664C17.5924 30.404 18.1858 30.6498 18.8047 30.6498H37.4714C38.0902 30.6498 38.6837 30.404 39.1213 29.9664C39.5589 29.5288 39.8047 28.9353 39.8047 28.3165C39.8047 27.6976 39.5589 27.1042 39.1213 26.6666C38.6837 26.229 38.0902 25.9832 37.4714 25.9832Z"
          fill="#365EDC"
        />
      </svg>
      {location.pathname.includes("sample_quality") && (
        <Title>Нет добавленных проб</Title>
      )}
      {!location.pathname.includes("sample_quality") && (
        <Title>Нет добавленных предложений</Title>
      )}
      <Button onClick={() => NewRequestStore.toggleAddProductModal(true)}>
        Добавить позицию
      </Button>
    </Container>
  );
};

export default observer(EmptyTable);

const Container = styled.div`
  width: 100%;
  height: 743px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;

  background: white;
  border-radius: 0px 0px 8px 8px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  color: var(--SageLight12);

  text-align: center;
`;

const Button = styled(ButtonPrimary)`
  width: fit-content;
  padding: 12px 56px;
`;
