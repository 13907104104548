import { Table, TableBody, TableContainer } from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import styled from "styled-components";
import NewRequestProductsTableHead from "./NewRequestProductsTableHead";
import { usePersistentStore } from "../../../../store/RootStore";
import EmptyTable from "../EmptyTable";
import NewRequestProductsTableRow from "./NewRequestProductsTableRow";

const NewRequestProductsTable = () => {
  const { NewRequestStore, FiltersDetailedRequestStore } = usePersistentStore();

  return (
    <StyledTableContainer>
      {NewRequestStore.getProducts().length !== 0 ? (
        <Table>
          <NewRequestProductsTableHead />
          <StyledTableBody>
            {FiltersDetailedRequestStore.FilteredNewRequestData(
              NewRequestStore.getProducts()
            ).map((e: any, i: number) => {
              return <NewRequestProductsTableRow row={e} key={i} />;
            })}
          </StyledTableBody>
        </Table>
      ) : (
        <EmptyTable />
      )}
    </StyledTableContainer>
  );
};

export default observer(NewRequestProductsTable);

const StyledTableContainer = styled(TableContainer)`
  width: 100%;
  border-radius: 8px;
  overflow: initial !important;
`;

const StyledTableBody = styled(TableBody)`
  background: white;
  border-radius: 8px;
`;
