import styled from "styled-components";

export const PriceInput = styled.input.attrs({ type: "number" })`
  width: 100%;
  padding: 8px 8px 8px 16px;

  border: 1px solid var(--GrayLight3);
  border-radius: 4px;
  background: var(--GrayLight1);
  color: var(--GrayLight12);

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  &:focus {
    border-color: var(--darkBlue);
  }

  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
