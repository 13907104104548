import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { observer } from "mobx-react-lite";
import Login from "./pages/Login/Login";
import AfterLogin from "./pages/Login/AfterLogin";
import LoginError from "./pages/Login/LoginError";
import LoginLoading from "./pages/Login/LoginLoading";
import Main from "./pages/Main";
import { usePersistentStore } from "./store/RootStore";
import Header from "./components/Header";
import Request from "./pages/Request";
import Supplier from "./pages/Supplier";
import Traiders from "./pages/Traiders";
import NotificationsModal from "./components/Notifications/Notifications";
import DetailedRequest from "./pages/DetailedRequest";
import DetailedRequestTable from "./components/DetailedRequest/DetailedRequestTable/DetailedRequestTable";
import ApprovalHistoryTable from "./components/DetailedRequest/ApprovalHistoryTable/ApprovalHistoryTable";
import SampleQualityTable from "./components/DetailedRequest/SampleQuality/SampleQualityTable/SampleQualityTable";
import NewRequest from "./pages/NewRequest";
import NewRequestOffer from "./components/NewRequest/NewRequestOffer";
import NewRequestProducts from "./components/NewRequest/NewRequestProducts";
import NewRequestProductsTable from "./components/NewRequest/NewRequestTables/ProductsTable/NewRequestProductsTable";
import NewRequestSampleQualityTable from "./components/NewRequest/NewRequestTables/SampleQualityTable/NewRequestSampleQualityTable";
import Preview from "./pages/Preview";
import Registration from "./pages/Registration";

function App() {
  const { UserStore } = usePersistentStore();

  //   console.log(UserStore.isLoginFunc());

  return (
    <>
      <BrowserRouter basename="/">
        <ScrollToTop>
          {UserStore.isLoginFunc() ? (
            <>
              <Header />
              <Routes>
                {/* <Route path="/" element={<Main />} /> */}
                <Route path="/request" element={<Request />} />
                {/* <Route path="/supplier" element={<Supplier />} /> */}
                {/* <Route path="/traiders" element={<Traiders />} /> */}
                <Route path="/request/:id" element={<DetailedRequest />}>
                  <Route path="" element={<DetailedRequestTable />}></Route>
                  <Route
                    path="approval_history"
                    element={<ApprovalHistoryTable />}
                  />
                  <Route
                    path="sample_quality"
                    element={<SampleQualityTable />}
                  />
                </Route>
                <Route path="/new_request" element={<NewRequest />}>
                  <Route path="offer" element={<NewRequestOffer />}></Route>
                  <Route path="products" element={<NewRequestProducts />}>
                    <Route path="" element={<NewRequestProductsTable />} />
                    <Route
                      path="sample_quality"
                      element={<NewRequestSampleQualityTable />}
                    />
                  </Route>
                </Route>
                <Route
                  path="*"
                  element={<Navigate to="/request" replace={true} />}
                />
              </Routes>
            </>
          ) : (
            <Routes>
              <Route
                path="*"
                element={<Navigate to="/preview" replace={true} />}
              />
              <Route path="/preview" element={<Preview />} />
              <Route path="/login" element={<Login />} />
              <Route path="/login/success" element={<AfterLogin />} />
              <Route path="/login/error" element={<LoginError />} />
              <Route path="/auth" element={<LoginLoading />} />
              <Route path="/registration" element={<Registration />} />
            </Routes>
          )}
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default observer(App);
