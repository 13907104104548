export const GetStatus = (status: string) => {
  switch (status) {
    case "NEW":
      return "Новая заявка";
      break;
    case "SEND":
      return "Согласование";
      break;
    case "FIXED":
      return "В закупке";
      break;
    case "DECLINED":
      return "Отклонена";
      break;
    case "InTest":
      return "Отбор проб";
      break;
    case "UPDATED":
      return "Обновлен";
    case "CONTRACT_ABORTED":
      return "Контракт разорван";
    case "FINISHED":
      return "Дата окончания контракта достигнута";
    case "ERROR":
      return "Ошибка";
    case "DRAFT":
      return "Создание";
  }
  return status;
};
