import { observer } from "mobx-react-lite";
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ButtonActiveProps } from "../Dropdown/Dropdown";
import listenForOutsideClicks from "../../utils/ListenForOutsideClicks";
import { DownArrow } from "../../styles/DownArrow";
import { usePersistentStore } from "../../store/RootStore";
import ProductEditModal from "./Modals/ProductEditModal";
import AddSampleQualityModal from "./Modals/AddSampleQualityModal";
import axios from "axios";
import { BaseURL } from "../../utils/BaseUrl";

interface SupervisorActionsProps {
  setDeleteModal: Dispatch<SetStateAction<boolean>>;
  isOpenSampleSelection: boolean;
  openSampleSelectionFunction: Function;
  isOpenDecreaseModal: boolean;
  openDecreaseFunction: Function;
  isOpenIncreaseModal: boolean;
  openIncreaseFunction: Function;
}

const SupervisorActions = ({
  setDeleteModal,
  isOpenSampleSelection,
  openSampleSelectionFunction,
  isOpenDecreaseModal,
  openDecreaseFunction,
  isOpenIncreaseModal,
  openIncreaseFunction,
}: SupervisorActionsProps) => {
  const { DetailedRequestStore, UserStore } = usePersistentStore();
  const [open, setOpen] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openAddSampleQuality, setOpenAddSampleQuality] = useState(false);

  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  useEffect(listenForOutsideClicks(listening, setListening, menuRef, setOpen));

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleDelete = () => {
    setDeleteModal(true);
  };

  const handleOpenProductModal = () => {
    setOpen(false);
    setOpenProductModal(true);
  };

  const handleOpenAddSampleQuality = () => {
    setOpen(false);
    setOpenAddSampleQuality(true);
  };

  const handleApprovePrice = () => {
    const body = {
      prices: [
        {
          id: DetailedRequestStore.getPriceForApproveOfSelected().id,
          comment: "",
          reason: "Approve",
        },
      ],
    };
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };

    axios
      .post(`${BaseURL}/v1/contracts/decrease_and_increase/`, body, config)
      .then((res) => {
        // console.log("🚀 ~ file: SupervisorActions.tsx:82 ~ .then ~ res", res);
        DetailedRequestStore.setApprovedPriceStatus();
      })
      .catch((err) => {
        console.log("🚀 ~ file: SupervisorActions.tsx:84 ~ .then ~ err", err);
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
          handleApprovePrice();
        }
      });
  };

  return (
    <Container>
      <Button
        borderColor={isOpenSampleSelection ? "#55B467" : "#DBDBDB"}
        backGroundColor={isOpenSampleSelection ? "#EBF9EB" : "#F8F8F8"}
        onClick={() => openSampleSelectionFunction()}
        disabled={
          DetailedRequestStore.getAmountOfSelected() !== 1 ||
          DetailedRequestStore.getStatus() === "FIXED" ||
          DetailedRequestStore.getStatus() === "CONTRACT_ABORTED" ||
          DetailedRequestStore.getStatus() === "FINISHED"
        }
      >
        <div>Отобрать пробы</div>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4783 6.22822C15.4783 6.22822 15.4783 6.22822 15.4783 6.16822L15.4333 6.05572C15.4177 6.03185 15.4001 6.00928 15.3808 5.98822C15.3608 5.95647 15.3382 5.92637 15.3133 5.89822L15.2458 5.84572L15.1258 5.78572L9.50084 2.31322C9.38164 2.23872 9.24391 2.19922 9.10334 2.19922C8.96278 2.19922 8.82504 2.23872 8.70584 2.31322L3.12584 5.78572L3.05834 5.84572L2.99084 5.89822C2.96598 5.92637 2.94341 5.95647 2.92334 5.98822C2.90406 6.00928 2.8865 6.03185 2.87084 6.05572L2.82584 6.16822C2.82584 6.16822 2.82584 6.16822 2.82584 6.22822C2.81847 6.29301 2.81847 6.35843 2.82584 6.42322V12.9782C2.82559 13.1057 2.85782 13.2311 2.91949 13.3426C2.98117 13.4542 3.07026 13.5482 3.17834 13.6157L8.80334 17.0882C8.83796 17.1096 8.87603 17.1248 8.91584 17.1332C8.91584 17.1332 8.95334 17.1332 8.97584 17.1332C9.10273 17.1735 9.23896 17.1735 9.36584 17.1332C9.36584 17.1332 9.40334 17.1332 9.42584 17.1332C9.46566 17.1248 9.50372 17.1096 9.53834 17.0882L15.1258 13.6157C15.2339 13.5482 15.323 13.4542 15.3847 13.3426C15.4464 13.2311 15.4786 13.1057 15.4783 12.9782V6.42322C15.4857 6.35843 15.4857 6.29301 15.4783 6.22822ZM8.37584 15.1082L4.25084 12.5582V7.77322L8.37584 10.3157V15.1082ZM9.12584 9.01822L4.92584 6.42322L9.12584 3.83572L13.3258 6.42322L9.12584 9.01822ZM14.0008 12.5582L9.87584 15.1082V10.3157L14.0008 7.77322V12.5582Z"
            fill="#55B467"
          />
        </svg>
      </Button>
      <Button
        borderColor={isOpenDecreaseModal ? "#55B467" : "#DBDBDB"}
        backGroundColor={isOpenDecreaseModal ? "#EBF9EB" : "#F8F8F8"}
        onClick={() => openDecreaseFunction()}
        disabled={
          DetailedRequestStore.getAmountOfSelected() !== 1 ||
          DetailedRequestStore.getStatus() === "FIXED" ||
          DetailedRequestStore.getStatus() === "CONTRACT_ABORTED" ||
          DetailedRequestStore.getStatus() === "FINISHED"
        }
      >
        <div>Снизить цену</div>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4067 9.16867C13.3369 9.09838 13.254 9.04258 13.1626 9.0045C13.0712 8.96643 12.9732 8.94682 12.8742 8.94682C12.7752 8.94682 12.6771 8.96643 12.5857 9.0045C12.4943 9.04258 12.4114 9.09838 12.3417 9.16867L9.87416 11.6437V5.95117C9.87416 5.75226 9.79515 5.56149 9.65449 5.42084C9.51384 5.28019 9.32308 5.20117 9.12416 5.20117C8.92525 5.20117 8.73449 5.28019 8.59383 5.42084C8.45318 5.56149 8.37416 5.75226 8.37416 5.95117V11.6437L5.90666 9.16867C5.76543 9.02744 5.57389 8.9481 5.37416 8.9481C5.17444 8.9481 4.98289 9.02744 4.84166 9.16867C4.70043 9.3099 4.62109 9.50145 4.62109 9.70117C4.62109 9.9009 4.70043 10.0924 4.84166 10.2337L8.59166 13.9837C8.66299 14.052 8.7471 14.1055 8.83916 14.1412C8.92894 14.1809 9.02601 14.2013 9.12416 14.2013C9.22232 14.2013 9.31939 14.1809 9.40916 14.1412C9.50123 14.1055 9.58534 14.052 9.65666 13.9837L13.4067 10.2337C13.477 10.1639 13.5328 10.081 13.5708 9.9896C13.6089 9.89821 13.6285 9.80018 13.6285 9.70117C13.6285 9.60216 13.6089 9.50413 13.5708 9.41274C13.5328 9.32134 13.477 9.23839 13.4067 9.16867Z"
            fill="#0081F1"
          />
        </svg>
      </Button>
      <Button
        borderColor={isOpenIncreaseModal ? "#55B467" : "#DBDBDB"}
        backGroundColor={isOpenIncreaseModal ? "#EBF9EB" : "#F8F8F8"}
        disabled={
          DetailedRequestStore.getAmountOfSelected() !== 1 ||
          DetailedRequestStore.getStatus() === "FIXED" ||
          DetailedRequestStore.getStatus() === "CONTRACT_ABORTED" ||
          DetailedRequestStore.getStatus() === "FINISHED"
        }
        onClick={() => openIncreaseFunction()}
      >
        <div>Повысить цену</div>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4067 9.16884L9.65666 5.41884C9.58534 5.35056 9.50123 5.29703 9.40916 5.26134C9.22657 5.18632 9.02176 5.18632 8.83916 5.26134C8.7471 5.29703 8.66299 5.35056 8.59166 5.41884L4.84166 9.16884C4.77173 9.23877 4.71626 9.32178 4.67842 9.41315C4.64057 9.50452 4.62109 9.60244 4.62109 9.70134C4.62109 9.90106 4.70043 10.0926 4.84166 10.2338C4.98289 10.3751 5.17444 10.4544 5.37416 10.4544C5.57389 10.4544 5.76543 10.3751 5.90666 10.2338L8.37416 7.75884V13.4513C8.37416 13.6503 8.45318 13.841 8.59383 13.9817C8.73449 14.1223 8.92525 14.2013 9.12416 14.2013C9.32308 14.2013 9.51384 14.1223 9.65449 13.9817C9.79515 13.841 9.87416 13.6503 9.87416 13.4513V7.75884L12.3417 10.2338C12.4114 10.3041 12.4943 10.3599 12.5857 10.398C12.6771 10.4361 12.7752 10.4557 12.8742 10.4557C12.9732 10.4557 13.0712 10.4361 13.1626 10.398C13.254 10.3599 13.3369 10.3041 13.4067 10.2338C13.477 10.1641 13.5328 10.0812 13.5708 9.98977C13.6089 9.89838 13.6285 9.80035 13.6285 9.70134C13.6285 9.60233 13.6089 9.5043 13.5708 9.41291C13.5328 9.32151 13.477 9.23856 13.4067 9.16884Z"
            fill="#DC3D43"
          />
        </svg>
      </Button>
      <Button
        borderColor={"#DBDBDB"}
        backGroundColor={"#F8F8F8"}
        disabled={
          DetailedRequestStore.getAmountOfSelected() !== 1 ||
          DetailedRequestStore.getStatus() === "FIXED" ||
          DetailedRequestStore.getStatus() === "CONTRACT_ABORTED" ||
          DetailedRequestStore.getStatus() === "FINISHED"
        }
        onClick={() => handleApprovePrice()}
      >
        <div>Согласовано</div>
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4783 6.22822C15.4783 6.22822 15.4783 6.22822 15.4783 6.16822L15.4333 6.05572C15.4177 6.03185 15.4001 6.00928 15.3808 5.98822C15.3608 5.95647 15.3382 5.92637 15.3133 5.89822L15.2458 5.84572L15.1258 5.78572L9.50084 2.31322C9.38164 2.23872 9.24391 2.19922 9.10334 2.19922C8.96278 2.19922 8.82504 2.23872 8.70584 2.31322L3.12584 5.78572L3.05834 5.84572L2.99084 5.89822C2.96598 5.92637 2.94341 5.95647 2.92334 5.98822C2.90406 6.00928 2.8865 6.03185 2.87084 6.05572L2.82584 6.16822C2.82584 6.16822 2.82584 6.16822 2.82584 6.22822C2.81847 6.29301 2.81847 6.35843 2.82584 6.42322V12.9782C2.82559 13.1057 2.85782 13.2311 2.91949 13.3426C2.98117 13.4542 3.07026 13.5482 3.17834 13.6157L8.80334 17.0882C8.83796 17.1096 8.87603 17.1248 8.91584 17.1332C8.91584 17.1332 8.95334 17.1332 8.97584 17.1332C9.10273 17.1735 9.23896 17.1735 9.36584 17.1332C9.36584 17.1332 9.40334 17.1332 9.42584 17.1332C9.46566 17.1248 9.50372 17.1096 9.53834 17.0882L15.1258 13.6157C15.2339 13.5482 15.323 13.4542 15.3847 13.3426C15.4464 13.2311 15.4786 13.1057 15.4783 12.9782V6.42322C15.4857 6.35843 15.4857 6.29301 15.4783 6.22822ZM8.37584 15.1082L4.25084 12.5582V7.77322L8.37584 10.3157V15.1082ZM9.12584 9.01822L4.92584 6.42322L9.12584 3.83572L13.3258 6.42322L9.12584 9.01822ZM14.0008 12.5582L9.87584 15.1082V10.3157L14.0008 7.77322V12.5582Z"
            fill="#55B467"
          />
        </svg>
      </Button>
      <ActionContainer ref={menuRef}>
        <ActionButton
          onClick={() => toggleOpen()}
          borderColor={open ? "#55B467" : "#DBDBDB"}
          backGroundColor={open ? "#2F6E3B" : "#171717"}
          disabled={
            DetailedRequestStore.getAmountOfSelected() === 0 ||
            DetailedRequestStore.getStatus() === "FIXED" ||
            DetailedRequestStore.getStatus() === "CONTRACT_ABORTED" ||
            DetailedRequestStore.getStatus() === "FINISHED"
          }
        >
          <div>Действия</div>
          <DownArrow src="/assets/Header/AccountMore.svg" />
        </ActionButton>
        {open && (
          <ExportMenu>
            {DetailedRequestStore.getAmountOfSelected() > 1 ? (
              <Item onClick={() => handleDelete()}>
                Удалить выбранное ({DetailedRequestStore.getAmountOfSelected()})
              </Item>
            ) : (
              <>
                <Item onClick={() => handleDelete()}>Удалить позицию</Item>
                <Item onClick={() => handleOpenProductModal()}>
                  Редактировать позицию
                </Item>
                <Item onClick={() => handleOpenAddSampleQuality()}>
                  Добавить пробы
                </Item>
              </>
            )}
          </ExportMenu>
        )}
      </ActionContainer>
      {openProductModal && (
        <ProductEditModal closeFunction={() => setOpenProductModal(false)} />
      )}
      {openAddSampleQuality && (
        <AddSampleQualityModal
          closeFunction={() => setOpenAddSampleQuality(false)}
        />
      )}
    </Container>
  );
};

export default observer(SupervisorActions);

const Container = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
`;

const Button = styled.button<ButtonActiveProps>`
  /* width: 145px; */
  height: 36px;
  padding: 8px 8px 8px 16px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  color: var(--GrayLight12);
  border-radius: 8px;
  transition: var(--transition);
  background-color: ${(props) => props.backGroundColor};
  border: 1px solid ${(props) => props.borderColor};

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  &:hover {
    border-color: var(--GrassDark10);
  }

  &:active {
    border-color: var(--GrassDark10);
    background: var(--GrassLight3);
  }

  &:disabled {
    background: var(--GrayLight2);
    color: var(--GrayLight9);
    border-color: var(--GrayLight8);
    cursor: unset;
  }

  &:disabled svg path {
    fill: var(--GrayLight9);
  }
`;

const ActionContainer = styled.div`
  position: relative;
`;

const ActionButton = styled.button<ButtonActiveProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  /* height: 36px; */

  text-align: left;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 6px;
  padding: 7px 9px 7px 16px;
  color: ${(props) => props.backGroundColor};

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: var(--transition);

  &:hover {
    border-color: var(--GrassDark8);
    color: var(--GrassDark8);
  }

  & .active {
    border-color: var(--GrassDark10);
  }

  &:disabled {
    border-color: var(--GrayLight10);
    color: var(--GrayLight10);
    cursor: unset;
  }
`;

const ExportMenu = styled.div`
  position: absolute;
  top: calc(100% + 3px);
  right: 0;
  z-index: 1;
  width: 225px;
  background-color: white;
  border: 1px solid var(--GrayLight7);
  border-radius: 6px;
  box-shadow: 0px 36px 64px -8px rgba(0, 0, 0, 0.12);
`;

const Item = styled.div`
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--GrayLight12);

  transition: var(--transition);

  &:hover {
    background: var(--GrayLight2);
    color: var(--GrassLight11);
  }
`;
