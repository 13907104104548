import React, { useEffect } from "react";
import { Wrapper } from "../styles/Wrapper";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { ButtonSecondary } from "../styles/Buttons/ButtonSecondary";
import { Outlet, useNavigate } from "react-router-dom";
import NewRequestNavSide from "../components/NewRequest/NewRequestNavSide";
import NewRequestFooter from "../components/NewRequest/NewRequestFooter";
import { usePersistentStore } from "../store/RootStore";
import NewRequestAddProductModal from "../components/NewRequest/Modals/NewRequestAddProductModal";

const NewRequest = () => {
  const { NewRequestStore, FiltersDetailedRequestStore } = usePersistentStore();
  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/request");
  };

  useEffect(() => {
    NewRequestStore.toggleAddProductModal(false);
    FiltersDetailedRequestStore.clearFilters();
  }, []);

  return (
    <Wrapper>
      <Container>
        <Header>
          <BackButton onClick={() => handleBack()}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.3679 9.95756L12.3054 7.02008C12.4599 6.86461 12.5467 6.65429 12.5467 6.43507C12.5467 6.21585 12.4599 6.00554 12.3054 5.85007C12.2282 5.77229 12.1365 5.71056 12.0353 5.66843C11.9342 5.6263 11.8258 5.60461 11.7162 5.60461C11.6067 5.60461 11.4982 5.6263 11.3971 5.66843C11.296 5.71056 11.2042 5.77229 11.1271 5.85007L7.60873 9.3684C7.53096 9.44554 7.46923 9.53732 7.4271 9.63844C7.38497 9.73955 7.36328 9.84801 7.36328 9.95756C7.36328 10.0671 7.38497 10.1756 7.4271 10.2767C7.46923 10.3778 7.53096 10.4696 7.60873 10.5467L11.1271 14.1065C11.2046 14.1834 11.2966 14.2443 11.3977 14.2856C11.4988 14.3269 11.607 14.3478 11.7162 14.3472C11.8254 14.3478 11.9337 14.3269 12.0348 14.2856C12.1359 14.2443 12.2278 14.1834 12.3054 14.1065C12.4599 13.9511 12.5467 13.7407 12.5467 13.5215C12.5467 13.3023 12.4599 13.092 12.3054 12.9365L9.3679 9.95756Z"
                fill="#2F52BF"
              />
            </svg>
          </BackButton>
          <div>Добавление сделки</div>
        </Header>
        <ContentContainer>
          <NewRequestNavSide />
          <Outlet />
        </ContentContainer>
      </Container>
      <NewRequestFooter />
      {NewRequestStore.getOpenAddProductModal() && (
        <NewRequestAddProductModal />
      )}
    </Wrapper>
  );
};

export default observer(NewRequest);

const Container = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 32px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: black;

  align-self: flex-start;
`;

const BackButton = styled(ButtonSecondary)`
  padding: 4px;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 56px;
`;

const OutletContainer = styled.div`
  max-width: 1250px;
`;
