import styled from "styled-components";

export const ResetButton = styled.button`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--blue);
  text-align: left;

  width: 100%;
  height: 36px;
  padding: 0px 12px;
  margin-bottom: 12px;

  transition: var(--transition);

  &:hover {
    color: var(--blue);
  }

  &:active {
    color: var(--blue);
  }
`;
