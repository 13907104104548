import axios from "axios";
import { observer } from "mobx-react-lite";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { usePersistentStore } from "../../store/RootStore";
import { BaseURL } from "../../utils/BaseUrl";
import { GetNotificationType } from "../../utils/GetNotificationType";
import { GetStatus } from "../../utils/GetStatus";
import Status from "../Status";

interface NotificationProps {
  name: string;
  type: string;
  info: string;
  from: string;
  to: string;
  id: number;
  number: number;
  requestId: string;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

interface NameProps {
  color: string;
}

interface ContainerProps {
  backgroundColor: string;
}

const Notification = ({
  name,
  type,
  info,
  from,
  to,
  id,
  number,
  requestId,
  setOpen,
}: NotificationProps) => {
  const { UserStore, NotificationStore, RequestStore, DetailedRequestStore } =
    usePersistentStore();
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();

  const handleDelete = () => {
    const config = {
      headers: { Authorization: `Bearer ${UserStore.getAccessToken()}` },
    };
    const body = {
      id: id,
    };

    axios
      .post(`${BaseURL}/v1/notifications/read_one_notification/`, body, config)
      .then((res) => {
        // console.log("🚀 ~ file: Notification.tsx:43 ~ .then ~ res", res);
        NotificationStore.removeNotification(id);
      })
      .catch((err) => {
        console.log("🚀 ~ file: Notification.tsx:52 ~ .then ~ err", err);
        if (err.response.data.code === "token_not_valid") {
          UserStore.updateToken();
          handleDelete();
        }
      });
  };

  const handleSubmit = () => {
    if (RequestStore.getIfExist(requestId) > -1) {
      const requestForDetail = {
        request_number: RequestStore.getRequestByUUID(requestId).request_number,
        uuid: RequestStore.getRequestByUUID(requestId).uuid,
        counterparty_id:
          RequestStore.getRequestByUUID(requestId).counterparty_company
            .full_name,
        trader_from_company:
          RequestStore.getRequestByUUID(requestId).trader_from_company,
        delivery_tern_id:
          RequestStore.getRequestByUUID(requestId).delivery_tern_id,
        departure_point_id:
          RequestStore.getRequestByUUID(requestId).departure_point_id,
        agro_culture_type:
          RequestStore.getRequestByUUID(requestId).agro_culture_type,
        contract_start_date:
          RequestStore.getRequestByUUID(requestId).contract_start_date,
        contract_end_date:
          RequestStore.getRequestByUUID(requestId).contract_end_date,
        number: RequestStore.getRequestByUUID(requestId).number,
        conclusion_date:
          RequestStore.getRequestByUUID(requestId).conclusion_date,
        measure: RequestStore.getRequestByUUID(requestId).measure,
        sum: RequestStore.getRequestByUUID(requestId).sum,
        vat: RequestStore.getRequestByUUID(requestId).vat,
        harvest_year: RequestStore.getRequestByUUID(requestId).harvest_year,
        contract_status_id:
          RequestStore.getRequestByUUID(requestId).contract_status,
        cargo_class: RequestStore.getRequestByUUID(requestId).cargo_class,
        price: [
          {
            id: RequestStore.getRequestByUUID(requestId).price[0]?.id,
            unit_offered_price:
              RequestStore.getRequestByUUID(requestId).price[0]
                ?.unit_offered_price,
            unit_agreed_price:
              RequestStore.getRequestByUUID(requestId).price[0]
                ?.unit_agreed_price,
            unit_delivery_price:
              RequestStore.getRequestByUUID(requestId).price[0]
                ?.unit_delivery_price,
            unit_agreed_delivery_price:
              RequestStore.getRequestByUUID(requestId).price[0]
                ?.unit_agreed_delivery_price,
            comment: RequestStore.getRequestByUUID(requestId).price[0]?.comment,
            start_at_version:
              RequestStore.getRequestByUUID(requestId).price[0]
                ?.start_at_version,
            end_at_version:
              RequestStore.getRequestByUUID(requestId).price[0]?.end_at_version,
            is_active_version:
              RequestStore.getRequestByUUID(requestId).price[0]
                ?.is_active_version,
            is_actual_now_version:
              RequestStore.getRequestByUUID(requestId).price[0]
                ?.is_actual_now_version,
          },
        ],
        isFavourite: RequestStore.getRequestByUUID(requestId).isFavourite,
      };
      DetailedRequestStore.setRequest(requestForDetail);
      DetailedRequestStore.clearSelection();
      DetailedRequestStore.clearProducts();
      setOpen(false);
      navigate(
        `/request/id:${RequestStore.getRequestByUUID(requestId).request_number}`
      );
    } else {
      RequestStore.updateRequests(UserStore.getAccessToken());
    }
  };

  return (
    <Container
      onClick={() => setSelected(!selected)}
      backgroundColor={selected ? "#F3F3F3" : "transparent"}
    >
      <AvatarImg>{`${name.split(" ")[0][0]}${
        name.split(" ")[1][0]
      }`}</AvatarImg>
      <InfoContainer>
        <Name color={selected ? "#365EDC" : "#171717"}>{name}</Name>
        <SubName color={selected ? "#171717" : "#858585"}>
          {`${GetNotificationType(info)} #${number}`}
        </SubName>
        {(type === "NEW_CONTRACT" ||
          type === "APPROVED_CONTRACT" ||
          type === "CONTRACT_ABORTED" ||
          type === "CONTRACT_SEND_FOR_APPROVE" ||
          type === "CHANGE_STATUS") && (
          <StatusContainer>
            <Status status={from} border={true} backgroundColor={"#F8F8F8"} />
            <svg
              width="17"
              height="8"
              viewBox="0 0 17 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z"
                fill="#858585"
              />
            </svg>
            <Status status={to} border={true} backgroundColor={"transparent"} />
          </StatusContainer>
        )}
        {selected && (
          <ButtonsContainer onClick={(e) => e.stopPropagation()}>
            <OpenButton onClick={() => handleSubmit()}>Смотреть</OpenButton>
            <HideButton onClick={() => handleDelete()}>Скрыть</HideButton>
          </ButtonsContainer>
        )}
      </InfoContainer>
    </Container>
  );
};

export default observer(Notification);

const Container = styled.div<ContainerProps>`
  width: calc(100%);
  padding: 16px 24px;

  background: ${(props) => props.backgroundColor};
  border-bottom: 1px solid var(--GrayLight2);

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;

  cursor: pointer;
  transition: var(--transition);
`;

const AvatarImg = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 32px;

  background: var(--lightBlue3);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: var(--blue);

  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
`;

const Name = styled.div<NameProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => props.color};
`;

const SubName = styled.div<NameProps>`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${(props) => props.color};
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-top: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

const OpenButton = styled.button`
  /* width: 93px; */
  /* height: 28px; */
  padding: 4px 12px;
  background: var(--blue);
  border-radius: 4px;
  border: 1px solid transparent;

  color: var(--TextWhite);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  transition: var(--transition);

  &:hover {
    background: var(--lightBlue);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    background: var(--darkBlue);
  }
`;
const HideButton = styled.div`
  color: var(--blue);

  border-radius: 6px;
  border: 1px solid var(--GrayLight8);
  transition: var(--transition);

  padding: 4px 12px;
  /* width: 76px; */
  /* height: 28px; */

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  &:hover {
    color: var(--blue);
    border-color: var(--blue);
  }

  &:disabled {
    background: var(--GrayLight6);
    color: var(--GrayLight9);
  }

  &:active {
    color: var(--darkBlue);
    border-color: var(--darkBlue);
  }
`;
